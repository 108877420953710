import APICall from "../Utils/APICall";

export default class CustomerService {
  static listCustomers = async (page = 1, pageSize = 20, search = "") => {
    return await APICall(
      `/api/Customers/list/${page}/${pageSize}?search=${search}`,
      "GET"
    );
  };

  static search = async (search, page = 1, pageSize = 5) => {
    return await APICall(
      `/api/Customers/list/${page}/${pageSize}?search=${search}`,
      "GET"
    );
  };
  static add = async (payload) => {
    return await APICall("/api/Customers/add", "POST", payload);
  };
  static list = async (page = 1, pageSize = 20) => {
    return await APICall(`/api/Customers/list/${page}/${pageSize}`, "GET");
  };
  static count = async () => {
    return await APICall(`/api/Customers/count`, "GET");
  };
  static get = async (customerId) => {
    return await APICall(`/api/Customers/get/${customerId}`, "GET");
  };
  static update = async (customerId, payload) => {
    return await APICall(`/api/Customers/update/${customerId}`, "POST", payload);
  };
}
