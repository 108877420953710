import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import "../../sass/invoice.css";
import CategoriesService from "../../services/CategoriesService";
import CustomerService from "../../services/CustomerService";
import InvoiceService from "../../services/InvoiceService";
import ServicesService from "../../services/ServicesService";
import Spinner from "../../Utils/Spinner";
import Toastr from "../../Utils/Toastr";
import Select from "react-select";
import UsersService from "../../services/UsersService";
import Functions from "../../Utils/Functions";

export default class RaiseInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //payload:{},
      supportingDocuments: [],
      invoiceAmount: 0,
      loading: false,
      categories: [],
      services: [],
      showCustomer: false,
      invoiceItems: [{ amount: 0, serviceId: 0, currency: "", comments: "" }],
      currencies: [
        { label: "NGN", value: "NGN", isBase: true },
        { label: "USD", value: "USD", isBase: false },
        //{ label: "Canadian Dollars", value: "CAD" },
      ],
      customers: [],
      data: {
        amount: 0,
        currency: "",
        customerId: 0,
        customer: "",
        discountAmount: 0,
        dueDate: "",
        comments: "",
        lineItems: [],
      },
      exchangeRate: "",
      officialRate: "",
      servicesArray: [],
      dummySelect: [{ label: "Select Currency", value: "" }],
      

    };
  }
  listCategories = async () => {
    this.setState({ loading: true });
    var categories = await CategoriesService.listCategories();
    if (categories) this.setState({ categories: categories });
    this.setState({ loading: false });
  };
  listActiveServices = async () => {
    this.setState({ loading: true });
    var services = [];
    let serviceNeeded = [];
    services = await ServicesService.listActiveServices();
    this.setState({ servicesArray: services });
    if (services) {
      services.forEach((element) => {
        serviceNeeded.push({ value: element.id, label: element.servicename });
      });
      this.setState({ services: serviceNeeded });
    }
    this.setState({ loading: false });
    console.log({ array: this.state.servicesArray });
  };

  assignCustomer = async (customer) => {
    let customerName = customer.firstName + " " + customer.lastName;
    const data = { ...this.state.data };
    data.customer = customerName;
    data.customerId = customer.id;
    this.setState({ data: data, customers: [] });
  };
  searchCustomers = async (search) => {
    this.setState({ loading: true });
    if (search.length >= 3) {
      this.setState({ showCustomer: true });
      var customers = await CustomerService.search(search);
      if (customers) {
        console.log(customers);
        this.setState({ customers: customers });
      }
    } else {
      this.setState({ customers: [] });
      this.setState({ showCustomer: false });
    }
    this.setState({ loading: false });
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data: data });
  };

  raiseInvoice = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let data = { ...this.state.data };
    data.amount = parseFloat(data.amount);
    data.customerId = parseInt(data.customerId);
    data.discountAmount = parseFloat(data.discountAmount);
    data.comments = data.comments;
    let validItems = this.state.invoiceItems.filter(
      (obj) => obj.serviceId > 0 && obj.amount > 0
    );
    console.log({ data: data });

    if (validItems.length == 0) {
      Toastr("error", "You must add aleast one invoice item");
      this.setState({ loading: false });
      return;
    }
    let totalAmount = 0;
    validItems.forEach((element) => {
      element.amount = parseFloat(element.amount);
      element.serviceId = parseInt(element.serviceId);
      totalAmount = totalAmount + element.amount;
    });

    data.lineItems = [...validItems];
    data.amount = totalAmount;
    data.documents = this.state.supportingDocuments || [];
    console.log(data);
    let response = await InvoiceService.raise(data);
    if (response) {
      Toastr("success", "Your invoice is successfully raised.");
      setTimeout(() => {
        window.location.href = `invoice?ref=${response.invoiceNumber}`;
      }, 2000);
      
      //window.location.href='/invoices'
    }
    this.setState({ loading: false });
  };

  AddInvoiceItem = () => {
    let data = [...this.state.invoiceItems];
    this.setState({ invoiceItems: [...data, { amount: 0, serviceId: 0 }]});
    
  };
RemoveInvoiceItem = (index) => {
  let data = [...this.state.invoiceItems];
  data.splice(index, 1);
  this.setState({invoiceItems: data });
};

  handleItemChange = (e, index) => {
    const { name, value } = e.target;
    let items = [...this.state.invoiceItems];
    items[index][name] = value;
    this.setState({ invoiceItems: items });
  };

  handleCurrencyChange = (option) => {
    if (option) {
      let data = { ...this.state.data };
      data.currency = option.value;
      let invoiceItems = [...this.state.invoiceItems];
      var services = this.state.servicesArray;
      invoiceItems.forEach((x) => {
        if (x.serviceId > 0) {
          let neededService = services.find((y) => y.id === x.serviceId);
          x.amount =
            option.value === neededService.currency
              ? neededService.amount
              : this.convertAmount(neededService.amount, option.value);
          x.currency = option.value;
        }
      });
      this.setState({ invoiceItems: invoiceItems, data: data });
    }
  };

  convertAmount = (amount, currency) => {
    let currencies = this.state.currencies;
    var rate = parseInt(this.state.exchangeRate);
    var offRate = parseInt(this.state.officialRate);
    let currencyNeeded = currencies.find((x) => x.value === currency);
    var convertedAmount = currencyNeeded.isBase
      ? rate * amount
      : amount / offRate;
    return convertedAmount;
  };

  handleServiceChange = (option, index) => {
    var services = this.state.servicesArray;
    let data = { ...this.state.data };
    if (option) {
      let items = [...this.state.invoiceItems];
      let neededService = services.find((x) => x.id == option.value);
      items[index].serviceId = option.value;
      items[index].currency = data.currency;
      items[index].amount =
        data.currency === neededService.currency
          ? neededService.amount
          : this.convertAmount(neededService.amount, data.currency);
      this.setState({ invoiceItems: items });
    }
  };

  componentDidMount = async () => {
    await this.listCategories();
    await this.listActiveServices();
    const settings = await UsersService.getSettingsItems();
    if (settings?.length > 0) {
      settings.forEach((sett) => {
        if (sett.settingKey === "NGN_to_USD_Exchange_Rate") {
          this.setState({ exchangeRate: sett.settingValue });
        }
        if (sett.settingKey === "USD_to_NGN_Exchange_Rate") {
          this.setState({ officialRate: sett.settingValue });
        }
      });
    }
  };

  updatePayload = (payloadArray) => {
    const tempPayload = { ...this.state.payload };
    payloadArray.forEach((el) => {
      tempPayload[el.key] = el.data;
    });
    console.log({tempPayload:tempPayload});
    this.setState({payload: tempPayload});
  }

  filesList() {
    return (
      <div className="nk-files nk-files-view-grid">
        <div className="nk-files-list">
          {this.state.supportingDocuments.map((file, index) => (
            <div key={index} className="nk-file-item nk-file">
              <div className="nk-file-info">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <a className="nk-file-icon-link" href="#">
                      <span className="nk-file-icon-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 72 72"
                        >
                          <path
                            d="M49,61H23a5.0147,5.0147,0,0,1-5-5V16a5.0147,5.0147,0,0,1,5-5H40.9091L54,22.1111V56A5.0147,5.0147,0,0,1,49,61Z"
                            style={{ fill: "#e3edfc" }}
                          />
                          <path
                            d="M54,22.1111H44.1818a3.3034,3.3034,0,0,1-3.2727-3.3333V11s1.8409.2083,6.9545,4.5833C52.8409,20.0972,54,22.1111,54,22.1111Z"
                            style={{ fill: "#b7d0ea" }}
                          />
                          <path
                            d="M19.03,59A4.9835,4.9835,0,0,0,23,61H49a4.9835,4.9835,0,0,0,3.97-2Z"
                            style={{ fill: "#c4dbf2" }}
                          />
                          <rect
                            x={27}
                            y={31}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={35}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={39}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={43}
                            width={14}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={47}
                            width={8}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <a href="#" className="title">
                        {file.fileName}
                      </a>
                    </div>
                  </div>
                </div>
                <ul className="nk-file-desc">
                  <li className="size">
                    {Functions.currencyFormat(file.size / 1000)} kb
                  </li>
                </ul>
              </div>
              <div className="nk-file-actions">
                <div className="dropdown">
                  <a
                    href
                    className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-plain no-bdr">
                      <li>
                        <a onClick={() => this.removeSupportingDocument(index)}>
                          <em className="icon ni ni-trash" />
                          <span>Remove file</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  hiddenFileInput = {
    height: "45px",
    width: "130px",
    cursor: "pointer",
    position: "relative",
    opacity: "0",
    left: "-130px",
    top: "-25px",
    fontSize: 0,
  };

  removeSupportingDocument = (index) => {
    const docs = [...this.state.supportingDocuments];
    docs.splice(index, 1);
    this.setState({ supportingDocuments: docs });
  };

  grabUploadedFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    console.log(reader);
    reader.onloadend = () => {
      const supportingDocuments = this.state.supportingDocuments || [];
      const newFile = {
        description: file.name,
        base64String: reader.result.split(",")[1],
        fileName: file.name,
        size: file.size,
      };

      console.log("uploaded file:", newFile);
      if (newFile.size / 1000000 > 1.0) {
        Toastr("error", "File cannot be larger than 1MB");
      } else {
        supportingDocuments.push(newFile);
      }
      this.setState({ supportingDocuments: supportingDocuments });
    };
    reader.readAsDataURL(file);
  };


render() {
  return (
    <MainLayout title="Raise Invoice">
      <div className="col">
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered">
            <div className="card-inner" style={{padding:"35px"}}>
              <form onSubmit={async (e) => await this.raiseInvoice(e)}>
                <div className="row g-4">
                  <div className="col-lg-8">
                    <div className="form-group">
                      <label className="form-label" htmlFor="customer">
                        Customer Name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          onChange={this.handleChange}
                          onKeyUp={async (e) =>
                            await this.searchCustomers(e.target.value)
                          }
                          value={this.state.data.customer}
                          className="form-control form-control-lg"
                          id="customer"
                          placeholder="search"
                          name="customer"
                        />
                        <div className="customer-drop-down invoice-pad">
                          <ul>
                            {this.state.customers.map((customer, index) => (
                              <li
                                key={index}
                                onClick={async (e) =>
                                  await this.assignCustomer(customer)
                                }
                              >
                                <span className="customer-name">
                                  {customer.firstName} {customer.lastName}
                                </span>
                                <span className="customer-email">
                                  {customer.emailAddress}
                                </span>
                              </li>
                            ))}
                            {this.state.showCustomer && (
                              <li className="customer-name">
                                Not Found?{" "}
                                <Link to="/add-customer" className="new">
                                  Add New
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="currency">
                            Select invoice currency
                          </label>
                          <div className="form-control-wrap ">
                            <Select
                              placeholder="Select"
                              onChange={(option) =>
                                this.handleCurrencyChange(option)
                              }
                              options={this.state.currencies}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label
                            className="form-label fix-up"
                            htmlFor="dueDate"
                          >
                            Due Date
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="date"
                              onChange={this.handleChange}
                              id="dueDate"
                              name="dueDate"
                              className="form-control form-control-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label
                            className="form-label fix-up"
                            htmlFor="discountAmount"
                          >
                            Discount Amount
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              onChange={this.handleChange}
                              className="form-control form-control-lg"
                              id="discountAmount"
                              name="discountAmount"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <hr className="preview-hr" />
                    <span className="preview-title-lg overline-title">
                      Invoice Item{" "}
                    </span>

                    <div className="row gy-4 align-center">
                      {this.state.invoiceItems.map((item, index) => (
                        <div className=" container row" key={index}>
                          <div className="col-6">
                            <label className="form-label" htmlFor="serviceId">
                              Service{" "}
                              <small
                                hidden={
                                  this.state.data.currency === ""
                                    ? false
                                    : true
                                }
                                style={{ color: "tomato", fontWeight: "500" }}
                              >
                                {" "}
                                - You must select invoice currency first
                              </small>
                            </label>
                            <div
                              className="form-control-wrap "
                              disabled={
                                this.state.data.currency === "" ? true : false
                              }
                            >
                              <Select
                                onChange={(option) =>
                                  this.handleServiceChange(option, index)
                                }
                                options={this.state.services}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="amount">
                                Amount
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  value={
                                    item.amount
                                      ? Number(
                                          parseFloat(
                                            Math.round(item.amount * 100) / 100
                                          )
                                        )
                                      : 0
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    this.handleItemChange(e, index)
                                  }
                                  className="form-control form-control-lg inphgt"
                                  name="amount"
                                  placeholder="Input amount"
                                />
                              </div>
                            </div>
                          </div>

                          {!this.state.invoiceItems[index + 1] && (
                            <div className="col-2 based">
                              <div className="form-group">
                                <div className="form-control-wrap mt-4 add-fix">
                                  <button
                                    onClick={() => this.AddInvoiceItem()}
                                    type="button"
                                    className="btn btn-block  btn-dark add-btn"
                                  >
                                    <em className="icon ni mr-3 ni-plus-c"></em>{" "}
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                            
                          {this.state.invoiceItems.length > 1 && (
                            <div className="col-md-1">
                              <div className="form-group">
                                <div className="form-control-wrap mt-4 ex-mg">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      
                                      this.RemoveInvoiceItem(index)
                                    }
                                  >
                                    <img
                                      src="/images/delete2.png"
                                      style={{ width: "20px" }}
                                    ></img>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-12 mt-5" style={{paddingLeft:"0px"}}>
                    <hr></hr>
                    <span className="preview-title-lg overline-title pt-3">Upload proof of payment{" "}
                    </span>{" "}
                    <p>Maximum file size is 1MB</p>
                    <button className="btn btn-dim btn-outline-danger">
                        {this.state.supportingDocuments &&
                          this.state.supportingDocuments.length > 0 ? "+ Add another document" : "Add document"}
                    </button>
                    <input
                        accept=".png, .jpg, .jpeg, .docx, .pdf"
                        type="file"
                        id="documentUploader"
                        style={this.hiddenFileInput}
                        title="+ Add supporting document"
                        onChange={(e) => this.grabUploadedFile(e)}/>
                    <div className="row gy-4 align-center">
                        {this.state.supportingDocuments &&
                          this.state.supportingDocuments.length > 0 && (
                        <div className="col-12">{this.filesList()}</div>)}
                    </div>
                  </div>
                  <hr className="mt-3"/>

                  {/**Add comments functionality */}     
                  <div className="col-6 based" style={{paddingLeft:"0px"}}>
                    <div className="form-group">
                      <label className="form-label fix-up" htmlFor="addComments" >
                        Add comments
                      </label>
                      <div className="form-control-wrap">
                        <textarea
                          type="text"
                          onChange={this.handleChange}
                          className="form-control form-control-lg"
                          id="comments"
                           name="comments"/>
                      </div>
                    </div>
                  </div>
                  <div className="row gy-4 align-center">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-label buttonLabel"
                          htmlFor="Button"
                        ></label>
                        <div className="form-control-wrap">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              {this.state.loading ? (
                                <Spinner />
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-block  btn-primary fix-inv-btn "
                                >
                                  Submit Invoice
                                </button>
                              )}
                            </div>
                            <div className="col-md-6">
                              <Link
                                to="/invoices"
                                className="btn btn-block btn-dark fix-inv-btn"
                              >
                                {" "}
                                <em className="icon ni ni-chevrons-left"></em>{" "}
                                Back to List
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
}