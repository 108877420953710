import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import InvoiceService from "../../services/InvoiceService";
import PaymentService from "../../services/PaymentService";
import Toastr from "../../Utils/Toastr";
import Spinner from "../../Utils/Spinner";
import InvoiceStatus from "../../Utils/InvoiceStatus";
import Modal from "react-modal";
import Constants from "../../Utils/Constants";
import BanksService from "../../services/BanksService";
import ServicesService from "../../services/ServicesService";
import Select from "react-select";
import Logo from "../../Logo";

export default class InvoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceId: "",
      loading: false,
      approvalLoading: false,
      selfCreated: false,
      rejectLoading: false,
      settlementBanks: [],
      banks: [],
      serviceUsed: {},
      services: [],
      invoiceItems: [],
      invoicePayment: [],
      invoice: {
        amount: 0,
        vat: 0,
        customer: {},
        // worflowTrail:[]
      },
      paymentChannels: [
        { name: "Online", value: "online" },
        { name: "Offline", value: "offline" },
      ],
      vatPercentage: 0,
      vat: 0,
      total: 0,
      role: "",
      data: {
        invoiceNumber: "",
        rejectionMessage: "",
      },
      approvalData: {
        invoiceNumber: "",
        approvalMessage: "",
        paymentChannel: "",
        settlementBanks: [],
        vatPercentage: 0,
      },
      modalOpen: false,
    };
  }

  makePayment = async () => {
    this.setState({ loading: true });
    let invoiceNumber = new URLSearchParams(this.props.location.search).get(
      "ref"
    );
    var payment = `${process.env.REACT_APP_API_BASEURL}Payment/invoice?invoiceNumber=${invoiceNumber}`;
    window.location.href = payment;
    console.log(payment);
  };
  confirmPayment = async () => {
    this.setState({ loading: true });

    var payment = await PaymentService.callback();
    console.log(payment);
    if (payment) {
    }
  };
  getInvoice = async (invoiceNumber) => {
    this.setState({ loading: true });
    var invoice = await InvoiceService.get(invoiceNumber);
    if (invoice) {
      let vatPercentage = invoice.vat;
      let vatAmount =
        (parseFloat(vatPercentage) / 100) * (invoice.amount - invoice.discount);
      let total = invoice.amount - invoice.discount + vatAmount;
      this.setState({
        invoice: invoice,
        vatPercentage: vatPercentage,
        total: total,
        vat: vatAmount,
      });
      if (invoice.lineItems) {
        this.setState({ invoiceItems: invoice.lineItems });
      }
      if (invoice) {
        this.setState({ invoicePayment: invoice });
      }
    }
    this.setState({ loading: false });
  };

  componentDidMount = async () => {
    let invoiceId = new URLSearchParams(window.location.search).get("ref");
    this.setState({ invoiceId: invoiceId });
    console.log(invoiceId);
    await this.getInvoice(invoiceId);
  };

  GetFirstLetter(stringValue = "") {
    return stringValue.charAt(0)?.toUpperCase();
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="col">
            <div className="nk-content mt-4">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div
                    className="nk-content-body mx-auto"
                    style={{ maxWidth: "1024px" }}
                  >
                    <div className="nk-block-head">
                      <div className="nk-block-between g-3">
                        <div className="nk-block-head-content">
                          <h3 className="nk-block-title page-title">
                            Invoice{" "}
                            <strong className="text-primary small">
                              #{this.state.invoice.invoiceNumber}
                            </strong>{" "}
                            {InvoiceStatus(
                              this.state.invoice,
                              this.state.invoice.isPaid ? "paid" : "unpaid"
                            )}{" "}
                          </h3>
                          <div className="nk-block-des text-soft">
                            <ul className="list-inline">
                              <li>
                                Date generated:{" "}
                                <span className="text-base">
                                  {new Date(
                                    this.state.invoice.dateCreated
                                  ).toDateString()}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="nk-block-head-content">
                          {/* <Link
                            to="/invoices"
                            className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                          >
                            <em className="icon ni ni-arrow-left" />
                            <span>Back</span>
                          </Link> */}
                          <Link
                            to="/invoices"
                            className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                          >
                            <em className="icon ni ni-arrow-left" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* .nk-block-head */}
                    <div className="nk-block">
                      <div className="invoice">
                        <div className="invoice-action">
                          {/* <Link className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary" to="#" target="_blank"><em className="icon ni ni-printer-fill" /></Link> */}
                        </div>
                        {/* .invoice-actions */}
                        <div className="invoice-wrap">
                          <div className="invoice-brand text-left">
                            <Logo />
                          </div>
                          <div className="invoice-head mt-5">
                            <div className="invoice-contact">
                              <span className="overline-title">Invoice To</span>
                              <div className="invoice-contact-info">
                                <h4 className="title">
                                  {this.state.invoice.customer.firstName}{" "}
                                  {this.state.invoice.customer.lastName}
                                </h4>
                                <ul className="list-plain">
                                  <li>
                                    <em className="icon ni ni-map-pin-fill" />
                                    {this.state.invoice.customer
                                      .contactAddress && (
                                      <span>
                                        {
                                          this.state.invoice.customer
                                            .contactAddress
                                        }
                                      </span>
                                    )}
                                    {!this.state.invoice.customer
                                      .contactAddress && <span></span>}
                                  </li>
                                  <li>
                                    <em className="icon ni ni-call-fill" />
                                    <span>
                                      {this.state.invoice.customer.phoneNumber}
                                    </span>
                                  </li>
                                  <li>
                                    <em className="icon ni ni-emails-fill" />
                                    <span>
                                      {this.state.invoice.customer.emailAddress}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="invoice-desc">
                              <ul className="list-plain">
                                <li className="invoice-id">
                                  <span>Invoice ID</span>
                                  <br />
                                  <span className="pl-0">
                                    {this.state.invoice.invoiceNumber}
                                  </span>
                                </li>
                                <li className="invoice-date">
                                  <span>Due Date</span>
                                  <br />
                                  <span className="pl-0">
                                    {new Date(
                                      this.state.invoice.dueDate
                                    ).toDateString()}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* .invoice-head */}
                          <div className="invoice-bills">
                            <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th className="w-250px">Invoice Number</th>
                                    <th className="w-60">Description</th>
                                    <th>Price</th>
                                    {/* {this.state.invoice.modeOfPayment ==
                                    "offline" ? (
                                      <th>Bank</th>
                                    ) : (
                                      ""
                                    )} */}
                                    <th>Qty</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.invoiceItems.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td>{item.invoiceNumber}</td>
                                        <td>
                                          Payment for - {item.description}
                                          <br />
                                          {this.state.invoice.modeOfPayment ==
                                            "offline" &&
                                            item.settlementBankName +
                                              "-" +
                                              item.settlementBankAccount}
                                        </td>
                                        <td>
                                          {this.state.invoice.currency}
                                          {item.amount.toLocaleString()}
                                        </td>
                                        <td>1</td>
                                        <td>
                                          {this.state.invoice.currency}
                                          {item.amount.toLocaleString()}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan={2} />
                                    <td colSpan={2}>Subtotal</td>
                                    <td>
                                      {this.state.invoice.currency}
                                      {this.state.invoice.amount.toLocaleString()}
                                    </td>
                                  </tr>

                                  {this.state.invoice.discount && (
                                    <tr>
                                      <td colSpan={2} />
                                      <td colSpan={2}>Discount</td>
                                      <td>
                                        {this.state.invoice.currency}
                                        {this.state.invoice.discount.toLocaleString()}
                                      </td>
                                    </tr>
                                  )}
                                  {/* {
                                this.state.invoice.map((item, index) => (
                                  <tr key={index} > */}
                                  <tr>
                                    <td colSpan={2} />
                                    <td colSpan={2}>VAT</td>
                                    <td>
                                      {this.state.invoice.currency}
                                      {this.state.vat.toLocaleString()}
                                    </td>
                                  </tr>
                                  {/* ))
                              } */}

                                  <tr>
                                    <td colSpan={2} />
                                    <td colSpan={2}>Grand Total</td>
                                    <td>
                                      {this.state.invoice.currency}
                                      {this.state.total.toLocaleString()}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2} />
                                    <td colSpan={2} />
                                    {this.state.invoice.modeOfPayment ==
                                    "offline" ? (
                                      ""
                                    ) : (
                                      <td>
                                        {" "}
                                        <button
                                          target="_blank"
                                          type="submit"
                                          className="btn btn-primary my-3 btn-lg mr-2"
                                          onClick={async (e) =>
                                            await this.makePayment(e)
                                          }
                                        >
                                          Pay Now
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                </tfoot>
                              </table>

                              <div className="nk-notes ff-italic fs-12px text-soft">
                                {" "}
                                Invoice was created on a computer and is valid
                                without the signature and seal.{" "}
                              </div>
                            </div>
                          </div>
                          {/* .invoice-bills */}
                        </div>
                        {/* .invoice */}
                      </div>
                      {/* .nk-block */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
