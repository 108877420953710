import common from "./common";

const admin = [
  ...common,
  { label: "Transactions", icon: "fa fa-home", url: "/transactions" },
  { label: "Services", icon: "fa fa-home", url: "/services" },
  {
    label: "Service Categories",
    icon: "fa fa-home",
    url: "/service-categories",
  },
  { label: "Users", icon: "fa fa-home", url: "/users" },
  { label: "Cash and Banks", icon: "fa fa-home", url: "/accounts" },
  { label: "Settings", icon: "fa fa-home", url: "/settings" },
  //{ label: "Reports", icon: "fa fa-home", url: "/reports" },
];
export default admin;
