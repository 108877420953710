import React from 'react';
import Logo from '../../Logo';


function SuccessPayment(props) {
    return (
        <>
             <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  <a  className="logo-link">
                    <Logo style={{height: "45px"}} />
                  </a>
                </div>
                <div className="nk-block-head">
                  <div className="nk-block-head-content text-center">
              <h4 className="nk-block-title"> <em style={{ fontSize: "58px", verticalAlign: "middle" }} className="icon text-success fs-100px ni ni-check-circle-cut"></em>  </h4>
                   <h4 className="nk-block-title"> Your Payment is successful. </h4>
              <p className="sub-payment-text">You will receive your receipt soon</p>
                    <div className="nk-block-des ">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
              
        </>
    );
}

export default SuccessPayment;