import AddCustomer from "../pages/customer/add-customer";
import CustomerDetails from "../pages/customer/customer-details";
import CustomerList from "../pages/customer/customer-list";
import Invoice from "../pages/invoice/invoice";
import RaiseInvoice from "../pages/invoice/raise-invoice";
import InvoiceView from "../pages/invoice/invoice-view";
import Login from "../pages/others/login";
import PasswordReset from "../pages/others/password-reset";
import PasswordChange from "../pages/others/password-change";
import SamplePage from "../pages/samplePage";
import Logout from "../pages/others/logout";
import UpdateInvoice from "../pages/invoice/update-invoice";
import PaymentStatus from "../pages/payment/payment-status";
import VoucherView from "../pages/voucher/voucher-view";
import RaiseVoucher from "../pages/voucher/raise-voucher";
import Vouchers from "../pages/voucher/vouchers";
import VoucherReview from "../pages/voucher/voucher-review";
import InvoicePreview from "../pages/invoice/invoice-preview";
import DummyLayout from "../Components/Layouts/DummyLayout";


const CommonRoutes = [
    { route: "/", component: <Login /> },
    { route: "/home", component: <SamplePage /> },
    { route: "/customers", component: <CustomerList /> },
    { route: "/add-customer", component: <AddCustomer /> },
    { route: "/customer/:customerId", component: <CustomerDetails /> },
    { route: "/raise-invoice", component: <RaiseInvoice /> },
    { route: "/invoices", component: <Invoice />},
    { route: "/invoice", component: <InvoiceView /> },
    { route: "/invoice-preview", component: <InvoicePreview /> },
    { route: "/update-invoice", component: <UpdateInvoice /> },
    { route: "/login", component: <Login /> },
    { route: "/logout", component: <Logout /> },
    { route: "/password-reset", component: <PasswordReset /> },
    { route: "/password/:code", component: <PasswordChange /> },
    { route: "/payment-status/:paymentReference", component: <PaymentStatus /> },
    { route: "/voucher/:voucherId", component: <VoucherView />},
    { route: "/voucher-review/:voucherCode", component: <VoucherReview />},
    { route: "/raise-voucher", component: <RaiseVoucher />},
    { route: "/vouchers", component: <Vouchers />},
    { route: "/approvals", component: <DummyLayout child={<Vouchers approvalsOnly={true} />}></DummyLayout>},
    { route: "/voucher-approval", component: <DummyLayout child={<Vouchers vouchersOnly={true} />}></DummyLayout>}
];

export default CommonRoutes;
