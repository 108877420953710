import React, { Component, useEffect, useState } from "react";
import { DataContext } from "./Contexts/DataContexts";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
//import dotenv from "dotenv";
import AllRoutes from "./routes/index";
import Constants from "./Utils/Constants";
import Functions from "./Utils/Functions";
import Spinner from "./Utils/Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsersService from "./services/UsersService";

//dotenv.config();

function App() {
  const [globalState, setGlobalState] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [notificationDetails, setNotificationDetails] = useState();

  const checkNotifications = async () => {
    let user = localStorage.getItem("user");
    if (!user) {
      return;
    } else {
      const response = await UsersService.getNotiications();
      console.log({ notis: response });
      setNotificationDetails(response);
      if (response && response.message) {
        showNotification(response);
      }
    }
  };

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    // console.log("routes", AllRoutes);
    let user = localStorage.getItem("user");
    if (user) {
      try {
        user = JSON.parse(user);
        setLoggedInUser(user);
      } catch (e) {
        localStorage.clear();
      }
    } else {
      setLoading(false);
    }
    const timer = setInterval(checkNotifications, 30000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const showNotification = (notification) => {
    if (!notification) return;
    const n = new Notification(notification.message);
    n.onclick = () => {
      window.open(notification.url, "_blank");
    };
  };

  const addItem = (key, value) => {
    let _globalState = globalState;
    _globalState[key] = value;
    setGlobalState(_globalState);
    // console.log(`${key} added to global state: `, value);
  };

  const setLoggedInUser = (_user, error) => {
    addItem("user", _user);
    setLoading(false);
  };

  return errorMessage ? (
    <p>{errorMessage}</p>
  ) : loading ? (
    <Spinner />
  ) : (
    <DataContext.Provider value={{ globalState, addItem }}>
      <ToastContainer />
      <BrowserRouter basename={Constants.subfolder}>
        <Routes>
          {AllRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.route}
              exact
              element={route.component}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </DataContext.Provider>
  );
}

export default App;

// "node-sass": "^7.0.3",
