import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import Spinner from "../../Utils/Spinner";

import ServicesService from "../../services/ServicesService";

export default class ServiceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            services: [],
        };
    }

    componentDidMount = async () => {
        this.setState({ loading: true });
        const services = await ServicesService.listServices();
        if (services) {
            this.setState({ services: services });
        }
        this.setState({ loading: false });
    };

    tableRow = ({
        servicename,
        categoryName,
        settlementBank,
        currency,
        amount,
        id,
        isActive
    }) => {
        return (
            <div className="nk-tb-item">
                <div className="nk-tb-col">
                    <div className="nk-tnx-type">
                        <div className="nk-tnx-type-text">
                            <span className="tb-lead">
                                {servicename}
                                {categoryName ? <span className="tb-lead-sub">{categoryName}</span> : <span className="tb-lead-sub">{"Not Available"}</span>}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="nk-tb-col">
                    {/* currency */}
                    {currency ? <span className="tb-amount">{currency}</span> : <span className="tb-amount">{"Not Available"}</span>}
                </div>
                <div className="nk-tb-col text-right">
                    {/* amount */}
                    {!amount || amount === 0 ? <span className="tb-amount">{"Not Available"}</span> : <span className="tb-amount">{amount}</span>}
                </div>
                <div className="nk-tb-col text-right">
                    {/* status */}
                    <span className={`tb-amount ${isActive ? 'text-success' : 'text-danger'}`}>
                        {isActive ? 'Active' : 'Inactive'}
                    </span>
                </div>
                <div className="nk-tb-col text-right">
                    {settlementBank ? (
                        <>
                            <span className="tb-amount">{settlementBank?.accountNumber}</span>
                            <span className="tb-amount">{settlementBank?.bankName}</span>
                        </>
                    ) : (
                        <span className="tb-amount">{"Not Available"}</span>
                    )}
                </div>
                <div className="nk-tb-col nk-tb-col-status list text-right">
                    <Link
                        to={"/service/" + id}
                        className="btn btn-primary btn-md px-3"
                    >
                        <em className="icon ni ni-eye mr-1"></em>
                        View
                    </Link>
                </div>
            </div>
        )
    }

    render() {
        return (
            <MainLayout title="Service list">
                <div className="col">
                    <div className="nk-content">
                        <div className="container-fluid">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head nk-block-head-sm col-md-12">
                                        <div className="nk-block-between g-3">
                                            <div className="nk-block-head-content">
                                                <div className="nk-block-des text-soft">
                                                    <p>You have total {this.state.services.length} Services.</p>
                                                </div>
                                            </div>
                                            <div className="nk-block-head-content">
                                                <ul className="nk-block-tools g-3">
                                                    <li>
                                                        <Link
                                                            className="btn btn-primary btn-md"
                                                            to="/add-service"
                                                        >
                                                            <em className="icon ni ni-plus-fill-c mr-2"></em>
                                                            New Service
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <div className="card card-bordered card-stretch col-md-12">
                                            <div className="card-inner-group">
                                                <div className="card-inner">
                                                    <div className="card-title-group">
                                                        <div className="card-title">
                                                            <h5 className="title">All Services</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-inner p-0">
                                                    <div className="nk-tb-list nk-tb-tnx">
                                                        <div className="nk-tb-item nk-tb-head">
                                                            <div className="nk-tb-col tb-col">
                                                                <b><span>Service Details</span></b>
                                                            </div>
                                                            <div className="nk-tb-col tb-col">
                                                                <b><span>Currency</span></b>
                                                            </div>
                                                            <div className="nk-tb-col tb-col">
                                                                <b><span>Price</span></b>
                                                            </div>
                                                            <div className="nk-tb-col tb-col">
                                                                <b><span>Status</span></b>
                                                            </div>
                                                            <div className="nk-tb-col text-right">
                                                                <b><span>Settlement Bank Details</span></b>
                                                            </div>
                                                        </div>
                                                        {this.state.loading ? (
                                                            <div className="nk-tb-item">
                                                                <div className="nk-tb-col">
                                                                    <div className="nk-tnx-type">
                                                                        <div className="nk-tnx-type-text">
                                                                            <span className="tb-lead">
                                                                                <Spinner />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            this.state.services.map((service, index) => (
                                                                <this.tableRow key={index} {...service} />
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }
}
