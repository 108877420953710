import React from 'react';


function InvoiceStatus(invoice, status) {
    const statusThemes = {
        awaiting_approval: "warning",
        returned: "danger",
        approved: "success",
        cancelled: "secondary",
        paid: 'success',
        unpaid:"danger"
    }

    return (
        <span className={`badge badge-${statusThemes[status || invoice.status]}`}>{status || invoice.status}</span>
    );
}

export default InvoiceStatus;