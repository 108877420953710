import React from 'react';
import Logo from '../../Logo';


function FailedPayment(props) {
    return (
        <>
             <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  <a  className="logo-link">
                    <Logo style={{height: "45px"}} />
                  </a>
                </div>
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                   <h4 className="nk-block-title"> <em style={{fontSize:"58px", verticalAlign:"middle"}} class="icon text-danger fs-100px ni ni-cross-circle-fill"></em> Sorry Your payment failed. <br/> 
                <span className="sub-payment-text" style={{ fontSize: "15px", position: "relative", left: "65px" }}>Please try again later or contact our help center: accounts@mapleeducation.ca</span> </h4>
                    <div className="nk-block-des ">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
              
        </>
    );
}

export default FailedPayment;