import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import BanksService from "./../../services/BanksService";
import Toastr from "../../Utils/Toastr";
import Spinner from "../../Utils/Spinner";
import Select from "react-select";

export default class AddAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCash: false,
      loading: false,
      data: {
        accountNumber: "",
        bankAddress: "",
        bankCode: "",
        sortCode: "",
        zipCode: "",
        accountType: "",
        accountingCode: "",
        currency: "",
      },
      bankAccount: [],
      paymentType: [
        { label: "CASH", value: "CASH" },
        { label: "BANK", value: "BANK" },
        { label: "CARD", value: "CARD" },
      ],
      currencyOption: [
        { label: "USD", value: "USD" },
        { label: "NGN", value: "NGN" },
      ],
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    const bankAccount = await BanksService.listBank();
    let accountOptions = [];
    if (bankAccount) {
      bankAccount.forEach((element) => {
        accountOptions.push({
          value: element.bankCode,
          label: element.bankName,
        });
      });
      this.setState({ bankAccount: accountOptions });
    }
    this.setState({ loading: false });
  };

  addBankAccount = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const Data = await BanksService.addBank(this.state.data);
    if (Data) {
      Toastr("success", "Account added successfully");
      setTimeout(() => {
        window.location.href = "/accounts";
      }, 2000);
    }
    this.setState({ loading: false });
  };

  handleBankChange = async ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data: data });
  };

  handleBankCodeChange = (bank) => {
    console.log(bank);
    if (bank) {
      const data = { ...this.state.data };
      data.bankCode = bank.value;
      this.setState({ data: data });
    }
  };

  handleAccountType = (option) => {
    if (option) {
      let data = { ...this.state.data };
      data.accountType = option.value;

      if (data.accountType == "CASH") {
        this.setState({ isCash: true });
      } else {
        this.setState({ isCash: false });
      }
      this.setState({ data: data });
    }
  };

  handleCurrencyChange = (option) => {
    if (option) {
      let data = { ...this.state.data };
      data.currency = option.value;
      this.setState({ data: data });
    }
  };

  render() {
    return (
      <MainLayout title="Add Account">
        <div className="col">
          <div className="card card-bordered col-lg-10">
            <div className="card-inner ">
              <form
                action=""
                onSubmit={async (e) => await this.addBankAccount(e)}
              >
                <div className="row g-4">
                  <div className="col-lg-6">
                    <div>
                      <label
                        className="form-label"
                        htmlFor="full-name-1"
                        style={{ visibility: "" }}
                      >
                        Account Type
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <Select
                        onChange={(option) => this.handleAccountType(option)}
                        options={this.state.paymentType}
                      />
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{
                      borderBottom: "1px #F1F1F1 solid",
                      height: "24px",
                    }}
                  >
                    <hr style={{ visibility: "hidden" }} />
                  </div>
                  {this.state.data?.accountType && (
                    <>
                      <div
                        className={`${this.state.isCash ? "col-6" : "col-6"}`}
                      >
                        <div className="form-group">
                          <label className="form-label" htmlFor="full-name-1">
                            {this.state.isCash
                              ? "Account Name"
                              : "Account Number"}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              name="accountNumber"
                              value={this.state.data.accountNumber}
                              onChange={this.handleBankChange}
                              className="form-control form-select-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${this.state.isCash ? "col-3" : "col-3"}`}
                      >
                        <div className="form-group">
                          <label className="form-label" htmlFor="full-name-1">
                            {this.state.isCash
                              ? "Accounting Code"
                              : "Accounting Code"}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              name="accountingCode"
                              value={this.state.data.accountingCode}
                              onChange={this.handleBankChange}
                              className="form-control form-select-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${this.state.isCash ? "col-3" : "col-3"}`}
                      >
                        <div className="form-group">
                          <label className="form-label" htmlFor="full-name-1">
                            {this.state.isCash ? "Currency" : "Currency"}
                          </label>
                          <div>
                            <Select
                              options={this.state.currencyOption}
                              onChange={this.handleCurrencyChange}
                            />
                          </div>
                          {/* <div className="form-control-wrap">
                          <input
                            type="text"
                            name="accountingCode"
                            value={this.state.data.accountingCode}
                            onChange={this.handleBankChange}
                            className="form-control form-select-lg"
                          />
                        </div> */}
                        </div>
                      </div>
                      {this.state.isCash ? null : (
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="full-name-1">
                              Bank Address
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                name="bankAddress"
                                value={this.state.data.bankAddress}
                                onChange={this.handleBankChange}
                                className="form-control form-select-lg"
                              ></input>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="full-name-1">
                            {this.state.isCash
                              ? "Account Number"
                              : "Swift Code"}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              name="zipCode"
                              value={this.state.data.zipCode}
                              onChange={this.handleBankChange}
                              className="form-control form-select-lg"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.isCash ? null : (
                        <>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="full-name-1"
                              >
                                Bank Name
                              </label>
                              <div className="form-control-wrap">
                                <Select
                                  className=""
                                  name="bankCode"
                                  id="bankCode"
                                  onChange={(option) =>
                                    this.handleBankCodeChange(option)
                                  }
                                  value={this.state.bankAccount.find(
                                    (obj) =>
                                      obj.value === this.state.data.bankCode
                                  )}
                                  options={this.state.bankAccount}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="full-name-1"
                              >
                                Sort Code
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="sortCode"
                                  value={this.state.data.sortCode}
                                  onChange={this.handleBankChange}
                                  className="form-control form-select-lg"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="col-lg-6">
                        {this.state.isCash ? (
                          <div>
                            <label
                              className="form-label"
                              htmlFor="full-name-1"
                              style={{ visibility: "hidden" }}
                            >
                              ****
                            </label>
                          </div>
                        ) : null}
                        <div className="form-group">
                          {this.state.loading ? (
                            <Spinner />
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary btn-block"
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
