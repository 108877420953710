import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import "../../sass/invoice.css";
import CategoriesService from "../../services/CategoriesService";
import CustomerService from "../../services/CustomerService";
import InvoiceService from "../../services/InvoiceService";
import ServicesService from "../../services/ServicesService";
import Spinner from "../../Utils/Spinner";
import Toastr from "../../Utils/Toastr";
import Select from "react-select";
import UsersService from "../../services/UsersService";
import Functions from "../../Utils/Functions";
import UploadedFiles from "../../Components/UploadedFiles";
export default class UpdateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      previouslyAttachedDocuments: [],
      supportingDocuments: [],
      categories: [],
      services: [],
      filteredServices: [],
      showCustomer: false,
      currencies: [
        { label: "NGN", value: "NGN", isBase: true },
        { label: "USD", value: "USD", isBase: false },
      ],
      customers: [],
      data: {
        amount: 0,
        currency: "",
        customerId: 0,
        serviceId: 0,
        categoryId: 0,
        customer: "",
        discountAmount: 0,
        dueDate: "",
        comments: "",
        lineItems: [],
        invoiceNumber: "",
        documents: [],
      },
      invoiceId: "",
      invoice: {
        amount: 0,
        customer: {},
        lineItems: [],
      },
      servicesArray: [],
      exchangeRate: "",
      officialRate: "",
    };
  }
  getInvoice = async (invoiceNumber) => {
    this.setState({ loading: true });
    var invoice = await InvoiceService.get(invoiceNumber);
    if (invoice) {
      console.log({invoice: invoice});
      // if(invoice){
      //   invoice.documents.forEach((inv) =>{
      //     inv.fileName = inv.description;
      //     inv.size = 20;
      //   })
      //   this.setState({ supportingDocuments: invoice.documents });
      //   this.setState({previouslyAttachedDocuments:invoice.documents})
      // }
      
      console.log(this.state.supportingDocuments);
      this.setState({ invoice: invoice });
      let data = { ...this.state.data };
      data = { ...invoice };
      data.customer =
        invoice.customer?.firstName + " " + invoice.customer?.lastName;
      data.customerId = invoice.customer?.id;
      data.discountAmount = invoice.discount;
      data.previouslyAttachedDocuments = invoice.documents;
      this.setState({ data: data });
      if (invoice.lineItems) {
        let lineItems = [];
        invoice.lineItems.forEach((element) => {
          lineItems.push({
            amount: element.amount,
            invoiceNumber: element.invoiceNumber,
            serviceId: element.serviceId,
            currency: invoice.currency,
          });
        });
        this.setState({ invoiceItems: lineItems });
      }
    }
    this.setState({ loading: false });
  };

  listCategories = async () => {
    this.setState({ loading: true });
    var categories = await CategoriesService.listCategories();
    if (categories) this.setState({ categories: categories });
    this.setState({ loading: false });
  };
  listServices = async () => {
    this.setState({ loading: true });
    var services = [];
    let serviceNeeded = [];
    services = await ServicesService.listServices();
    this.setState({servicesArray:services});
    if (services) {
      services.forEach((element) => {
        serviceNeeded.push({ value: element.id, label: element.servicename });
      });
      this.setState({ services: serviceNeeded });
    }
    this.setState({ loading: false });
  };
  assignCustomer = async (customer) => {
    let customerName = customer.firstName + " " + customer.lastName;
    const data = { ...this.state.data };
    data.customer = customerName;
    data.customerId = customer.id;
    this.setState({ data: data, customers: [] });
  };
  searchCustomers = async (search) => {
    this.setState({ loading: true });
    if (search.length >= 3) {
      this.state.showCustomer = true;
      var customers = await CustomerService.search(search);
      if (customers) {
        console.log(customers);
        this.setState({ customers: customers });
      }
    } else {
      this.setState({ customers: [] });
    }
    this.setState({ loading: false });
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data: data });
    if (parseInt(data.categoryId) > 0) {
      let filteredServices = this.state.services.filter(
        (obj) => obj.categoryId === parseInt(data.categoryId)
      );
      this.setState({ filteredServices: filteredServices });
    }
  };
  handleCurrencyChange = (option) => {
    if (option) {
      let data = { ...this.state.data };
      data.currency = option.value;
      let invoiceItems = [...this.state.invoiceItems];
      var services = this.state.servicesArray;
      invoiceItems.forEach( x=> {
        if(x.serviceId >  0) {
          let neededService = services.find(y=>y.id ===x.serviceId);
          x.amount = option.value === neededService.currency? neededService.amount: this.convertAmount(neededService.amount, option.value);
          x.currency = option.value;
        }
      });
      this.setState({ invoiceItems: invoiceItems, data: data });
    }
  };
  handleServiceChange = (option, index) => {
    var services = this.state.servicesArray;
    let data = { ...this.state.data };
    if (option) {
      let items = [...this.state.invoiceItems];
      let neededService = services.find(x=>x.id == option.value);
      items[index].serviceId = option.value;
      items[index].currency = data.currency;
      items[index].amount = data.currency === neededService.currency? neededService.amount: this.convertAmount(neededService.amount, data.currency);
      items[index].invoiceNumber = this.state.invoiceId;
      this.setState({ invoiceItems: items });
    }
  };

  convertAmount = (amount, currency) => {
    let currencies = this.state.currencies;
    var rate = parseInt(this.state.exchangeRate);
    var offRate = parseInt(this.state.officialRate);
    let currencyNeeded = currencies.find(x=>x.value === currency);
    var convertedAmount = currencyNeeded.isBase? (rate * amount) : (amount/offRate);
    return convertedAmount;
  }

  updateInvoice = async (e) => {
    e.preventDefault();
    
    this.setState({ loading: true });
    let data = { ...this.state.data };
    data.amount = parseFloat(data.amount);
    data.customerId = parseInt(data.customerId);
    data.discountAmount = parseFloat(data.discountAmount);
    let validItems = this.state.invoiceItems.filter(
      (obj) => obj.serviceId > 0 && obj.amount > 0 && obj.invoiceNumber != ""
    );
    if (validItems.length == 0) {
      Toastr("error", "You must add aleast one invoice item");
      this.setState({ loading: false });
    }
    let totalAmount = 0;
    validItems.forEach((element) => {
      element.amount = parseFloat(element.amount);
      element.serviceId = parseInt(element.serviceId);
      totalAmount = totalAmount + element.amount;
    });

    data.lineItems = [...validItems];
    data.invoiceNumber = this.state.invoiceId;
    // if(this.state.supportingDocuments === this.state.previouslyAttachedDocuments){
    //   data.documents = [];
    // }else{
    //   data.documents = this.state.supportingDocuments;
    // }
    data.documents = this.state.supportingDocuments || [];
    console.log(data);
    data.amount = totalAmount;
    let response = await InvoiceService.respondToRejection(data);
    if (response) {
      Toastr("success", "Your invoice is successfully updated.");
      setTimeout(() => {
        window.location.href = ("invoice?ref=" + this.state.invoiceId);
      }, 2000);
    }
    this.setState({ loading: false });
  };
  componentDidMount = async () => {
    // let invoiceId = this.props.match.params.invoiceId;
    let invoiceId = new URLSearchParams(window.location.search).get("ref");
    this.setState({ invoiceId: invoiceId });
    await this.listCategories();
    await this.listServices();
    await this.getInvoice(invoiceId);
    const settings = await UsersService.getSettingsItems();
    if(settings?.length > 0){
      settings.forEach(sett =>{
        if(sett.settingKey === "NGN_to_USD_Exchange_Rate"){
          this.setState({exchangeRate:sett.settingValue});
        }
        if(sett.settingKey === "USD_to_NGN_Exchange_Rate"){
          this.setState({officialRate:sett.settingValue});
        }
      });
    }
  };
  convertDatetoReadable(date) {
    if (date) {
      console.log(date);
      return new Date(date).toISOString().split("T")[0];
    }
    return "";
  }
  handleItemChange = (e, index) => {
    const { name, value } = e.target;
    let items = [...this.state.invoiceItems];
    items[index][name] = value;
    this.setState({ invoiceItems: items });
  };
  
  AddInvoiceItem = () => {
    let data = [...this.state.invoiceItems];
    this.setState({
      invoiceItems: [...data, { amount: 0, invoiceNumber: "", serviceId: 0 }],
    });
  };
  RemoveInvoiceItem = (index) => {
    let data = [...this.state.invoiceItems];
    data.splice(index, 1);
    this.setState({ invoiceItems: data });
  };

  filesList() {
    return (
      <div className="nk-files nk-files-view-grid">
        <div className="nk-files-list">
          {this.state.supportingDocuments.map((file, index) => (
            <div key={index} className="nk-file-item nk-file">
              <div className="nk-file-info">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <a className="nk-file-icon-link" href="#">
                      <span className="nk-file-icon-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 72 72"
                        >
                          <path
                            d="M49,61H23a5.0147,5.0147,0,0,1-5-5V16a5.0147,5.0147,0,0,1,5-5H40.9091L54,22.1111V56A5.0147,5.0147,0,0,1,49,61Z"
                            style={{ fill: "#e3edfc" }}
                          />
                          <path
                            d="M54,22.1111H44.1818a3.3034,3.3034,0,0,1-3.2727-3.3333V11s1.8409.2083,6.9545,4.5833C52.8409,20.0972,54,22.1111,54,22.1111Z"
                            style={{ fill: "#b7d0ea" }}
                          />
                          <path
                            d="M19.03,59A4.9835,4.9835,0,0,0,23,61H49a4.9835,4.9835,0,0,0,3.97-2Z"
                            style={{ fill: "#c4dbf2" }}
                          />
                          <rect
                            x={27}
                            y={31}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={35}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={39}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={43}
                            width={14}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={47}
                            width={8}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <a href="#" className="title">
                        {file.fileName}
                      </a>
                    </div>
                  </div>
                </div>
                <ul className="nk-file-desc">
                  <li className="size">
                    {Functions.currencyFormat(file.size / 1000)} kb
                  </li>
                </ul>
              </div>
              <div className="nk-file-actions">
                <div className="dropdown">
                  <a
                    href
                    className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-plain no-bdr">
                      <li>
                        <a onClick={() => this.removeSupportingDocument(index)} style={{cursor:"pointer"}}>
                          <em className="icon ni ni-trash" />
                          <span>Remove file</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  hiddenFileInput = {
    height: "45px",
    width: "130px",
    cursor: "pointer",
    position: "relative",
    opacity: "0",
    left: "-130px",
    top: "-25px",
    fontSize: 0,
  };

  removeSupportingDocument = (index) => {
    const docs = [...this.state.supportingDocuments];
    docs.splice(index, 1);
    this.setState({ supportingDocuments: docs });
  };

  grabUploadedFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    console.log(reader);
    reader.onloadend = () => {
      const supportingDocuments = this.state.supportingDocuments || [];
      const newFile = {
        description: file.name,
        base64String: reader.result.split(",")[1],
        fileName: file.name,
        size: file.size,
      };

      console.log("uploaded file:", newFile);
      if (newFile.size / 1000000 > 1.0) {
        Toastr("error", "File cannot be larger than 1MB");
      } else {
        supportingDocuments.push(newFile);
      }
      this.setState({ supportingDocuments: supportingDocuments });
    };
    reader.readAsDataURL(file);
  };

  render() {
    return (
      <MainLayout title="Respond to Rejection">
        <div className="col">
          <div className="nk-block nk-block-lg">
            <div className="card card-bordered">
              <div className="card-inner" style={{padding:"35px"}}>
                <form onSubmit={async (e) => await this.updateInvoice(e)}>
                  <div className="row g-4">
                    <div className="col-lg-8">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Customer
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            onChange={this.handleChange}
                            onKeyUp={async (e) =>
                              await this.searchCustomers(e.target.value)
                            }
                            value={this.state.data.customer}
                            className="form-control form-control-lg"
                            id="customer"
                            placeholder="search Customer"
                            name="customer"
                          />
                          <div className="customer-drop-down invoice-pad">
                            <ul>
                              {this.state.customers.map((customer, index) => (
                                <li
                                  key={index}
                                  onClick={async (e) =>
                                    await this.assignCustomer(customer)
                                  }
                                >
                                  <span className="customer-name">
                                    {customer.firstName} {customer.lastName}
                                  </span>
                                  <span className="customer-email">
                                    {customer.emailAddress}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                          <label className="form-label" htmlFor="currency">
                          Currency
                          </label>
                          <div className="form-control-wrap ">
                          <Select
                            value={this.state.currencies.find(
                              (obj) => obj.value === this.state.data.currency
                            )}
                            onChange={(option) =>
                              this.handleCurrencyChange(option)
                            }
                            options={this.state.currencies}
                          />
                          </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label fix-up" htmlFor="dueDate">
                              Due Date
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="date"
                                onChange={this.handleChange}
                                value={this.convertDatetoReadable(
                                this.state.data.dueDate
                                )}
                                id="dueDate"
                                name="dueDate"
                                className="form-control form-control-lg"/>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-label fix-up" htmlFor="discountAmount">
                            Discount
                          </label>
                          <div className="form-control-wrap">
                          <input
                            type="text"
                            onChange={this.handleChange}
                            value={this.state.data.discountAmount}
                            className="form-control form-control-lg"
                            id="discountAmount"
                            name="discountAmount"/>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr className="preview-hr" />
                    <span className="preview-title-lg overline-title">
                      Invoice Item{" "}
                    </span>

                    {this.state.invoiceItems && (
                      <div className="row gy-4 align-center">
                        {this.state.invoiceItems.map((item, index) => (
                          <>
                            <div className="col-lg-3">
                              <label className="form-label" htmlFor="serviceId">
                                Service
                              </label>
                              <div className="form-control-wrap ">
                                <Select
                                  value={this.state.services.find(
                                    (obj) =>
                                      obj.value === parseInt(item.serviceId)
                                  )}
                                  onChange={(option) =>
                                    this.handleServiceChange(option, index)
                                  }
                                  options={this.state.services}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-label" htmlFor="amount">
                                  Amount
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    //disabled
                                    value={item.amount? Number(parseFloat(Math.round(item.amount*100)/100)):0}
                                    type="text"
                                    onChange={(e) =>
                                      this.handleItemChange(e, index)
                                    }
                                    className="form-control form-control-lg inphgt"
                                    name="amount"
                                    //value={item.amount}
                                    placeholder="Input amount"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="invoiceNumber"
                                >
                                  Item Number
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    disabled
                                    value={item.invoiceNumber}
                                    type="text"
                                    onChange={(e) =>
                                      this.handleItemChange(e, index)
                                    }
                                    className="form-control form-control-lg"
                                    name="invoiceNumber"
                                    //value={item.invoiceNumber}
                                    placeholder="Input item number"
                                  />
                                </div>
                              </div>
                            </div>
                            {!this.state.invoiceItems[index + 1] && (
                              <div className="col-md-2 based based-update">
                                <div className="form-group">
                                  {/* <label className="form-label" style={{visibility: "hidden"}} htmlFor="invoiceNumber">Item Number</label> */}
                                  <div className="form-control-wrap mt-4 add-fix">
                                    <button
                                      onClick={() => this.AddInvoiceItem()}
                                      type="button"
                                      className="btn btn-block  btn-dark add-btn"
                                    >
                                      <em className="icon ni mr-3 ni-plus-c"></em>{" "}
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {this.state.invoiceItems.length > 1 && (
                              <div className="col-md-1">
                                <div className="form-group">
                                  {/* <label className="form-label" style={{visibility: "hidden"}} htmlFor="invoiceNumber">Item Number</label> */}
                                  <div className="form-control-wrap mt-4">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.RemoveInvoiceItem(index)
                                      }
                                    >
                                      <img src="/images/delete2.png" style={{width: "20px"}}></img>
                                      {/* <em className="icon ni ni-lg ni-cross-c"></em> */}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="col-12" style={{paddingLeft:"0px", paddingBottom:"15px"}}>
                              <hr className="preview-hr" />

                              <span className="preview-title-lg overline-title">
                                Upload Supporting Documents{" "}
                              </span>{" "}

                              <p>Maximum file size is 1MB</p>

                              <button className="btn btn-dim btn-outline-danger">
                                {this.state.supportingDocuments &&
                                this.state.supportingDocuments.length > 0
                                ? "+ Add another document"
                                : "Update document"}
                              </button>

                              <input
                                accept=".png, .jpg, .jpeg, .docx, .pdf"
                                type="file"
                                id="documentUploader"
                                style={this.hiddenFileInput}
                                title="+ Add supporting document"
                                onChange={(e) => this.grabUploadedFile(e)}
                              />

                              <div className="row gy-4 align-center">
                                {this.state.supportingDocuments &&
                                this.state.supportingDocuments.length > 0 && (
                                <div className="col-12">{this.filesList()}</div>
                                )}
                              </div>

                              {/* <div className="mt-3">
                                <span className="overline-title">
                                  Previously Uploaded document(s)
                                </span>

                                <div>
                                  {this.state.previouslyAttachedDocuments && (
                                    <UploadedFiles
                                      files={this.state.previouslyAttachedDocuments}
                                    />
                                  )}
                                </div>
                              </div> */}
                            </div>
                            <hr></hr>
                  <div className="gy-4 align-center">
                    <div className="col-lg-6" style={{paddingLeft:"0px"}}>
                      <div className="form-group">
                        <label className="form-label pt-2" htmlFor="comments">
                          Add Comments for the reviewer<small style={{color: "red", marginLeft: "5px"}}>Required</small>
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            onChange={this.handleChange}
                            id="comments"
                            name="comments"
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6" style={{paddingLeft:"0px"}}>
                      <div className="form-group">
                        <label
                          className="form-label buttonLabel"
                          htmlFor="Button"
                        >
                          top
                        </label>
                        <div className="form-control-wrap">
                          <div className="row">
                            <div className="col-md-6 ">
                              {this.state.loading ? (
                                <Spinner />
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-block  btn-primary fix-inv-btn"
                                >
                                  Submit Invoice
                                </button>
                              )}
                            </div>
                            <div className="col-md-6">
                              <Link
                                to={"/invoice/" + this.state.invoiceId}
                                className="btn btn-block btn-dark fix-inv-btn"
                              >
                                {" "}
                                <em className="icon ni ni-chevrons-left"></em>{" "}
                                Back to view
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
