import APICall from "../Utils/APICall";

export default class ServicesService {
    static listServices = async () => {
        return await APICall("/api/Services/list", "GET");
    };
    
    static listActiveServices = async () => {
        return await APICall("/api/Services/list/active", "GET");
    };

    static add = async (payload) => {
        return await APICall("/api/Services/add", "POST", payload);
    };
    static update = async (serviceId, payload) => {
        return await APICall(`/api/Services/update/${serviceId}`, "POST", payload);
    };

    static updateStatus = async (id, isActive) => {
        return await APICall(`/api/Services/toggle-status/${id}`,"POST", isActive);
    }
    static reports = async (reportType, request) => {
        return await APICall(
          `/api/Report?reportType=${reportType}`,
          "POST",
          request
        );
      };
}





