import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import "./settings.scss"
import Modal from "react-modal";
import Constants from "../../Utils/Constants";
import UsersService from "../../services/UsersService";
import Toastr from "../../Utils/Toastr";
import { DataContext } from "../../Contexts/DataContexts";
import Functions from "../../Utils/Functions";

export default class Settings extends Component {
  constructor(props) {
    super(props);
  }

  state = { 
    currentModalContent: {},
    modalIsOpen: false,
    payload: {},
    vat: "",
    sla: "",
    exchangeRate: "",
    officialRate: "",
    isLoading: false,
    user: {},
    isAccountant: false
    
  };

  static contextType = DataContext;

  requestRateUpdate=()=>{
    this.setState({modalIsOpen:true});
    this.setState({currentModalContent: this.confirmRate()});
  }

  requestVatUpdate=()=>{
    this.setState({modalIsOpen:true});
    this.setState({currentModalContent: this.confirmVat()});
  }

  requestTreasholdUpdate=()=>{
    this.setState({modalIsOpen:true});
    this.setState({currentModalContent: this.confirmTreashold()});
  }

  requestOfficialRateUpdate=()=>{
    this.setState({modalIsOpen:true});
    this.setState({currentModalContent: this.confirmOfficialRate()});
  }

  componentDidMount = async () => {
    this.setState({isLoading:true});
    const settings = await UsersService.getSettingsItems();
    this.setState({isLoading:false});
    console.log({settings:settings});
    if(settings?.length > 0){
      settings.forEach(sets => {
        if (sets.settingKey === "second_approval_threshold"){
          this.setState({sla:sets.settingValue});
        }
        if (sets.settingKey === "vat_percent"){
          this.setState({vat:sets.settingValue});
        }
        if (sets.settingKey === "NGN_to_USD_Exchange_Rate"){
          this.setState({exchangeRate:sets.settingValue});
        }
        if (sets.settingKey === "USD_to_NGN_Exchange_Rate"){
          this.setState({officialRate:sets.settingValue});
        }

      });
    }
    // const globalState = this.context.globalState;
    // if(globalState.user.role === "accountant"){
    //   this.setState({isAccountant:true});
    // }else{
    //   this.setState({isAccountant:false});
    // }
    // console.log({accountant:this.state.isAccountant});
  }

  updatePayload=(data, key)=>{
    const tempPayload = {...this.state.payload};
    tempPayload[key] = data;
    this.setState({payload: tempPayload});
    console.log({temppayload:tempPayload});
  }

  updateRate=async()=>{
    console.log({payload:this.state.payload})
    let value = null
    if(this.state.payload.rate === undefined){
      value = this.state.exchangeRate
    }else{
      value = this.state.payload.rate
    }
    console.log({value:value});
    const request = {
      settingKey: "NGN_to_USD_Exchange_Rate",
      settingValue: value
    }
    console.log({request:request});
    const response = await UsersService.postSettings(request);
    console.log({checkpost:response});
    this.setState({ modalIsOpen: false });
    if(response.settingValue !== null){
      Toastr("success", "Exchange rate updated successfully.");
      setTimeout(function(){
        window.location.reload();
     }, 2000);
    }else{
      Toastr("error", "Exchange rate update failed.");
      setTimeout(function(){
        window.location.reload();
     }, 2000);
    }
  }

  updateVat=async()=>{
    console.log({payload:this.state.payload})
    let value = null
    if(this.state.payload.vat === undefined){
      value = this.state.vat
    }else{
      value = this.state.payload.vat
    }
    const request = {
      settingKey: "vat_percent",
      settingValue: value,
    };
    const response = await UsersService.postSettings(request);
    this.setState({ modalIsOpen: false });
    if(response.settingValue !== null){
      Toastr("success", "Vat % updated successfully.");
      setTimeout(function(){
        window.location.reload();
     }, 2000);
    }else{
      Toastr("error", "Vat % update failed.");
      setTimeout(function(){
        window.location.reload();
     }, 2000);
    }
  }

  updateTreashold=async()=>{
    let value = null
    if(this.state.payload.sla === undefined){
      value = this.state.sla
    }else{
      value = this.state.payload.sla
    }
    const request = {
      settingKey: "second_approval_threshold",
      settingValue: value,
    };
    const response = await UsersService.postSettings(request);
    this.setState({ modalIsOpen: false });
    if(response.settingValue !== null){
      Toastr("success", "Approval treashold updated successfully.");
      setTimeout(function(){
        window.location.reload();
     }, 2000);
    }else{
      Toastr("error", "Approval treashold update failed.");
      setTimeout(function(){
        window.location.reload();
     }, 2000);
    }
  }

  updateOfficialRate=async()=>{
    let value = null
    if(this.state.payload.officialRate === undefined){
      value = this.state.officialRate
    }else{
      value = this.state.payload.officialRate
    }
    const request = {
      settingKey: "USD_to_NGN_Exchange_Rate",
      settingValue: value,
    };
    const response = await UsersService.postSettings(request);
    this.setState({ modalIsOpen: false });
    if(response.settingValue !== null){
      Toastr("success", "Official rate updated successfully.");
      setTimeout(function(){
        window.location.reload();
     }, 2000);
    }else{
      Toastr("error", "Official rate update failed.");
      setTimeout(function(){
        window.location.reload();
     }, 2000);
    }
  }

  confirmRate=()=>
    <><div className='message-pop'>
      <div className="pop-title">Are you sure you want to update the exchange rate?</div>
      <div className="btn-section">
        <div>
          <button className="modal-btn btn-cancel" type='button' onClick={() => this.setState({ modalIsOpen: false })} >Cancel</button>
        </div>
        <div>
          <button className="modal-btn btn-confirm" type='button' onClick={() => this.updateRate()}>Confirm</button>
        </div>
      </div>
    </div></>

confirmVat=()=>
<><div className='message-pop'>
  <div className="pop-title">Are you sure you want to update the Vat %?</div>
  <div className="btn-section">
    <div>
      <button className="modal-btn btn-cancel" type='button' onClick={() => this.setState({ modalIsOpen: false })} >Cancel</button>
    </div>
    <div>
      <button className="modal-btn btn-confirm" type='button' onClick={() => this.updateVat()}>Confirm</button>
    </div>
  </div>
</div></>

confirmTreashold=()=>
<><div className='message-pop'>
  <div className="pop-title">Are you sure you want to update approval treashhold?</div>
  <div className="btn-section">
    <div>
      <button className="modal-btn btn-cancel" type='button' onClick={() => this.setState({ modalIsOpen: false })} >Cancel</button>
    </div>
    <div>
      <button className="modal-btn btn-confirm" type='button' onClick={() => this.updateTreashold()}>Confirm</button>
    </div>
  </div>
</div></>

confirmOfficialRate=()=>
<><div className='message-pop'>
  <div className="pop-title">Are you sure you want to update the official rate?</div>
  <div className="btn-section">
    <div>
      <button className="modal-btn btn-cancel" type='button' onClick={() => this.setState({ modalIsOpen: false })} >Cancel</button>
    </div>
    <div>
      <button className="modal-btn btn-confirm" type='button' onClick={() => this.updateOfficialRate()}>Confirm</button>
    </div>
  </div>
</div></>

  render() {
    return (
      <MainLayout title="Settings">
        <Modal
          isOpen={this.state.modalIsOpen}
          style={Constants.invoiceModalStyle}
          ariaHideApp={false}
          onRequestClose={() => this.closeModal()} >
          <div>
          {/* <button type='button' onClick={() => this.setState({ modalIsOpen: false })} className="btn">X</button> */}
          </div>
          <div>{this.state.currentModalContent}</div>
      </Modal>
        {/* <div className="col"></div> */}
        <div className="settings-box">
          <div className="section-set row">
            <div className="settings-item col-md-4">
              <p className="set-item-title">Daily exchange rate update:</p>
            </div>
            <div className="settings-value col-md-3 remv-pad">
              <input className="form-control-lg value-box" 
              onChange={(e)=>this.updatePayload(e.target.value, 'rate')} 
              defaultValue={this.state.isLoading? "Loading..." : this.state.exchangeRate}
              />
            </div>
            <div className="settings-value col-md-4">
              <button type="button" onClick={() => this.requestRateUpdate()}  className="btn-lg update-btn">Update value</button>
            </div>
          </div>
          <div className="section-set row">
            <div className="settings-item col-md-4">
              <p className="set-item-title">Update FIRS VAT Rate:</p>
            </div>
            <div className="settings-value col-md-3 remv-pad">
              <input className="form-control-lg value-box" 
              onChange={(e)=>this.updatePayload(e.target.value, 'vat')} 
              defaultValue={this.state.isLoading? "Loading..." :this.state.vat} 
              //disabled={this.state.isAccountant}
              />
            </div>
            <div className="settings-value col-md-4">
              <button type="button" onClick={() => this.requestVatUpdate()}  className="btn-lg update-btn"
              //hidden={this.state.isAccountant}
              >Update value</button>
            </div>
          </div>
          <div className="section-set row">
            <div className="settings-item col-md-4">
              <p className="set-item-title">Second-level approval treashold:</p>
            </div>
            <div className="settings-value col-md-3 remv-pad">
              <input className="form-control-lg value-box" 
              onChange={(e)=>this.updatePayload(e.target.value, 'sla')} 
              defaultValue={this.state.isLoading? "Loading..." :this.state.sla}
              //disabled={this.state.isAccountant}
              />
            </div>
            <div className="settings-value col-md-4">
              <button type="button" onClick={() => this.requestTreasholdUpdate()}  className="btn-lg update-btn"
              //hidden={this.state.isAccountant}
              >Update value</button>
            </div>
          </div>
          <div className="section-set row">
            <div className="settings-item col-md-4">
              <p className="set-item-title">Official rate update:</p>
            </div>
            <div className="settings-value col-md-3 remv-pad">
              <input className="form-control-lg value-box" 
              onChange={(e)=>this.updatePayload(e.target.value, 'officialRate')} 
              defaultValue={this.state.isLoading? "Loading..." :this.state.officialRate}
              //disabled={this.state.isAccountant}
              />
            </div>
            <div className="settings-value col-md-4">
              <button type="button" onClick={() => this.requestOfficialRateUpdate()}  className="btn-lg update-btn"
              //hidden={this.state.isAccountant}
              >Update value</button>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
