import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import UsersService from "../../services/UsersService";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";
import Toastr from "../../Utils/Toastr";

export default class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
      page: 1,
      search: "",
      role: "",
      count: 0,
    };
  }
  componentDidMount = async () => {
    await this.listUser();
  };
  toggleActivateUser = async (userId) => {
    this.setState({ loading: true });
    let response = await UsersService.ToggleActive(userId);
    if (response) {
      Toastr("success", "Your request is processed successfully");
      await this.listUser();
    }
  };
  listUser = async () => {
    this.setState({ loading: true });
    const users = await UsersService.listUsers("all");
    if (users) {
      this.setState({ users: users.data });
      this.setState({ count: users.count });
    }
    this.setState({ loading: false });
  };

  previous = async () => {
    this.setState({ loading: true });
    if (this.state.page == 1) return;
    let previousPage = this.state.page - 1;
    const users = await UsersService.search(
      this.state.search,
      "all",
      previousPage,
      20
    );
    this.setState({ users: users.data });
    this.setState({ page: previousPage });
    this.setState({ loading: false });
  };

  next = async () => {
    this.setState({ loading: true });
    let nextPage = this.state.page + 1;
    const users = await UsersService.search(
      this.state.search,
      "all",
      nextPage,
      20
    );
    if (!users || users.length == 0) return;
    this.setState({ users: users.data });
    this.setState({ page: nextPage });
    this.setState({ loading: false });
  };

  search = async (text) => {
    const users = await UsersService.search(text, "all", 1, 20);
    this.setState({ users: users.data });
    this.setState({ page: 1, search: text });
  };

  tableRow = ({ fullname, email, role, id, isdisabled }) => {
    return (
      <div className="nk-tb-item">
        <div className="nk-tb-col">
          <div className="nk-tnx-type">
            <div className="nk-tnx-type-text">
              <span className="tb-lead">{fullname}</span>
            </div>
          </div>
        </div>

        <div className="nk-tb-col text-left">
          <span className="tb-amount">{email}</span>
        </div>
        <div className="nk-tb-col text-left">
          <span className="tb-amount">{role}</span>
        </div>
        <div className="nk-tb-col text-left">
          {isdisabled ? (
            <span className="badge badge-dot badge-danger">Disabled</span>
          ) : (
            <span className="badge badge-dot badge-success">Active</span>
          )}
        </div>
        {/* <div className="nk-tb-col text-left">
                    {isDisabled? <button className="btn btn-primary btn-md px-3">Activate</button> : <button className="btn btn-primary btn-md px-3">Disable</button>}
                </div> */}
        <div className="nk-tb-col nk-tb-col-status nk-tb-col-tools  text-right">
          <ul class="nk-tb-actions gx-1">
            <li>
              <div class="drodown">
                <a
                  href="#"
                  class="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                  data-toggle="dropdown"
                >
                  <em class="icon ni ni-more-h"></em>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="link-list-opt no-bdr">
                    <li>
                      <Link to={"/user/" + id}>
                        <em class="icon ni ni-eye"></em>
                        <span>View User</span>
                      </Link>
                    </li>

                    <li>
                      {isdisabled ? (
                        <a
                          onClick={async (e) =>
                            await this.toggleActivateUser(id)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <em class="icon ni ni-user-check"></em>
                          <span>Activate User</span>
                        </a>
                      ) : (
                        <a
                          onClick={async (e) =>
                            await this.toggleActivateUser(id)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <em class="icon ni ni-user-cross"></em>
                          <span>Disable User</span>
                        </a>
                      )}
                    </li>

                    <li>
                      <Link to={`/password-reset?email=${email}`}>
                        <em class="icon ni ni-user-cross"></em>
                        Reset Password
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          {/*
                    <Link
                        to={"/user/" + id}
                        className="btn btn-primary btn-md px-3"
                    >
                        <em class="icon ni ni-eye mr-1"></em>
                        View
                    </Link> */}
        </div>
      </div>
    );
  };

  render() {
    return (
      <MainLayout title="Users List">
        <div className="col">
          <div className="nk-content ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm ">
                    <div className="nk-block-between g-3">
                      <div className="nk-block-head-content">
                        <div className="nk-block-des text-soft">
                          <p>You have total {this.state.count} Users.</p>
                        </div>
                      </div>
                      <div className="nk-block-head-content">
                        <ul className="nk-block-tools g-3">
                          <li>
                            <Link
                              className="btn btn-primary btn-md"
                              to="/add-user"
                            >
                              {" "}
                              <em class="icon ni ni-plus-fill-c mr-2"></em>
                              New User
                            </Link>
                          </li>
                          {/* <li>
                            <a
                              href="#"
                              className="btn btn-white btn-dim btn-outline-light"
                            >
                              <em className="icon ni ni-download-cloud" />
                              <span>Export</span>
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title">All Users</h5>
                            </div>
                            <div className="card-tools mr-n1">
                              <ul className="btn-toolbar gx-1">
                                <li>
                                  <input
                                    onChange={(e) =>
                                      this.search(e.target.value)
                                    }
                                    type="search"
                                    class="form-control form-control-md"
                                    placeholder="Type in to search"
                                    id="search"
                                    aria-controls="customer_list_Table"
                                  />
                                </li>
                              </ul>
                            </div>
                            <div
                              className="card-search search-wrap"
                              data-search="search"
                            >
                              <div className="search-content">
                                <a
                                  href="#"
                                  className="search-back btn btn-icon toggle-search"
                                  data-target="search"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </a>
                                <button className="search-submit btn btn-icon">
                                  <em className="icon ni ni-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner p-0">
                          <div className="nk-tb-list nk-tb-tnx">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col tb-col">
                                <b>
                                  <span>Name</span>
                                </b>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span>Email</span>
                              </div>
                              <div className="nk-tb-col text-left">
                                <b>
                                  {" "}
                                  <span>Role</span>
                                </b>
                              </div>
                              <div className="nk-tb-col text-left">
                                <b>
                                  {" "}
                                  <span>Status</span>
                                </b>
                              </div>
                              <div className="nk-tb-col nk-tb-col-tools" />
                              <div className="nk-tb-col nk-tb-col-tools" />
                            </div>
                            {this.state.loading ? (
                              <div className="nk-tb-item">
                                <div className="nk-tb-col">
                                  <div className="nk-tnx-type">
                                    <div className="nk-tnx-type-text">
                                      <span className="tb-lead">
                                        <Spinner />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              this.state.users.map((user, index) => (
                                <this.tableRow key={index} {...user} />
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
