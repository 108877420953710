import APICall from "./APICall";
import React from "react";
import Constants from "./Constants";
import MyTable from "./MyTable";

export default class Functions {
  static voucherStatusThemes = {
    raised: "primary",
    responded: "warning",
    returned_by_accountant: "danger",
    approved_by_accountant: "info",
    returned_at_approval: "danger",
    first_approval: "info",
    true: "danger",
    first_and_final_approval: "success",
    second_approval: "success",
    paid: "light",
  };
  static RenderTable({
    headerList,
    propertyList,
    dataList,
    enableSearch,
    deleteAction,
    cssArray,
  }) {
    const _headerList = [];
    for (let i = 0; i < headerList.length; i++) {
      _headerList.push({ Header: headerList[i], accessor: propertyList[i] });
    }
    //const tableHeader = React.useMemo(() => _headerList, []);
    //const tableData = React.useMemo(() => dataList, []);

    const tableHeader = _headerList;
    const tableData = dataList;

    return (
      <div style={{ zoom: "0.85" }}>
        <MyTable
          columns={tableHeader}
          data={tableData}
          enableSearch={enableSearch}
          deleteAction={deleteAction}
          cssArray={cssArray}
        />
      </div>
    );
  }
  static toReadableDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }

  static sqlDateString(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  static isPhoneValid(phoneNumber) {
    if (phoneNumber.startsWith("0")) {
      phoneNumber = phoneNumber.substr(1);
    }

    var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    var digits = phoneNumber.replace(/\D/g, "");
    return phoneRe.test(digits);
  }

  static currencyFormat(amount) {
    return Number(parseFloat(amount).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }

  static ratingStars(noOfstars) {
    var stars = [];
    if (noOfstars <= 0) noOfstars = 1;
    for (var i = 1; i <= noOfstars; i++) {
      stars.push(i);
    }
    return stars.map((star, index) => (
      <i key={index} className="mdi mdi-star text-warning"></i>
    ));
  }

  static randomString(length) {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  formatDate(dateStr) {
    var monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var date = new Date(dateStr);

    var hour = date.getHours() - 1;
    var minutes = date.getMinutes();
    var meridiem = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour = hour - 12;
    }

    return (
      date.getDate() +
      "-" +
      monthNames[date.getMonth()] +
      "-" +
      date.getFullYear() +
      " " +
      hour +
      ":" +
      minutes +
      " " +
      meridiem
    );
  }

  static formatDate2(dateStr) {
    var monthNames = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    var date = new Date(dateStr);

    var hour = date.getHours() - 1;
    var minutes = date.getMinutes();
    var meridiem = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour = hour - 12;
    }

    return (
      date.getFullYear() +
      "-" +
      monthNames[date.getMonth()] +
      "-" +
      date.getDate()

      // date.getDate() +
      // "-" +
      // monthNames[date.getMonth()] +
      // "-" +
      // date.getFullYear()
      // +
      // " " +
      // hour +
      // ":" +
      // minutes +
      // " " +
      // meridiem
    );
  }

  static dateForm(dateStr) {
    var monthNames = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ];
    var date = new Date(dateStr);

    var hour = date.getHours() - 1;
    var minutes = date.getMinutes();
    var meridiem = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour = hour - 12;
    }

    return (
      date.getDate() +
      "/" +
      monthNames[date.getMonth()] +
      "/" +
      date.getFullYear()
      // +
      // " " +
      // hour +
      // ":" +
      // minutes +
      // " " +
      // meridiem
    );
  }

  static dateFormReport(dateStr) {
    var monthNames = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ];
    var date = new Date(dateStr);

    var hour = date.getHours() - 1;
    var minutes = date.getMinutes();
    var meridiem = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour = hour - 12;
    }

    return (
      monthNames[date.getMonth()] +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear()
      // +
      // " " +
      // hour +
      // ":" +
      // minutes +
      // " " +
      // meridiem
    );
  }

  async whoIsLoggedIn(callback, force) {
    var userString = localStorage.getItem("user");
    if (userString) {
      let userData = JSON.parse(userString);
      callback(userData, null);
    } else {
      var response = await APICall("/users/profile", "GET", {}, 0, true);
      if (response) {
        if (response.isSuccess) {
          callback(response.data, null);
        } else {
          callback(
            null,
            "Your request generated an error. Refresh or try again after sometime"
          );
        }
      } else {
        callback(
          null,
          "Your request generated an error. Please check your connection"
        );
      }
    }
  }

  static modalDefaultStyle() {
    return {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
  }
  static GetPathUrl(pathName) {
    let pathArray = pathName.split("/");
    return pathArray.slice(-1)[0];
  }
}
