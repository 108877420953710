import APICall from "../Utils/APICall";

export default class VoucherService {
  static raiseVoucher = async (payload) => {
     
    return await APICall("/api/Voucher/raise", "POST", payload);
  };

  static raiseCardVoucher = async (payload) => {
   
    return await APICall("/api/Voucher/raise-prepaid", "POST", payload);
  };

  static AccountantRejectVoucher = async (payload) => {
    payload.rejectionMessage = payload.rejectionMessage || payload.comments;
    return await APICall("/api/Voucher/accountant/reject", "POST", payload);
  };

  static AppprovalLevelReject = async (payload) => {
    payload.rejectionMessage = payload.rejectionMessage || payload.comments;
    return await APICall("/api/Voucher/approval_level/reject", "POST", payload);
  };

  static RespondToRejection = async (voucherCode, payload) => {
    return await APICall(
      `/api/Voucher/respondToRejection/${voucherCode}`,
      "POST",
      payload
    );
  };

  static voidVoucher = async (voucherCode) => {
    return await APICall(
      `/api/Voucher/void?voucherCode=${voucherCode}`,
      "POST"
    );
  };

  

  static approveVoucherAccountant = async ({
    voucherCode,
    disburseFromAccountId,
    comments,
    ledgerCode,
    bypassFirstLevelApproval,
  }) => {
    return await APICall("/api/voucher/approve/by_accountant", "POST", {
      voucherCode,
      disburseFromAccountId,
      comments,
      ledgerCode,
      bypassFirstLevelApproval,
    });
  };

  static firstLevelApproval = async ({ voucherCode, comments }) => {
    return await APICall("/api/voucher/approve/by_firstlevel", "POST", {
      voucherCode,
      comments,
    });
  };
  static secondLevelApproval = async ({ voucherCode, comments }) => {
    return await APICall("/api/voucher/approve/by_secondlevel", "POST", {
      voucherCode,
      comments,
    });
  };

  static finalProcessing = async ({ voucherCode, comments, dateDisbursed, bankAccountId, documents, ledgerCode }) => {

    return await APICall("/api/voucher/finalprocessing", "POST", {
      voucherCode,
      comments,
      dateDisbursed,
      bankAccountId,
      documents,
      ledgerCode
    });
  };

  static getVoucher = async (voucherCode) => {
    return await APICall(`/api/Voucher/get?voucherCode=${voucherCode}`, "GET");
  };

  static listVoucher = async (
    dateFrom,
    dateTo,
    page = 1,
    pageSize = 20,
    nextAction,
    forCurrentUser,
    voucherCode,
    voucherSearchText,
    voucherStatus,
    voucherType
  ) => {
    let url = `/api/Voucher/list/${dateFrom}/${dateTo}?page=${page}&pageSize=${pageSize}`;
    if (nextAction) url = `${url}&nextAction=${nextAction}`;
    if (forCurrentUser) url = `${url}&forCurrentUser=${forCurrentUser}`;
    if (voucherCode) url = `${url}&voucherCode=${voucherCode}`;
    if (voucherSearchText) url = `${url}&search=${voucherSearchText}`;
    if (voucherStatus) url = `${url}&voucherStatus=${voucherStatus}`;
    if (voucherType) url = `${url}&voucherType=${voucherType}`;
    return await APICall(url, "GET");
  };

  static dashVoucher = async (
    dateFrom,
    dateTo,
    page = 1,
    pageSize = 5,
    forCurrentUser,
    voucherStatus,
    voucherCode,
    voucherSearchText
  ) => {
    let url = `/api/Voucher/list/${dateFrom}/${dateTo}?page=${page}&pageSize=${pageSize}`;
    if (voucherStatus) url = `${url}&voucherStatus=${voucherStatus}`;
    if (forCurrentUser) url = `${url}&forCurrentUser=${forCurrentUser}`;
    if (voucherCode) url = `${url}&voucherCode=${voucherCode}`;
    if (voucherSearchText) url = `${url}&search=${voucherSearchText}`;
    return await APICall(url, "GET");
  };
}
