import Functions from "../Utils/Functions";

const FileList = ({ documents, removeDoc }) => {
  return (
    <div className="nk-files nk-files-view-grid">
      <div className="nk-files-list">
        {documents.map((file, index) => (
          <div key={index} className="nk-file-item nk-file">
            <div className="nk-file-info">
              <div className="nk-file-title">
                <div className="nk-file-icon">
                  <a className="nk-file-icon-link" href="#">
                    <span className="nk-file-icon-type">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 72 72"
                      >
                        <path
                          d="M49,61H23a5.0147,5.0147,0,0,1-5-5V16a5.0147,5.0147,0,0,1,5-5H40.9091L54,22.1111V56A5.0147,5.0147,0,0,1,49,61Z"
                          style={{ fill: "#e3edfc" }}
                        />
                        <path
                          d="M54,22.1111H44.1818a3.3034,3.3034,0,0,1-3.2727-3.3333V11s1.8409.2083,6.9545,4.5833C52.8409,20.0972,54,22.1111,54,22.1111Z"
                          style={{ fill: "#b7d0ea" }}
                        />
                        <path
                          d="M19.03,59A4.9835,4.9835,0,0,0,23,61H49a4.9835,4.9835,0,0,0,3.97-2Z"
                          style={{ fill: "#c4dbf2" }}
                        />
                        <rect
                          x={27}
                          y={31}
                          width={18}
                          height={2}
                          rx={1}
                          ry={1}
                          style={{ fill: "#7e95c4" }}
                        />
                        <rect
                          x={27}
                          y={35}
                          width={18}
                          height={2}
                          rx={1}
                          ry={1}
                          style={{ fill: "#7e95c4" }}
                        />
                        <rect
                          x={27}
                          y={39}
                          width={18}
                          height={2}
                          rx={1}
                          ry={1}
                          style={{ fill: "#7e95c4" }}
                        />
                        <rect
                          x={27}
                          y={43}
                          width={14}
                          height={2}
                          rx={1}
                          ry={1}
                          style={{ fill: "#7e95c4" }}
                        />
                        <rect
                          x={27}
                          y={47}
                          width={8}
                          height={2}
                          rx={1}
                          ry={1}
                          style={{ fill: "#7e95c4" }}
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <div className="nk-file-name">
                  <div className="nk-file-name-text">
                    <a href="#" className="title">
                      {file.fileName}
                    </a>
                  </div>
                </div>
              </div>
              <ul className="nk-file-desc">
                <li className="size">
                  {Functions.currencyFormat(file.size / 1000)} kb
                </li>
              </ul>
            </div>
            <div className="nk-file-actions">
              <div className="dropdown">
                <a
                  href
                  className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                  data-toggle="dropdown"
                >
                  <em className="icon ni ni-more-h" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <ul className="link-list-plain no-bdr">
                    <li>
                      <a onClick={() => removeDoc(index)}>
                        <em className="icon ni ni-trash" />
                        <span>Remove file</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileList;
