import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import Toastr from "../../Utils/Toastr";
import Spinner from "../../Utils/Spinner";
import CustomerService from "./../../services/CustomerService"
import { Link } from "react-router-dom";
import Functions from "../../Utils/Functions";
export default class CuatomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      customerId: "",
      customer: [],
      data: {
        firstname: "",
        lastname: "",
        email: "",
        companyName: "",
        phoneNumber: "",
        contactAddress: "",
      }
    };
  }
  getCustomer = async (customerNumber) => {
    this.setState({ loading: true });
    const customer = await CustomerService.get(customerNumber);
    if (customer) {
      let data = { ...this.state.data };
      data.firstname = customer.firstName;
      data.lastname = customer.lastName;
      data.email = customer.emailAddress;
      data.phoneNumber = customer.phoneNumber;
      data.contactAddress = customer.contactAddress;
      data.companyName = customer.company;
      this.setState({ data: data });
      this.setState({ customer: customer })
    };
    this.setState({ loading: false });
  }

  componentDidMount = async () => {
    let customerId = Functions.GetPathUrl(window.location.pathname);
    this.setState({ customerId: customerId })
    await this.getCustomer(customerId)
  };

  handleCustomerUpdate = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (data.name) {

    }
    this.setState({ data });
  }

  updateCustomer = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var updateData = await CustomerService.update(this.state.customerId, this.state.data);
    if (updateData) {
      Toastr("success", "Customer Details Successfully Updated");
      setTimeout(() => {
        this.props.history.push('/customers');
      }, 3000);
    };
    this.setState({ loading: false });
  }


  render() {
    return <MainLayout title="Customer Details/Update">
      <div classname="col">
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered col-lg-7">
            <div className="card-inner">
              <form action="" onSubmit={async (e) => await this.updateCustomer(e)}>
                <div className="row g-4">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="full-name-1">Customer First Name</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="firstname"
                          value={this.state.data.firstname}
                          onChange={this.handleCustomerUpdate}
                          className="form-control form-control-lg" id="full-name-1" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="full-name-1">Customer Last Name</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="lastname"
                          value={this.state.data.lastname}
                          onChange={this.handleCustomerUpdate}
                          className="form-control form-control-lg" id="full-name-1" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="company-name">Company Name</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="companyName"
                          value={this.state.data.companyName}
                          onChange={this.handleCustomerUpdate}
                          className="form-control form-control-lg" id="company-name" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="email-address-1">Email address</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="email"
                          value={this.state.data.email}
                          onChange={this.handleCustomerUpdate}
                          className="form-control form-control-lg" id="email-address-1" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="phone-no-1">Phone Number</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="phoneNumber"
                          value={this.state.data.phoneNumber}
                          onChange={this.handleCustomerUpdate}
                          className="form-control form-control-lg" id="phone-no-1" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="contactAddress">Contact Address</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="contactAddress"
                          value={this.state.data.contactAddress}
                          onChange={this.handleCustomerUpdate}
                          className="form-control form-control-lg" id="contactAddress" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-control-wrap">
                            {this.state.loading ? <Spinner /> : <button type="submit" className="btn btn-lg btn-primary btn-block">Update Customer</button>}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-control-wrap">
                            <Link to="/customers" className="btn btn-lg btn-dark btn-block"> <em className="icon ni ni-chevrons-left"></em> Back to Customers</Link>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </MainLayout>;
  }
}
