import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import "../../sass/invoice.css";
import Spinner from "../../Utils/Spinner";
import Toastr from "../../Utils/Toastr";
import Select from "react-select";
import VoucherService from "../../services/VoucherService";
import BanksService from "../../services/BanksService";
import Functions from "../../Utils/Functions";

export default class VoucherReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportingDocuments: [],
      loading: false,
      pageLoading: true,
      categories: [],
      services: [],
      showCustomer: false,
      voucherItems: [{ ...this.newVoucher }],
      currencies: [
        { label: "Naira", value: "NGN" },
        { label: "Dollars", value: "USD" },
        // { label: "Canadian Dollars", value: "CAD" }
      ],
      customers: [],
      data: {
        currency: "",
        beneficiaryName: "",
      },
      filesToRemove: [],
    };
  }
  listBanks = async () => {
    this.setState({ loading: true });
    let banks = await BanksService.listBank();
    if (banks) {
      banks.forEach((element) => {
        element.label = element.bankName;
        element.value = element.bankCode;
      });
      this.setState({ banks: banks });
    }
    this.setState({ loading: false });
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data: data });
  };
  updateVoucher = async (e) => {
    e.preventDefault();

    let payload = { ...this.state.data };
    if (!payload.currency) {
      Toastr("error", "Currency selection is required");
      this.setState({ loading: false });
      return;
    }
    if (!payload.beneficiaryName) {
      Toastr("error", "Beneficiary name is required");
      this.setState({ loading: false });
    }
    let validItems = [
      ...(this.state.voucherItems.filter(
        (obj) => obj.amount && parseFloat(obj.amount.toString()) > 0
      ) || []),
    ];

    if (validItems.length == 0) {
      Toastr("error", "You must add at least one item");
      this.setState({ loading: false });
      return;
    }

    let isValid = true;
    validItems.forEach((a) => {
      a.amount = parseFloat(a.amount);
      if (!a.description) {
        isValid = false;
      }
    });

    if (!isValid) {
      Toastr(
        "error",
        "There were errors with your voucher items. A description is required"
      );
      return;
    }

    payload.lineItems = validItems;
    payload.documentsToAdd = (this.state.supportingDocuments || []).filter(
      (a) => a.base64String
    );
    payload.fileReferencesToRemove = [...this.state.filesToRemove];

    this.setState({ loading: true });
    let response = await VoucherService.RespondToRejection(
      this.state.data.voucherCode,
      payload
    );
    if (response) {
      Toastr("success", "Your response was submitted.");
      setTimeout(() => {
        window.location.href = `/voucher/${response.voucherCode}`;
      }, 2000);
    }
    this.setState({ loading: false });
  };
  newVoucher = {
    amount: null,
    description: "",
    bankCode: "",
    accountNumber: "",
  };
  AddVoucherItem = () => {
    let data = [...this.state.voucherItems];
    this.setState({ voucherItems: [...data, { ...this.newVoucher }] });
  };
  RemoveVoucherItem = (index) => {
    console.log("index passed: ", index);
    let data = [...this.state.voucherItems];
    data.splice(index, 1);
    this.setState({ voucherItems: data });
  };
  handleItemChange = (prop, value, index) => {
    let items = [...this.state.voucherItems];
    items[index][prop] = value;
    this.setState({ voucherItems: items });
  };
  handleBeneficiaryChange = (value) => {
    if (value) {
      let items = { ...this.state.data };
      items.beneficiaryName = value;
      this.setState({ data: items });
    }
  };
  handleCurrencyChange = (option) => {
    if (option) {
      let data = { ...this.state.data };
      data.currency = option.value;
      this.setState({ data: data });
    }
  };

  handleCommentChange = (comment) => {
    let data = { ...this.state.data };
    data.comments = comment;
    this.setState({ data: data });
  };

  componentDidMount = async () => {
    await this.listBanks();
    let voucher = await VoucherService.getVoucher(
      Functions.GetPathUrl(window.location.pathname)
    );
    console.log({ seecontent: voucher });
    if (voucher.lineItems) {
      voucher.lineItems.forEach((element) => {
        element.accountNumber = element.settlementBankAccount;
        element.bankCode = element.settlementBankCode;
      });
    }
    this.setState({
      data: voucher,
      voucherItems: voucher?.lineItems,
      supportingDocuments: voucher?.documents,
    });
    this.setState({ pageLoading: false });
  };

  filesList() {
    return (
      <div className="nk-files nk-files-view-grid">
        <div className="nk-files-list">
          {this.state.supportingDocuments.map((file, index) => (
            <div key={index} className="nk-file-item nk-file">
              <div className="nk-file-info">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <a className="nk-file-icon-link" href="#">
                      <span className="nk-file-icon-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 72 72"
                        >
                          <path
                            d="M49,61H23a5.0147,5.0147,0,0,1-5-5V16a5.0147,5.0147,0,0,1,5-5H40.9091L54,22.1111V56A5.0147,5.0147,0,0,1,49,61Z"
                            style={{ fill: "#e3edfc" }}
                          />
                          <path
                            d="M54,22.1111H44.1818a3.3034,3.3034,0,0,1-3.2727-3.3333V11s1.8409.2083,6.9545,4.5833C52.8409,20.0972,54,22.1111,54,22.1111Z"
                            style={{ fill: "#b7d0ea" }}
                          />
                          <path
                            d="M19.03,59A4.9835,4.9835,0,0,0,23,61H49a4.9835,4.9835,0,0,0,3.97-2Z"
                            style={{ fill: "#c4dbf2" }}
                          />
                          <rect
                            x={27}
                            y={31}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={35}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={39}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={43}
                            width={14}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={47}
                            width={8}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <a href="#" className="title">
                        {file.fileName || file.description}
                      </a>
                    </div>
                  </div>
                </div>
                <ul className="nk-file-desc">
                  {file.size && (
                    <li className="size">
                      {Functions.currencyFormat(file.size / 1000)} kb
                    </li>
                  )}
                </ul>
              </div>
              <div className="nk-file-actions">
                <div className="dropdown">
                  <a
                    href
                    className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-plain no-bdr">
                      <li>
                        <a
                          onClick={() =>
                            this.removeSupportingDocument(file, index)
                          }
                        >
                          <em className="icon ni ni-trash" />
                          <span>Remove file</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  hiddenFileInput = {
    height: "45px",
    width: "130px",
    cursor: "pointer",
    position: "relative",
    opacity: "0",
    left: "-130px",
    top: "-25px",
    fontSize: 0,
  };

  removeSupportingDocument = (file, index) => {
    if (file.fileReference) {
      const filesToRemove = this.state.filesToRemove;
      filesToRemove.push(file.fileReference);
    }

    const docs = [...this.state.supportingDocuments];
    docs.splice(index, 1);
    this.setState({ supportingDocuments: docs });
  };

  grabUploadedFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const supportingDocuments = this.state.supportingDocuments || [];
      const newFile = {
        description: file.name,
        base64String: reader.result.split(",")[1],
        fileName: file.name,
        size: file.size,
      };
      console.log("uploaded file:", newFile);
      if (newFile.size / 1000000 > 1.0) {
        Toastr("error", "File cannot be larger than 1MB");
      } else {
        supportingDocuments.push(newFile);
      }
      this.setState({ supportingDocuments: supportingDocuments });
    };
    reader.readAsDataURL(file);
  };

  render() {
    return (
      <MainLayout title="Review Voucher">
        <div className="col">
          <div className="nk-block nk-block-lg">
            <div className="card card-bordered">
              <div className="card-inner">
                {this.state.pageLoading && <Spinner />}
                {this.state.data && (
                  <form onSubmit={async (e) => await this.updateVoucher(e)}>
                    <div className="row g-12">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="currency">
                            Currency<small className="text-danger">*</small>
                          </label>
                          <div className="form-control-wrap ">
                            <Select
                              onChange={(option) =>
                                this.handleCurrencyChange(option)
                              }
                              options={this.state.currencies}
                              value={this.state.currencies.find(
                                (a) => a.value == this.state.data.currency
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <label className="form-label" htmlFor="currency">
                            Beneficiary Name
                            <small className="text-danger">*</small>
                          </label>
                          <div className="form-control-wrap ">
                            <input
                              minLength={10}
                              value={this.state.data.beneficiaryName}
                              type="text"
                              onChange={(e) =>
                                this.handleBeneficiaryChange(e.target.value)
                              }
                              className="form-control form-control-lg"
                              placeholder="Beneficiary Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        {/* <hr className="preview-hr" /> */}
                        <span
                          className="preview-title-lg overline-title"
                          style={{ marginTop: "50px" }}
                        >
                          Voucher Items
                          <hr
                            className="preview-hr"
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </span>

                        <div className="row gy-4 align-center">
                          {this.state.voucherItems.map((item, index) => (
                            <div className="col-12">
                              <div
                                className="row"
                                style={{ marginBottom: "20px" }}
                              >
                                <div
                                  className="col-md-6"
                                  style={{ paddingRight: "0px" }}
                                >
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Item description{" "}
                                        <small className="text-danger">*</small>
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          value={item.description}
                                          type="text"
                                          onChange={(e) =>
                                            this.handleItemChange(
                                              "description",
                                              e.target.value,
                                              index
                                            )
                                          }
                                          className="form-control form-control-lg"
                                          placeholder="Item description"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="amount"
                                        style={{ marginTop: "20px" }}
                                      >
                                        Amount{" "}
                                        <small className="text-danger">*</small>
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            this.handleItemChange(
                                              "amount",
                                              e.target.value,
                                              index
                                            )
                                          }
                                          className="form-control form-control-lg"
                                          value={item.amount}
                                          placeholder="Enter amount"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div
                                    className="col-md-12"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    <label
                                      className="form-label"
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      Account number{" "}
                                      <small
                                        style={{
                                          color: "red",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        Ignore if cash payment
                                      </small>
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        minLength={10}
                                        value={
                                          item.accountNumber ||
                                          item.settlementBankAccount
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.handleItemChange(
                                            "accountNumber",
                                            e.target.value,
                                            index
                                          )
                                        }
                                        className="form-control form-control-lg"
                                        placeholder="Account number"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-12"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    <label
                                      className="form-label"
                                      style={{ marginTop: "20px" }}
                                    >
                                      Beneficiary bank account{" "}
                                      <small
                                        style={{
                                          color: "red",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        Ignore if cash payment
                                      </small>{" "}
                                    </label>
                                    <div className="pr-0">
                                      <div className="form-control-wrap ">
                                        <Select
                                          onChange={(option) =>
                                            this.handleItemChange(
                                              "bankCode",
                                              option.bankCode,
                                              index
                                            )
                                          }
                                          options={this.state.banks}
                                          value={
                                            this.state.banks &&
                                            this.state.banks.find(
                                              (a) =>
                                                a.bankCode ==
                                                (item.bankCode ||
                                                  item.settlementBankCode)
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.voucherItems.length > 1 && (
                                  <div className="col-md-1">
                                    <div className="form-group">
                                      <div className="form-control-wrap mt-4">
                                        <a
                                          className="mt-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.RemoveVoucherItem(index)
                                          }
                                        >
                                          {/* <em className="icon ni ni-lg ni-cross-c"></em> */}
                                          <img
                                            src="/images/delete2.png"
                                            style={{ width: "20px" }}
                                          ></img>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <hr
                                className="preview-hr"
                                style={{ marginBottom: "0px" }}
                              />
                            </div>
                          ))}

                          {
                            <div className="col-md-2 pr-0">
                              <div className="form-group">
                                <div className="form-control-wrap mt-0">
                                  <button
                                    onClick={() => this.AddVoucherItem()}
                                    type="button"
                                    className="mt-2 btn btn-link text-dark fix-add-vouch"
                                  >
                                    <em className="icon ni mr-3 ni-plus-c"></em>{" "}
                                    Add voucher item
                                  </button>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>

                      <div>
                        <hr className="preview-hr" />
                        <span className="preview-title-lg overline-title">
                          Upload Supporting Documents{" "}
                        </span>{" "}
                        <p>Maximum file size is 1MB</p>
                        <button className="btn btn-dim btn-outline-danger">
                          {this.state.supportingDocuments &&
                          this.state.supportingDocuments.length > 0
                            ? "+ Add another document"
                            : "Add document"}
                        </button>
                        <input
                          accept=".png, .jpg, .jpeg, .pdf, .docx"
                          type="file"
                          id="documentUploader"
                          style={this.hiddenFileInput}
                          title="+ Add supporting document"
                          onChange={(e) => this.grabUploadedFile(e)}
                        />
                        <div className="row gy-4 align-center">
                          {this.state.supportingDocuments &&
                            this.state.supportingDocuments.length > 0 && (
                              <div className="col-12">{this.filesList()}</div>
                            )}
                        </div>
                      </div>
                      <div className="row gy-4 align-center">
                        <div className="col-lg-6 mt-5">
                          <div className="form-group">
                            <label className="form-label ">
                              Add a comment for the reviewer{" "}
                              <small
                                style={{ color: "red", marginLeft: "5px" }}
                              >
                                Required
                              </small>
                            </label>
                            <div className="form-control-wrap">
                              <div className="row">
                                <div className="col-12 mb-0">
                                  <textarea
                                    className="form-control"
                                    onChange={(e) =>
                                      this.handleCommentChange(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row gy-4 align-center">
                        <div className="col-lg-6 mt-5">
                          <div className="form-group">
                            <label
                              className="form-label buttonLabel"
                              htmlFor="Button"
                            ></label>
                            <div className="form-control-wrap">
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  {this.state.loading ? (
                                    <Spinner />
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-block  btn-primary fix-inv-btn"
                                    >
                                      Submit Voucher Review
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 mt-1">
                                  <Link to="/vouchers" className="btn btn-link">
                                    {" "}
                                    <em className="icon ni ni-chevrons-left"></em>{" "}
                                    Back to List
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
