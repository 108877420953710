import React, { Component } from "react";
import MainLayout from "../Components/Layouts/MainLayout";
import Spinner from "../Utils/Spinner";
import Select from "react-select";
import Functions from "../Utils/Functions";
import { FormatMoney } from "../Utils/format-currency";
import { DataContext } from "../Contexts/DataContexts";
import BanksService from "../services/BanksService";
import "../../src/sass/archive.css"
import Toastr from "../Utils/Toastr";
import ArchiveService from "../services/ArchiveService";
import { forEach } from "lodash";


export default class Archive extends Component {
  constructor(props) {
    super(props);
  }

  state = { 
    loading: false,
    banks: [],
    users: [],
    payload:{},
    user: "",
    bankLoading: false,
    months:[],
    years:[],
    hideAccounts: false,
    accountFilterType:[],
    filter:"",
    emptyTableMessage:"Apply filter to view documents",
    fetching: false,
    archiveData:[],
    dataAvailable:false,
    
  };

  static contextType = DataContext;

  componentDidMount=async()=>{
    this.setState({loading:true});
    this.listBanks();
    const globalState = this.context.globalState;
    const filterType= [
      {label:"Single Account" , value:"one"},
      {label:"All Accounts" , value:"all"},
    ]
    this.setState({accountFilterType:filterType});
    const listMonths= [
      {label:"January" , Value:"January"},
      {label:"February" , Value:"February"},
      {label:"March" , Value:"March"},
      {label:"April" , Value:"April"},
      {label:"May" , Value:"May"},
      {label:"June" , Value:"June"},
      {label:"July" , Value:"July"},
      {label:"August" , Value:"August"},
      {label:"September" , Value:"September"},
      {label:"October" , Value:"October"},
      {label:"November" , Value:"November"},
      {label:"December" , Value:"December"},
    ]
    this.setState({months:listMonths});


    const startYear = 2020; // You can set this to any start year you want
    const yearLabelsArray = this.getYears(startYear);
    console.log(yearLabelsArray);
    this.setState({years:yearLabelsArray});
    
    // console.log({globalState:globalState})
    // if(globalState.user?.role?.toLowerCase() === "accountant"){
    //   this.setState({isAccountant:true});
    //   this.setState({user:globalState.user.fullname});
    // }
    this.setState({loading:false});
  }

  getYears(startYear) {
    const currentYear = new Date().getFullYear();
    let yearLabels = [];
    for (let year = startYear; year <= currentYear; year++) {
        yearLabels.push({ label: year, value: year });
    }
    return yearLabels;
}

  

  listBanks=async()=>{
    this.setState({bankLoading:true});
    const banks = await BanksService.listBankAccount();
    console.log({banks:banks});
    if (banks.length > 0){
      banks.forEach(bank => {
        bank.label = bank.bankName + "  -  " + bank.accountNumber;
        bank.value = bank.accountNumber;
        this.setState({banks:banks});
      });
    }
    this.setState({bankLoading:false});
  }


  updatePayload=(data, key)=>{
    const tempPayload = {...this.state.payload};
    tempPayload[key] = data;
    this.setState({payload: tempPayload});
    console.log({temppayload:tempPayload});
  }

  switchFilter=(option)=>{
    if (option) {
      let data = { ...this.state.data };
      data.selection = option.label;
      if (data.selection == "All Accounts"){
        this.setState({hideAccounts:true});
        this.updatePayload(0, "accountId");
      }else if(data.selection == "Single Account"){
        this.setState({hideAccounts:false});
        
      };
    }
  }

  fetchArchive= async()=>{
    console.log(this.state.payload);
    this.setState({fetching:true});
    let page = 1;
    let pageSize = 50;
    let month = this.state.payload.month;
    let year = this.state.payload.year;
    let accountId = this.state.payload.accountId;
    const response= await ArchiveService.fetchArchive(page, pageSize, month, year, accountId);
    console.log({response:response});
    if(response.count > 0){
      this.setState({archiveData:response.data});
      this.setState({dataAvailable:true});
    }else{
      this.setState({dataAvailable:false});
      Toastr("info", "No document found matching parameter");
      this.setState({emptyTableMessage:"No documents match your filter parameters"});
    };
    
    this.setState({fetching:false});
  }


  



 

  render() {
    return (
      <MainLayout title="Archive">
      {this.state.loading? <Spinner />:
      <div className="dash-box-report col-md-12">
            <div className="filter-sect filter-sect2" style={{paddingBottom:"30px", backgroundColor:"#f1f1f1"}}>
              <div className="row" style={{marginBottom:"20px"}}>
              <div className="col-md-6">
                <Select 
                  placeholder="All or Single account"
                  options={this.state.accountFilterType}
                  onChange={(option) => this.switchFilter(option)}
                  />
              </div>
              </div>
              <div className="row">
                  <div className="col-md-6" hidden={this.state.hideAccounts} style={{marginBottom:"20px"}}>
                    <Select
                      options={this.state.banks} 
                      placeholder="Select bank or cash account"
                      onChange={(option)=>this.updatePayload(option.id, 'accountId')}/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-4 filter">
                    <div className="" style={{width:"100%"}}>
                      <Select 
                        placeholder="Month"
                        onChange={(option)=>this.updatePayload(option.Value, 'month')}
                        options={this.state.months}/>
                    </div>
                    <div className="" style={{ marginLeft: "20px", width:"100%" }}>
                      <Select 
                        placeholder="Year"
                        onChange={(option)=>this.updatePayload(option.value.toString(), 'year')}
                        options={this.state.years}/>
                    </div>
                  </div>
                  <div className="col-md-2" style={{maxWidth:"100%"}}>
                    {this.state.fetching? <Spinner />:
                    <button onClick={() => this.fetchArchive()} className="btn btn-lg filter-btn">Fetch</button>}
                  </div>
              </div>
            </div>
            {this.state.dataAvailable?
            <div className="archive-box">
            {this.state.archiveData.map((data, i) => (
              <div className="Folder-box">
                <a  href={data.archiveUrl} target="_blank" rel="noopener noreferrer">
                <img 
                  src="/images/folder2.jpg"
                  style={{width:"100px"}}/>
                <p className="archiveText">{data.archiveName}</p>
                </a>
              </div>))}
            </div>:
            <div className="place-holder">
              <p className="place-holder-text">{this.state.emptyTableMessage}</p>
            </div>}
      </div>}
      </MainLayout>
    );
  }
}
