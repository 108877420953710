import APICall from "../Utils/APICall";

export default class InvoiceService {
  static raise = async (payload) => {
    return await APICall(`/api/Invoice/raise`, "POST", payload);
  };
  static reject = async (payload) => {
    return await APICall(`/api/Invoice/reject`, "POST", payload);
  };
  static confirm = async (invoiceNumber) => {
    return await APICall(
      `/api/Invoice/confirm_payment?invoiceNumber=${invoiceNumber}`,
      "POST",
      invoiceNumber
    );
  };
  static respondToRejection = async (payload) => {
    return await APICall(`/api/Invoice/respondToRejection`, "POST", payload);
  };
  static get = async (invoiceNumber) => {
    return await APICall(
      `/api/Invoice/get?invoiceNumber=${invoiceNumber}`,
      "GET"
    );
  };

  static voidInvoice = async (invoiceNumber) => {
    return await APICall(
      `/api/Invoice/void?invoiceNumber=${invoiceNumber}`,
      "POST"
    );
  };

  static download = async (invoiceNumber) => {
    return await APICall(
      `/Download/receipt/invoiceNumber=${invoiceNumber}`,
      "GET"
    );
  };
  static list = async (
    from,
    to,
    page = 1,
    pageSize = 10,
    invoiceStatus,
    createdBy,
    forCurrentUser,
    approvedBy,
    customerId,
    serviceId,
    invoiceNumber
  ) => {
    let url = `/api/Invoice/list/${from}/${to}?page=${page}&pageSize=${pageSize}`;
    if (invoiceStatus) url = `${url}&invoiceStatus=${invoiceStatus}`;
    if (createdBy) url = `${url}&createdBy=${createdBy}`;
    if (forCurrentUser) url = `${url}&forCurrentUser=${forCurrentUser}`;
    if (approvedBy) url = `${url}&approvedBy=${approvedBy}`;
    if (customerId) url = `${url}&customerId=${customerId}`;
    if (serviceId) url = `${url}&serviceId=${serviceId}`;
    if (invoiceNumber) url = `${url}&invoiceNumber=${invoiceNumber}`;
    return await APICall(url, "GET");
  };
  static approve = async (payload) => {
    return await APICall(`/api/Invoice/approve`, "POST", payload);
  };

  static dashList = async (
    from,
    to,
    page = 1,
    pageSize = 5,
    forCurrentUser = true,
    invoiceStatus,
    approvedBy,
    customerId,
    serviceId,
    invoiceNumber
  ) => {
    let url = `/api/Invoice/list/${from}/${to}?page=${page}&pageSize=${pageSize}`;
    if (invoiceStatus) url = `${url}&invoiceStatus=${invoiceStatus}`;
    if (forCurrentUser) url = `${url}&forCurrentUser=${forCurrentUser}`;
    if (approvedBy) url = `${url}&approvedBy=${approvedBy}`;
    if (customerId) url = `${url}&customerId=${customerId}`;
    if (serviceId) url = `${url}&serviceId=${serviceId}`;
    if (invoiceNumber) url = `${url}&invoiceNumber=${invoiceNumber}`;
    return await APICall(url, "GET");
  };
}
