import Transactions from "../pages/invoice/transaction-dashboard";
import ServiceList from "../pages/admin/serviceList";
import NewService from "../pages/admin/newService";
import UsersList from "../pages/admin/usersList";
import BankList from "../pages/admin/bankList";
import AddAccount from "../pages/admin/addAccount";
import AddUser from "../pages/admin/addUser";
import UpdateAccount from "../pages/admin/updateAccount";
import ServiceCategories from "../pages/admin/serviceCategories";
import UserDetail from "../pages/admin/userDetail";
import ServiceDetail from "../pages/admin/serviceDetail";
import Settings from "../pages/admin/settings";
import Reports from "../pages/admin/reports";
import Archive from "../pages/archive";
import CardPayment from "../pages/invoice/CardPayment";

const AdminRoutes = [
  { route: "/adminsample", component: null },
  { route: "/transactions", component: <Transactions /> },
  { route: "/services", component: <ServiceList /> },
  { route: "/add-service", component: <NewService /> },
  { route: "/users", component: <UsersList /> },
  { route: "/accounts", component: <BankList /> },
  { route: "/add-account", component: <AddAccount /> },
  { route: "/add-user", component: <AddUser /> },
  { route: "/account/:accountId", component: <UpdateAccount /> },
  { route: "/service-categories", component: <ServiceCategories /> },
  { route: "/user/:userId", component: <UserDetail /> },
  { route: "/service/:serviceId", component: <ServiceDetail /> },
  { route: "/settings", component: <Settings /> },
  { route: "/reports", component: <Reports /> },
  { route: "/archive", component: <Archive /> },
  { route: "/card-payment", component: <CardPayment /> },
];

export default AdminRoutes;
