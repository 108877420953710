import common from "./common";
const accountant = [
  ...common,
  { label: "Transactions", icon: "fa fa-home", url: "/transactions" },
  { label: "Approve Requisition", icon: "fa fa-check", url: "/approvals" },
  { label: "Raise voucher", icon: "fa fa-check", url: "/card-payment" },
  { label: "Approve voucher", icon: "fa fa-check", url: "/voucher-approval" },
  { label: "Archive", icon: "fa fa-check", url: "/archive" },
  
];
export default accountant;
