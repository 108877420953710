import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import "./reports.scss";
import Spinner from "../../Utils/Spinner";
import Select from "react-select";
import BanksService from "../../services/BanksService";
import UsersService from "../../services/UsersService";
import { CSVLink } from "react-csv";
import ServicesService from "../../services/ServicesService";
import Functions from "../../Utils/Functions";
import { FormatMoney } from "../../Utils/format-currency";
import { DataContext } from "../../Contexts/DataContexts";
import Toastr from "../../Utils/Toastr";


export default class Reports extends Component {
  constructor(props) {
    super(props);
  }

  state = { 
    loading: false,
    taboneBgcolor:"tomato", 
    tabtwoColor: "#8094ae",
    taboneColor: "white",
    tabtwoBgcolor:"#f1f1f1",
    inflow: true,
    banks: [],
    users: [],
    listLoading: false,
    infdataAvailable: false,
    outdataAvailable:false,
    receiptsData: [],
    paymentsData: [],
    reportLoading: false,
    payload:{},
    filterCall: false,
    isAccountant: false,
    user: "",
    servicesAvailable:[],
    emptyTableMessage:"Apply filter to view data",
    
  };

  static contextType = DataContext;

  componentDidMount=async()=>{
    this.setState({loading:true});
    this.listBanks();
    this.listUsers();
    this.listServices();
    this.setState({loading:false});
    //this.verifyUserRole();
    const globalState = this.context.globalState;
    console.log({globalState:globalState})
    if(globalState.user?.role?.toLowerCase() === "accountant"){
      this.setState({isAccountant:true});
      this.setState({user:globalState.user.fullname});
    }
   
  }
  

  listBanks=async()=>{
    this.setState({listLoading:true});
    const banks = await BanksService.listBankAccount();
    console.log({banks:banks});
    if (banks.length > 0){
      banks.forEach(bank => {
        bank.label = bank.bankName + "  -  " + bank.accountNumber;
        bank.value = bank.accountNumber;
        this.setState({banks:banks});
      });
    }
    this.setState({listLoading:false});
  }

  listUsers=async()=>{
    this.setState({listLoading:true});
    const users = await UsersService.listUsers("all");
    console.log({users:users.data});
    if (users.data.length > 0){
      users.data.forEach(user => {
        user.label = user.fullname;
        user.value = user.fullname;
        this.setState({users:users.data});
      });
    }
    this.setState({listLoading:false});
  }

  listServices=async()=>{
    const services = await ServicesService.listServices();
    console.log({services:services});
    if (services.length > 0){
        services.forEach(serv =>{
          serv.label = serv.servicename;
          serv.value = serv.id;
          this.setState({servicesAvailable:services})
        })
    }
  }

  selectInflow=()=>{
    if(this.state.inflow === true){
      return
    }else{
    this.listUsers();
    this.setState({taboneColor: "white"});
    this.setState({taboneBgcolor:"tomato"});
    this.setState({tabtwoColor: "#8094ae"});
    this.setState({tabtwoBgcolor: "#f1f1f1"});
    this.setState({inflow:true});
    }
  }

  selectOutflow=()=>{
    if(this.state.inflow === false){
      return
    }else{
    this.listBanks();
    this.setState({taboneColor: "#8094ae"});
    this.setState({taboneBgcolor:"#f1f1f1"});
    this.setState({tabtwoColor: "white"});
    this.setState({tabtwoBgcolor: "tomato"});
    this.setState({inflow:false});
    }
  }

  updatePayload=(payloadArray)=>{
    const tempPayload = {...this.state.payload};
    payloadArray.forEach((el)=>{
        tempPayload[el.key] = el.data;
    });
    console.log({tempPayload:tempPayload});
    this.setState({payload: tempPayload});
  }

  getInflowReport=async()=>{
    const globalState = this.context.globalState;
    this.setState({filterCall:true});
    let request = null;
    if(this.state.payload.selectedUser){
        request = {
        fields: [],
        reportSource: "inflow_report",
        orderBy: "Date",
        orderDirection: "asc",
        filters:[{fieldName:"NameOfRaiser", value: this.state.payload.selectedUser, compareOperator: "="},
          {fieldName:"Amount", value: "0", compareOperator: ">"},
          {fieldName:"Date", value: this.state.payload.dateFrom, compareOperator: ">"},
          {fieldName:"Date", value: this.state.payload.dateTo, compareOperator: "<"},
          this.state.payload.selectedService?
          {fieldName:"ServiceDescription", value: this.state.payload.selectedService, compareOperator: "="}:
          {fieldName:"Amount", value: "0", compareOperator: ">"}]}
      }else{
        request = {
          fields: [],
          reportSource: "inflow_report",
          orderBy: "Date",
          orderDirection: "asc",
          filters:[
            {fieldName:"Amount", value: "0", compareOperator: ">"},
            {fieldName:"Date", value: this.state.payload.dateFrom, compareOperator: ">"},
            {fieldName:"Date", value: this.state.payload.dateTo, compareOperator: "<"},
            this.state.payload.selectedService?
            {fieldName:"ServiceDescription", value: this.state.payload.selectedService, compareOperator: "="}:
            {fieldName:"Amount", value: "0", compareOperator: ">"},
            this.state.isAccountant?
            {fieldName:"Amount", value: "0", compareOperator: ">"}:
            {fieldName: "NameOfRaiser", value: globalState.user.fullname, compareOperator: "="}
          ]

      }
    }
    const response = await ServicesService.reports("json", request)
    console.log({reports:response});
    if(response.length > 0){
      response.forEach(data =>{
        data.transactionDate = Functions.dateFormReport(data.Date);
      })
      this.setState({infdataAvailable:true});
      this.setState({receiptsData:response});
    }else{
      this.setState({infdataAvailable:false});
      Toastr("info", "No data found matching filter");
      this.setState({emptyTableMessage:"No data found matching your filter parameter"});
    }
    this.setState({filterCall:false});
  }


  getOutflowReport=async()=>{
    const globalState = this.context.globalState;
    console.log({test:this.state.user});
    this.setState({filterCall:true});
    let request = null;
      if(this.state.payload.bank){
        request = {
        fields: [],
        reportSource: "outflow_report",
        orderBy: "DateDisbursed",
        orderDirection: "asc",
        filters:[
          {fieldName:"DisbursementBank", value: this.state.payload.bank, compareOperator: "="},
          {fieldName:"Amount", value: "0", compareOperator: ">"},
          {fieldName:"DateDisbursed", value: this.state.payload.dateFrom, compareOperator: ">"},
          {fieldName:"DateDisbursed", value: this.state.payload.dateTo, compareOperator: "<"}]}
      }else{
        request = {
          fields: [],
          reportSource: "outflow_report",
          orderBy: "DateDisbursed",
          orderDirection: "asc",
          filters:[
            {fieldName:"Amount", value: "0", compareOperator: ">"},
            {fieldName:"DateDisbursed", value: this.state.payload.dateFrom, compareOperator: ">"},
            {fieldName:"DateDisbursed", value: this.state.payload.dateTo, compareOperator: "<"},
            this.state.isAccountant?
            {fieldName:"Amount", value: "0", compareOperator: ">"}:
            {fieldName: "NameOfRaiser", value: globalState.user.fullname, compareOperator: "="}]

      }
    }

    const response2 = await ServicesService.reports("json", request)
    console.log({reports:response2});
    if(response2.length > 0){
      response2.forEach(data =>{
        data.date = Functions.dateFormReport(data.DateDisbursed);
      })
      this.setState({outdataAvailable:true});
      this.setState({paymentsData:response2});
    }else{
      this.setState({outdataAvailable:false});
      Toastr("info", "No data found matching filter");
      this.setState({emptyTableMessage:"No data found matching your filter parameter"});
    }
    this.setState({filterCall:false});
  }

 

  render() {
    return (
      <MainLayout title="Reports">
      {this.state.loading? <Spinner /> :
      <div className="dash-box-report col-md-12">
        <div className="boxed">
          <div className="tab-select">
            <button className="tab-button" onClick={()=>this.selectInflow()} style={{color: this.state.taboneColor, backgroundColor: this.state.taboneBgcolor}}>RECEIPTS</button>
            <button className="tab-button" onClick={()=>this.selectOutflow()} style={{color: this.state.tabtwoColor, backgroundColor: this.state.tabtwoBgcolor}}>PAYMENTS</button>
          </div>
          <div className="table-content-filter">
            {this.state.inflow?
            <div className="filter-sect" style={{backgroundColor:"#f1f1f1"}}>
              {this.state.listLoading? <Spinner />:
            <><div className="row">
                    <div hidden={this.state.isAccountant? false: true} className="col-md-5" style={{height:"fit-content"}}>
                      <Select
                        placeholder="select user"
                        options={this.state.users}
                        onChange={(option) => this.updatePayload([{data:option.value, key: "selectedUser"}])} />
                    </div>
                    <div className="col-md-5" style={{height:"fit-content"}}>
                      <Select
                        placeholder="select service"
                        options={this.state.servicesAvailable}
                        onChange={(option) => this.updatePayload([{data:option.label, key: "selectedService"}])} />
                    </div>
                    <div className="col-md-2">
                      {this.state.filterCall? <Spinner />:
                      <button onClick={() => this.getInflowReport()} className="btn btn-lg filter-btn">Filter</button>}
                    </div>
                  
              </div>
              <div className="row" style={{marginTop:"20px"}}>
                <div className="col-md-10 filter">
                    <div className="filter-content">
                      <p className="fromto-text" style={{ marginBottom: "3px" }}>FROM:</p>
                      <input className="date" type="date"
                      onChange={(e) => this.updatePayload([{data:e.target.value, key: "dateFrom"}])} defaultValue={this.state.payload.dateTo}/>
                    </div>
                    <div className="filter-content" style={{ marginLeft: "20px" }}>
                      <p className="fromto-text" style={{ marginBottom: "3px" }}>TO:</p>
                      <input className="date" type="date" 
                      onChange={(e) => this.updatePayload([{data:e.target.value, key: "dateTo"}])} defaultValue={this.state.payload.dateTo}/>
                    </div>
                </div>
                    {this.state.listLoading? <div />:
                    <div className="col-md-2" style={{height:"fit-content"}}>
                    {this.state.inflow?
                      <CSVLink filename="Receipts" data={this.state.receiptsData}><button disabled={this.state.receiptsData.length === 0? true: false} className="btn btn-secondary btn-lg filter-btn export-btn" style={{backgroundColor:"#364a63"}}>Export</button></CSVLink>:
                      <CSVLink filename="Payments" data={this.state.paymentsData}><button disabled={this.state.paymentsData.length === 0? true: false} className="btn btn-secondary btn-lg filter-btn export-btn" style={{backgroundColor:"#364a63"}}>Export</button></CSVLink>}
                    </div>}
              </div>
            </>}
            </div>:
            <div className="filter-sect" style={{backgroundColor:"#f1f1f1"}}>
            {this.state.listLoading? <Spinner />:
            <>
              <div className="row" hidden={this.state.isAccountant? false: true}>
                  <div className="col-md-5" hidden={this.state.isAccountant? false: true}>
                    <Select
                      options={this.state.banks} 
                      placeholder="Select bank or cash account"
                      onChange={(option) => this.updatePayload([{data:option.value, key: "bank"}])}/>
                  </div>
                  <div className="col-md-2" style={{maxWidth:"100%"}}>
                  {this.state.filterCall? <Spinner />:
                    <button onClick={() => this.getOutflowReport()} className="btn btn-lg filter-btn">Filter</button>}
                  </div>
              </div>
              <div className="row" style={{marginTop:"20px"}}>
                  <div className="col-md-6 filter">
                    <div className="filter-content">
                      <p className="fromto-text" style={{ marginBottom: "3px" }}>FROM:</p>
                      <input className="date" type="date" 
                      onChange={(e) => this.updatePayload([{data:e.target.value, key: "dateFrom"}])} defaultValue={this.state.payload.dateFrom} />
                    </div>
                    <div className="filter-content" style={{ marginLeft: "20px" }}>
                      <p className="fromto-text" style={{ marginBottom: "3px" }}>TO:</p>
                      <input className="date" type="date"
                      onChange={(e) => this.updatePayload([{data:e.target.value, key: "dateTo"}])} defaultValue={this.state.payload.dateTo} />
                    </div>
                  </div>
                  <div hidden={this.state.isAccountant? true:false} className="col-md-2" style={{maxWidth:"100%"}}>
                  {this.state.filterCall? <Spinner />:
                    <button onClick={() => this.getOutflowReport()} className="btn btn-lg filter-btn">Filter</button>}
                  </div>
                  {this.state.listLoading? <div />:
                    <div className="col-md-2" style={{height:"fit-content", maxWidth:"100%"}}>
                    {this.state.inflow?
                      <CSVLink filename="Receipts" data={this.state.receiptsData}><button disabled={this.state.receiptsData.length === 0? true: false} className="btn btn-secondary btn-lg filter-btn export-btn" style={{backgroundColor:"#364a63"}}>Export</button></CSVLink>:
                      <CSVLink filename="Payments" data={this.state.paymentsData}><button disabled={this.state.paymentsData.length === 0? true: false} className="btn btn-secondary btn-lg filter-btn export-btn" style={{backgroundColor:"#364a63"}}>Export</button></CSVLink>}
                    </div>}

              </div></>}
            </div>}
          </div>
          <div className="table-display">
            <div>
              {this.state.inflow?
              <div className="display-box">
                {this.state.infdataAvailable?
                <div>
                  <div className="row table-head">
                  <div className="col-md-2">Date</div>
                  <div className="col-md-2">Raiser</div>
                  <div className="col-md-2">Customer Name</div>
                  <div className="col-md-3">Description</div>
                  <div className="col-md-2">Amount</div>
                  <div className="col-md-1">Status</div>
                </div>
                {this.state.receiptsData.map((list, i) => (
                <div key={i} className="row table-list">
                  <div className="col-md-2">{Functions.dateForm(list.Date)}</div>
                  <div className="col-md-2">{list.NameOfRaiser}</div>
                  <div className="col-md-2">{list.CustomerName}</div>
                  <div className="col-md-3">{list.ServiceDescription}</div>
                  <div className="col-md-2">{FormatMoney(list.GrandTotal, list.Currency)}</div>
                  <div className="col-md-1">
                    {list.PaymentStatus === 1 && (<span>Paid</span>)}
                    {list.PaymentStatus === 0 && (<span>Not paid</span>)}
                  </div>
                </div>))}
                </div>: <div>{this.state.listLoading? <p></p>:<div className="no-data">{this.state.emptyTableMessage}</div>}</div>
                }
              </div>:
              <div>
                {this.state.outdataAvailable?
                <div>
                  <div className="row table-head">
                  <div className="col-md-1">Date</div>
                  <div className="col-md-2">Raiser</div>
                  <div className="col-md-2">Beneficiary</div>
                  <div className="col-md-4">Description</div>
                  <div className="col-md-2">Amount</div>
                  <div className="col-md-1">Status</div>
                </div>
                {this.state.paymentsData.map((list, i) => (
                <div key={i} className="row table-list">
                  <div className="col-md-1">{Functions.dateForm(list.DateDisbursed)}</div>
                  <div className="col-md-2">{list.NameOfRaiser}</div>
                  <div className="col-md-2">{list.Beneficiary}</div>
                  <div className="col-md-4">{list.Description}</div>
                  <div className="col-md-2">{FormatMoney(list.Amount, list.Currency)}</div>
                  <div className="col-md-1">
                    {list.PaymentStatus === 1 && (<span>Valid</span>)}
                    {list.PaymentStatus === 0 && (<span>Invalid</span>)}
                  </div>
                </div>))}
                </div> :<div>{this.state.listLoading? <p></p>:<div className="no-data">{this.state.emptyTableMessage}</div>}</div>}
                
              </div>}
            </div>
          </div>

          {/* {this.state.listLoading? <div />:
          <div>
            {this.state.inflow?
            <CSVLink filename="Receipts" data={this.state.receiptsData}><button disabled={this.state.receiptsData.length === 0? true: false} className="btn btn-secondary btn-lg filter-btn export-btn" style={{backgroundColor:"#364a63"}}>Export</button></CSVLink>:
            <CSVLink filename="Payments" data={this.state.paymentsData}><button disabled={this.state.paymentsData.length === 0? true: false} className="btn btn-secondary btn-lg filter-btn export-btn" style={{backgroundColor:"#364a63"}}>Export</button></CSVLink>}
          </div>} */}

        </div>
      </div>}
      </MainLayout>
    );
  }
}
