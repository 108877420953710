import React, { Component, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import VoucherService from "../../services/VoucherService";
import { DataContext } from "../../Contexts/DataContexts";
import "../../sass/others.css";
import ServicesService from "../../services/ServicesService";
import UsersService from "../../services/UsersService";
import Spinner from "../../Utils/Spinner";
import CustomerService from "../../services/CustomerService";
import Functions from "../../Utils/Functions";
import { FormatMoney } from "../../Utils/format-currency";

export default class Vouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: false,
      tableLoading: false,
      vouchers: [],
      services: [],
      approvals: [],
      admins: [],
      filterData: {
        dateFrom: sessionStorage.getItem("dateFrom") || "",
        dateTo: sessionStorage.getItem("dateTo") || "",
        voucherStatus: sessionStorage.getItem("voucherStatus") || "all",
        voucherCode: sessionStorage.getItem("voucherCode") || "",
        voucherSearchText: sessionStorage.getItem("voucherSearchText") || "",
        createdBy: 0,
        approvedBy: 0,
        customerId: 0,
        customerName: "",
        serviceId: 0,
        forCurrentUser: true,
        createdName: "",
      },
      customers: [],
    };
  }

  statusThemes = {
    raised: "primary",
    responded: "warning",
    returned_by_accountant: "danger",
    approved_by_accountant: "info",
    returned_at_approval: "danger",
    first_approval: "info",
    true: "danger",
    first_and_final_approval: "success",
    second_approval: "success",
    paid: "light",
  };

  actionThemes = {
    owner_review: "warning",
    accountant_review: "warning",
    first_approval: "info",
    second_approval: "info",
    final_processing: "info",
  };

  voucherStatuses = [
    { name: "Raised", value: "raised" },
    { name: "Owner responded to query", value: "responded" },
    { name: "Returned by accountant", value: "returned_by_accountant" },
    { name: "Approved by accountant", value: "approved_by_accountant" },
    { name: "First approval", value: "first_approval" },
    { name: "First and final approval", value: "first_and_final_approval" },
    { name: "Second approval", value: "second_approval" },
    { name: "Disbursed / Paid", value: "paid" },
  ];

  static contextType = DataContext;
  initComplete = false;

  listVouchers = async (filterData = null, page) => {
    const globalState = this.context.globalState;
    let currentUser = true;
    let role = globalState.user?.role?.toLowerCase();
    const globalViewRoles = [
      "admin",
      "accountant",
      "firstlevelauthorizer",
      "secondlevelauthorizer",
    ];

    if (globalViewRoles.includes(role?.toLowerCase())) currentUser = false;

    this.setState({ tableLoading: true });

    let today = new Date();
    let aYearAgo = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);

    const data = filterData ? { ...filterData } : { ...this.state.filterData };

    if (!data.dateFrom) data.dateFrom = aYearAgo.toISOString();
    if (!data.dateTo) data.dateTo = today.toISOString();
    data.dateFrom = Functions.sqlDateString(data.dateFrom);
    data.dateTo = Functions.sqlDateString(data.dateTo);

    if (page && page < 1) page = 1;
    if (page) this.setState({ page: page });

    if (this.props.approvalsOnly) {
      if (role?.toLowerCase() == "accountant") {
        data.nextAction = "accountant_review";
      } else if (role?.toLowerCase() == "firstlevelauthorizer") {
        data.nextAction = "first_approval";
      } else if (role?.toLowerCase() == "secondlevelauthorizer") {
        data.nextAction = "second_approval";
      }
    }

    if(this.props.vouchersOnly){
      data.voucherStatus = "raised|responded";
      data.voucherType = "voucher"
    }

    let vouchers = await VoucherService.listVoucher(
      data.dateFrom,
      data.dateTo,
      page || this.state.page,
      50,
      data.nextAction,
      currentUser,
      data.voucherCode,
      data.voucherSearchText,
      data.voucherStatus,
      data.voucherType
    );

    if (vouchers && vouchers.data)
      this.setState({ vouchers: vouchers.data, filterData: data }, () => {
        //console.log("New voucher data: ", this.state.vouchers);
      });

    this.setState({ tableLoading: false });
    //console.log({vouhers:vouchers});
  };

  listServices = async () => {
    this.setState({ loading: true });
    let services = await ServicesService.listServices();
    if (services) this.setState({ services: services });
    this.setState({ loading: false });
  };

  listAllApproval = async () => {
    this.setState({ loading: true });
    let approvals = [];
    let admindata = [];
    let accountantData = [];
    let admins = await UsersService.listUsers("admin");
    let accountant = await UsersService.listUsers("accountant");
    if (admins) admindata = admins.data;
    if (accountant) accountantData = accountant.data;
    approvals = accountantData.concat(admindata);
    // console.log(approvals);
    this.setState({ approvals: approvals, loading: false });
  };

  searchCustomers = async (search) => {
    this.setState({ loading: true });
    const data = { ...this.state.filterData };
    data.customerId = 0;
    this.setState({ filterData: data });
    if (search.length >= 3) {
      var customers = await CustomerService.search(search);
      if (customers) {
        // console.log(customers);
        this.setState({ customers: customers });
      }
    } else {
      this.setState({ customers: [] });
    }
    this.setState({ loading: false });
  };

  searchAdmin = async (search) => {
    this.setState({ loading: true });
    const data = { ...this.state.filterData };
    data.createdBy = 0;
    this.setState({ filterData: data });
    if (search.length >= 3) {
      var admins = await UsersService.search(search, "all");
      if (admins && admins.data.length > 0) {
        this.setState({ admins: admins.data });
      }
    } else {
      this.setState({ admins: [] });
    }
    this.setState({ loading: false });
  };

  componentDidMount = async () => {
    // await this.resetData();
    await this.listVouchers();
    await this.listServices();
    await this.listAllApproval();

  };

  // componentWillUnmount=()=>{
  //     if(this.props.approvalsOnly){
  //         this.clearSessionStorage();
  //     }
  // }

  handleChange = ({ currentTarget: input }) => {
    if (input.name === "dateFrom")
      sessionStorage.setItem("dateFrom", input.value);
    if (input.name === "dateTo") sessionStorage.setItem("dateTo", input.value);
    if (input.name === "voucherStatus") {
      sessionStorage.setItem("voucherStatus", input.value);
    }
    if (input.name === "voucherSearchText") {
      sessionStorage.setItem("voucherSearchText", input.value);
    }

    const data = { ...this.state.filterData };
    data[input.name] = input.value;
    this.setState({ filterData: data });
  };

  assignCustomer = async (customer) => {
    let customerName = customer.firstName + " " + customer.lastName;
    const data = { ...this.state.filterData };
    data.customerName = customerName;
    data.customerId = customer.id;
    this.setState({ filterData: data, customers: [] });
  };

  assignAdmin = async (admin) => {
    const data = { ...this.state.filterData };
    data.createdName = admin.fullname;
    data.createdBy = admin.id;
    this.setState({ filterData: data, admins: [] });
  };

  convertDate(date) {
    return new Date(date).toDateString();
  }

  convertDatetoReadable(date) {
    if (date) {
      // console.log(date);
      return new Date(date).toISOString().split("T")[0];
    }
    return "";
  }

  clearSessionStorage = () => {
    sessionStorage.removeItem("voucherStatus", "all");
    sessionStorage.removeItem("voucherSearchText", "");
    sessionStorage.removeItem("dateTo", "");
    sessionStorage.removeItem("dateFrom", "");
    window.location.reload();
  };

  resetData = async () => {
    let filterData = {
      dateFrom: "",
      dateTo: "",
      voucherStatus: "all",
      createdBy: 0,
      approvedBy: 0,
      customerId: 0,
      customerName: "",
      serviceId: 0,
      voucherCode: "",
      forCurrentUser: true,
      createdName: "",
      voucherSearchText: "",
    };

    this.setState({ filterData: filterData });
    await this.listVouchers(filterData);

    this.clearSessionStorage();
  };

  filterData = async (e) => {
    e.preventDefault();
    await this.listVouchers();
  };

  render() {
    return (
      <MainLayout
        title={
          this.props.approvalsOnly ?  "Approvals – Requisitions" :  
          this.props.vouchersOnly? "Approvals - Vouchers" :
          "Payment requests"
        }
      >
        <div className="col">
          <div className="nk-content px-0">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head">
                    <div className="nk-block-between g-3">
                      <div className="nk-block-head-content">
                        <div className="nk-block-des text-soft">
                          <p>
                            Showing {this.state.vouchers?.length || 0} vouchers
                          </p>
                        </div>
                      </div>
                      {/* .nk-block-head-content */}
                      <div className="nk-block-head-content">
                        <ul className="nk-block-tools g-3">
                          {this.props.approvalsOnly || this.props.vouchersOnly ||(
                            <li>
                              <Link
                                className="btn btn-primary btn-md"
                                to="/raise-voucher"
                              >
                                Raise a request
                              </Link>
                            </li>
                          )}
                          {/* <li><Link to="#" className="btn btn-white btn-dim btn-outline-light"><em className="icon ni ni-download-cloud" /><span>Export</span></Link></li> */}
                        </ul>
                      </div>
                      {/* .nk-block-head-content */}
                    </div>
                    {/* .nk-block-between */}
                  </div>
                  {/* .nk-block-head */}
                  <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="row card-title-group">
                            <div className="col mb-3">
                              <div className="All-filter row">
                                <div className="col-lg-4">
                                  <div className="form-control-wrap">
                                    <label
                                      style={
                                        this.props.approvalsOnly || this.props.vouchersOnly
                                          ? { marginBottom: 0 }
                                          : {}
                                      }
                                      className="form-label trans"
                                      htmlFor="voucherCode"
                                    >
                                      top
                                    </label>
                                    {/* <input type="search" value={this.state.filterData.voucherCode} onChange={this.handleChange} className="form-control form-control-md " placeholder="Search" id="voucherCode" name="voucherCode" /> */}
                                    {/* <input defaultValue={this.state.filterData.voucherCode} type="search" onChange={this.handleChange} className="form-control form-control-md " placeholder="Search" id="voucherCode" name="voucherCode" /> */}
                                    <input
                                      defaultValue={
                                        this.state.filterData.voucherSearchText
                                      }
                                      type="search"
                                      onChange={this.handleChange}
                                      className="form-control form-control-md "
                                      placeholder="Search"
                                      id="voucherCode"
                                      name="voucherSearchText"
                                    />
                                  </div>
                                </div>
                                {this.props.approvalsOnly || this.props.vouchersOnly ||(
                                  <div className="card-tools col-lg-4">
                                    <label
                                      className="form-label trans"
                                      htmlFor="voucherStatus"
                                    >
                                      top
                                    </label>
                                    <select
                                      onChange={this.handleChange}
                                      className="form-control form-control-md"
                                      id="voucherStatus"
                                      name="voucherStatus"
                                      defaultValue={
                                        this.state.filterData.voucherStatus
                                      }
                                    >
                                      {/* <option>Filter by Status</option> */}
                                      <option value="all">
                                        Filter by Status
                                      </option>
                                      {this.voucherStatuses.map(
                                        (status, index) => (
                                          <option
                                            key={index}
                                            value={status.value}
                                          >
                                            {status.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                )}

                                <div className="card-tools col-lg-8">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <div className="form-control-wrap mt-4">
                                          <span className="form-span mr-1 mt-1">
                                            From
                                          </span>
                                          <input
                                            onChange={this.handleChange}
                                            type="date"
                                            className="form-control  form-control-md"
                                            id="dateFrom"
                                            name="dateFrom"
                                            value={this.convertDatetoReadable(
                                              this.state.filterData.dateFrom
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <div className="form-control-wrap mt-4">
                                          <span className="form-span mr-1 mt-1">
                                            To
                                          </span>
                                          <input
                                            type="date"
                                            onChange={this.handleChange}
                                            className="form-control  form-control-md"
                                            id="dateTo"
                                            name="dateTo"
                                            value={this.convertDatetoReadable(
                                              this.state.filterData.dateTo
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-4 ">
                                  <div className="row mt-4 ">
                                    <div className="col">
                                      <div className="form-group">
                                        {this.state.loading ? (
                                          <Spinner />
                                        ) : (
                                          <>
                                            <button
                                              type="button"
                                              onClick={async (e) =>
                                                await this.filterData(e)
                                              }
                                              className="btn btn-secondary btn-sm mr-3"
                                            >
                                              Filter vouchers
                                            </button>
                                            <button
                                              onClick={async (e) =>
                                                await this.resetData()
                                              }
                                              type="button"
                                              className="btn btn-link btn-md"
                                            >
                                              Reset filter
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* .btn-toolbar */}
                            </div>
                          </div>
                          {/* .card-title-group */}
                        </div>
                        {/* .card-inner */}
                        <div className="card-inner p-0">
                          <table className="table table-tranx">
                            <thead>
                              <tr className="tb-tnx-head">
                                <th className="tb-tnx-id">
                                  <span className="">Voucher Type</span>
                                </th>
                                <th className="tb-tnx-info">
                                  <span className="tb-tnx-desc d-none d-sm-inline-block">
                                    <span>Raised by</span>
                                  </span>
                                  <span className="tb-tnx-date d-md-inline-block d-none">
                                    <span className="d-none d-md-block">
                                      <span>Date Raised</span>
                                      <span>Status</span>
                                    </span>
                                  </span>
                                </th>
                                <th className="tb-tnx-amount is-alt">
                                  <span className="tb-tnx-total">Amount</span>
                                  <span className="tb-tnx-status d-none d-md-inline-block">
                                    Payment Status
                                  </span>
                                </th>
                                <th className="tb-tnx-action">
                                  <span>&nbsp;</span>
                                </th>
                              </tr>
                              {/* tb-tnx-item */}
                            </thead>
                            <tbody>
                              {this.state.tableLoading && (
                                <tr>
                                  <td>
                                    <Spinner />
                                  </td>
                                </tr>
                              )}
                              {!this.state.tableLoading &&
                                (!this.state.vouchers ||
                                  this.state.vouchers.length <= 0) && (
                                  <tr>
                                    <td colSpan="4">
                                      <p
                                        className="text-muted text-center"
                                        style={{
                                          margin: "100px auto",
                                          maxWidth: "500px",
                                        }}
                                      >
                                        {" "}
                                        <strong>Empty result set</strong>
                                        <br />
                                        No voucher data matches your filter
                                        parameters{" "}
                                      </p>
                                    </td>
                                  </tr>
                                )}
                              {/* {this.state.vouchers.map((voucher, index) => ( */}

                              {this.state.vouchers
                                .sort((a, b) => {
                                  {
                                    /* console.log(this.state.vouchers); */
                                  }
                                  {
                                    /* console.log(typeof(new Date().toISOString())); */
                                  }
                                  return (
                                    new Date(b.dateModified) -
                                    new Date(a.dateModified)
                                  );
                                })
                                .map((voucher, index) => (
                                  <tr key={index} className="tb-tnx-item">
                                    <td className="tb-tnx-id">
                                      <span
                                        style={{
                                        fontSize:"12px",
                                        color:voucher.voucherType === "requisition"? "#526484" : "tomato"
                                        }}>
                                        {voucher.voucherType.toUpperCase()}
                                      </span>
                                    </td>
                                    <td className="tb-tnx-info">
                                      <div className="tb-tnx-desc">
                                        <span className="title">
                                          {voucher.raisedBy}{" "}
                                        </span>
                                      </div>
                                      <div className="tb-tnx-date">
                                        <span className="date">
                                          {this.convertDate(
                                            voucher.dateCreated
                                          )}
                                        </span>
                                        {voucher.voided == false ? (
                                          <span
                                            className={`badge badge-dot badge-${
                                              this.statusThemes[voucher.status]
                                            }`}
                                          >
                                            {voucher.status?.toUpperCase()}
                                          </span>
                                        ) : (
                                          <span
                                            className={`badge badge-dot badge-${
                                              this.statusThemes[voucher.voided]
                                            }`}
                                          >
                                            VOIDED
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                    <td className="tb-tnx-amount is-alt">
                                      <div className="tb-tnx-total">
                                        <span className="amount">
                                          {FormatMoney(
                                            voucher.amount,
                                            voucher.currency
                                          )}
                                        </span>
                                      </div>
                                      <div className="tb-tnx-status">
                                        {voucher.status == "paid" ? (
                                          <span className="badge badge-dot badge-success">
                                            Paid
                                          </span>
                                        ) : (
                                          <span className="badge badge-dot badge-danger">
                                            Not Paid
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                    <td className="tb-tnx-action">
                                      <div className="link-list-plain">
                                        <Link
                                          className="view-but"
                                          to={"/voucher/" + voucher.voucherCode}
                                        >
                                          {" "}
                                          View
                                          <img
                                            type="button"
                                            className="img-arrow"
                                            src="/images/arroww.png"
                                          />
                                        </Link>
                                      </div>
                                      {/* <div className="dropdown">
                                                                        <a className="text-soft dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h" /></a>
                                                                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                                            <ul className="link-list-plain">
                                                                                <li><Link to={"/voucher/" + voucher.voucherCode}> View</Link></li>

                                                                            </ul>
                                                                        </div>
                                                                    </div> */}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {/* .card-inner */}
                        <div className="card-inner">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            <li className="page-item">
                              <a
                                className="page-link"
                                onClick={async () =>
                                  await this.listVouchers(
                                    null,
                                    this.state.page - 1
                                  )
                                }
                              >
                                Prev
                              </a>
                            </li>
                            <li className="page-item">
                              <a
                                className="page-link"
                                onClick={async () =>
                                  await this.listVouchers(
                                    null,
                                    this.state.page + 1
                                  )
                                }
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                          {/* .pagination */}
                        </div>
                        {/* .card-inner */}
                      </div>
                      {/* .card-inner-group */}
                    </div>
                    {/* .card */}
                  </div>
                  {/* .nk-block */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
