import APICall from "../Utils/APICall";

export default class PaymentService {
  static status = async (reference) => {
    return await APICall(`/Payment/status/${reference}`, "GET");
  };
  static invoicePayment = async (invoiceNumber) => {
    return await APICall(
      `/Payment/invoice?invoiceNumber=${invoiceNumber}`,
      "GET"
    );
  };
  static callbacks = async () => {
    return await APICall("/Payment/callback/payment", "GET");
  };
}
