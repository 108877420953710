import React, { Component } from "react";
import { Link } from "react-router-dom";
import Constants from "../Utils/Constants";
import Menus from "../menus/index";
import Functions from "../Utils/Functions";
import Spinner from "../Utils/Spinner";
import { DataContext } from "../Contexts/DataContexts";
import Logo from "../Logo";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      iscompact: false,
    };
  }

  static contextType = DataContext;

  componentDidMount() {
    const globalState = this.context.globalState;
    // console.log(globalState.user?.role?.toLowerCase());
    const userMenus = Menus[globalState.user?.role?.toLowerCase()] || [];
    this.setState({ menus: userMenus });
  }

  toggleCompact = () => {
    this.setState({ iscompact: !this.state.iscompact });
  };

  render() {
    return (
      <div
        className={`nk-sidebar nk-sidebar-fixed is-dark ${
          this.state.iscompact && "is-compact"
        }`}
        data-content="sidebarMenu"
        style={{ borderRight: 0 }}
      >
        <div className="nk-sidebar-element nk-sidebar-head bg-white">
          <div className="nk-menu-trigger">
            <a
              href="#"
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-arrow-left" />
            </a>
            <a
              href="#"
              className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
              data-target="sidebarMenu"
              onClick={() => this.toggleCompact()}
            >
              <em className="icon ni ni-menu" />
            </a>
          </div>
          <div className="nk-sidebar-brand">
            <Link to="/" className="logo-link nk-sidebar-logo">
              <Logo className="logo-light logo-img" />
              <Logo className="logo-dark logo-img" reverse icon />
            </Link>
          </div>
        </div>
        {/* .nk-sidebar-element */}
        <div className="nk-sidebar-element nk-sidebar-body">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                <li className="nk-menu-heading">
                  <h6 className="overline-title text-primary-alt">MENU</h6>
                </li>
                {/* .nk-menu-item */}
                {this.state.menus.map((menu, index) => (
                  <li key={index} className="nk-menu-item">
                    <Link to={menu.url} className="nk-menu-link" key={index}>
                      <span className="nk-menu-icon">
                        <em className={menu.icon} />
                      </span>
                      <span className="nk-menu-text">{menu.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
              {/* .nk-menu */}
            </div>
            {/* .nk-sidebar-menu */}
          </div>
          {/* .nk-sidebar-content */}
        </div>
        {/* .nk-sidebar-element */}
      </div>
    );
  }
}
