import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import InvoiceService from "../../services/InvoiceService";
import { DataContext } from "../../Contexts/DataContexts";
import "../../sass/others.css";
import ServicesService from "../../services/ServicesService";
import UsersService from "../../services/UsersService";
import Spinner from "../../Utils/Spinner";
import CustomerService from "../../services/CustomerService";
import Functions from "../../Utils/Functions";
import Select from "react-select";
import { FormatMoney } from "../../Utils/format-currency";
export default class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceStatus: [
        { name: "All", value: "all" },
        { name: "Approved", value: "approved" },
        { name: "Awaiting Approval", value: "awaiting_approval" },
        { name: "Returned", value: "returned" },
        { name: "Cancelled", value: "cancelled" },
      ],
      page: 1,
      loading: false,
      tableLoading: false,
      invoices: [],
      services: [],
      approvals: [],
      admins: [],
      count: 0,
      filterData: {
        dateFrom: "",
        dateTo: "",
        invoiceStatus: "all",
        createdBy: 0,
        approvedBy: 0,
        customerId: 0,
        serviceId: 0,
        customerName: "",
        invoiceNumber: "",
        forCurrentUser: true,
        createdName: "",
      },
      customers: [],
      usersList:[],
    };
  }
  static contextType = DataContext;
  listInvoices = async (filterData = null, page) => {
    const globalState = this.context.globalState;
    let currentUser = true;
    let role = globalState.user?.role?.toLowerCase();

    const globalViewRoles = [
      "admin",
      "accountant",
      "firstlevelauthorizer",
      "secondlevelauthorizer",
    ];
    if (globalViewRoles.includes(role?.toLowerCase())) currentUser = false;

    this.setState({ tableLoading: true });
    let today = new Date();
    let aYearAgo = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
    const data = filterData ? { ...filterData } : { ...this.state.filterData };
    if (!data.dateFrom) data.dateFrom = Functions.formatDate2(aYearAgo);
    if (!data.dateTo) data.dateTo = Functions.formatDate2(today);

    if (page && page < 1) page = 1;
    if (page) this.setState({ page: page });
    console.log({date:data.dateFrom});
    let invoices = await InvoiceService.list(
      data.dateFrom,
      data.dateTo,
      this.state.page,
      50,
      data.invoiceStatus,
      data.createdBy,
      currentUser,
      data.approvedBy,
      data.customerId,
      data.serviceId,
      data.invoiceNumber
    );
    if (invoices && invoices.count > 0)
      this.setState({
        invoices: invoices.data,
        filterData: data,
        count: invoices.count,
      });

    this.setState({ tableLoading: false });
    console.log({invoices:invoices})
  };
  listServices = async () => {
    this.setState({ loading: true });
    let services = await ServicesService.listServices();
    if (services) this.setState({ services: services });
    this.setState({ loading: false });
  };
  listAllApproval = async () => {
    this.setState({ loading: true });
    let approvals = [];
    let admindata = [];
    let accountantData = [];
    let admins = await UsersService.listUsers("admin");
    let accountant = await UsersService.listUsers("accountant");
    if (admins) admindata = admins.data;
    if (accountant) accountantData = accountant.data;
    approvals = accountantData.concat(admindata);
    // console.log(approvals);
    this.setState({ approvals: approvals, loading: false });
  };
  searchCustomers = async (search) => {
    this.setState({ loading: true });
    const data = { ...this.state.filterData };
    data.customerId = 0;
    this.setState({ filterData: data });
    if (search.length >= 3) {
      var customers = await CustomerService.search(search);
      if (customers) {
        // console.log(customers);
        this.setState({ customers: customers });
      }
    } else {
      this.setState({ customers: [] });
    }
    this.setState({ loading: false });
  };

  previous = async (filterData = null, page) => {
    if (this.state.page == 1) return;
    let previousPage = this.state.page - 1;
    // const invoice = (await InvoiceService.list(previousPage, 10)) || [];
    const invoice = this.listInvoices(filterData, previousPage);
    this.setState({ invoice: invoice });
    this.setState({ page: previousPage });
  };

  next = async (filterData = null) => {
    if (this.state.page == 1);
    let nextPage = this.state.page + 1;
    // const invoice = (await InvoiceService.list(previousPage, 10)) || [];
    const invoice = this.listInvoices((filterData = null), nextPage);
    if (!invoice || invoice.length == 0) return;
    // console.log(nextPage);
    this.setState({ invoice: invoice });
    this.setState({ page: nextPage });
  };

  searchAdmin = async (search) => {
    this.setState({ loading: true });
    const data = { ...this.state.filterData };
    data.createdBy = 0;
    this.setState({ filterData: data });
    if (search.length >= 3) {
      var admins = await UsersService.search(search, "all");
      if (admins && admins.data.length > 0) {
        this.setState({ admins: admins.data });
      }
    } else {
      this.setState({ admins: [] });
    }
    this.setState({ loading: false });
  };
  listUsers=async()=>{
    const users = await UsersService.listUsers("all");
    console.log({users:users.data});
    if (users.data.length > 0){
      users.data.forEach(user => {
        user.label = user.fullname;
        user.value = user.id;
        this.setState({usersList:users.data});
      });
    }
  }
  componentDidMount = async () => {
    await this.listInvoices();
    await this.listServices();
    await this.listAllApproval();
    this.listUsers();
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.filterData };
    data[input.name] = input.value;
    this.setState({ filterData: data });
    console.log({data:data});
  };

  assignCustomer = async (customer) => {
    let customerName = customer.firstName + " " + customer.lastName;
    const data = { ...this.state.filterData };
    data.customerName = customerName;
    data.customerId = customer.id;
    this.setState({ filterData: data, customers: [] });
  };
  assignAdmin = async (admin) => {
    const data = { ...this.state.filterData };
    data.createdName = admin.fullname;
    data.createdBy = admin.id;
    this.setState({ filterData: data, admins: [] });
  };
  convertDate(date) {
    return new Date(date).toDateString();
  }
  convertDatetoReadable(date) {
    if (date) {
      // console.log(date);
      return new Date(date).toISOString().split("T")[0];
    }
    return "";
  }
  resetData = async () => {
    let filterData = {
      dateFrom: "",
      dateTo: "",
      invoiceStatus: "all",
      createdBy: 0,
      approvedBy: 0,
      customerId: 0,
      customerName: "",
      serviceId: 0,
      invoiceNumber: "",
      forCurrentUser: true,
      createdName: "",
    };
    this.setState({ filterData: filterData });
    await this.listInvoices(filterData);
  };
  filterData = async (e) => {
    e.preventDefault();
    await this.listInvoices();
  };

  render() {
    return (
      <MainLayout title="Invoices">
        <div className="col">
          <div className="nk-content "style={{padding:"0px"}}>
            <div className="container-fluid" style={{padding:"0px"}}>
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head">
                    <div className="nk-block-between g-3">
                      <div className="nk-block-head-content">
                        <div className="nk-block-des text-soft">
                          <p>You have total {this.state.count} invoices.</p>
                        </div>
                      </div>
                      {/* .nk-block-head-content */}
                      <div className="nk-block-head-content">
                        <ul className="nk-block-tools g-3">
                          <li>
                            <Link
                              className="btn btn-primary btn-md"
                              to="/raise-invoice"
                            >
                              Raise Invoice
                            </Link>
                          </li>
                          <li>
                            {/* <Link
                              to="#"
                              className="btn btn-white btn-dim btn-outline-light"
                            >
                              <em className="icon ni ni-download-cloud" />
                              <span>Export</span>
                            </Link> */}
                          </li>
                        </ul>
                      </div>
                      {/* .nk-block-head-content */}
                    </div>
                    {/* .nk-block-between */}
                  </div>
                  {/* .nk-block-head */}
                  <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="row card-title-group">
                            <div className="col-lg-12 card-title dropdown-head">
                              <h5 className="title">All Invoices</h5>
                            </div>
                            <div className="All-filter row">
                              <div className="col-lg-4">
                                <div className="form-control-wrap">
                                  <label
                                    className="form-label trans"
                                    htmlFor="invoiceNumber"
                                  >
                                    top
                                  </label>
                                  <input
                                    type="search"
                                    value={this.state.filterData.invoiceNumber}
                                    onChange={this.handleChange}
                                    className="form-control form-control-md "
                                    placeholder="Invoice Number"
                                    id="invoiceNumber"
                                    name="invoiceNumber"
                                  />
                                </div>
                              </div>
                              <div className="card-tools col-lg-4">
                                <label
                                  className="form-label trans"
                                  htmlFor="invoiceStatus"
                                >
                                  top
                                </label>
                                <select
                                  onChange={this.handleChange}
                                  className="form-control form-control-md"
                                  id="invoiceStatus"
                                  name="invoiceStatus"
                                >
                                  <option>Filter by Status</option>
                                  {this.state.invoiceStatus.map(
                                    (status, index) => (
                                      <option key={index} value={status.value}>
                                        {status.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              <div className="card-tools col-lg-4">
                                <label
                                  className="form-label trans"
                                  htmlFor="serviceId"
                                >
                                  top
                                </label>
                                <select
                                  onChange={this.handleChange}
                                  className="form-control form-control-md"
                                  id="serviceId"
                                  name="serviceId"
                                >
                                  <option>Filter by Services</option>
                                  {this.state.services.map((service, index) => (
                                    <option key={index} value={service.id}>
                                      {service.servicename}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="card-tools col-lg-8">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <div className="form-control-wrap mt-4">
                                        <span className="form-span mr-1 mt-1">
                                          From
                                        </span>
                                        <input
                                          onChange={this.handleChange}
                                          type="date"
                                          className="form-control  form-control-md"
                                          id="dateFrom"
                                          name="dateFrom"
                                          value={this.convertDatetoReadable(
                                            this.state.filterData.dateFrom
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <div className="form-control-wrap mt-4">
                                        <span className="form-span mr-1 mt-1">
                                          To
                                        </span>
                                        <input
                                          type="date"
                                          onChange={this.handleChange}
                                          className="form-control  form-control-md"
                                          id="dateTo"
                                          name="dateTo"
                                          value={this.convertDatetoReadable(
                                            this.state.filterData.dateTo
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 card-tools -mt-2">
                                <div className="form-control-wrap">
                                  <label
                                    className="form-label trans"
                                    htmlFor="customerName"
                                  >
                                    top
                                  </label>
                                  <input
                                    onChange={this.handleChange}
                                    onKeyUp={async (e) =>
                                      await this.searchCustomers(e.target.value)
                                    }
                                    value={this.state.filterData.customerName}
                                    type="text"
                                    className="form-control form-control-md "
                                    placeholder="Search Customer"
                                    id="customerName"
                                    name="customerName"
                                  />
                                  <div className="customer-drop-down">
                                    <ul>
                                      {this.state.customers.map(
                                        (customer, index) => (
                                          <li
                                            key={index}
                                            onClick={async (e) =>
                                              await this.assignCustomer(
                                                customer
                                              )
                                            }
                                          >
                                            <span className="customer-name">
                                              {customer.firstName}{" "}
                                              {customer.lastName}
                                            </span>
                                            <span className="customer-email">
                                              {customer.emailAddress}
                                            </span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="card-tools col-lg-4">
                                <div className="form-control-wrap">
                                  
                                  <label
                                    className="form-label trans"
                                    htmlFor="trans"
                                  >
                                    top
                                  </label>
                                  <select
                                  onChange={this.handleChange}
                                  className="form-control form-control-md"
                                  id="createdBy"
                                  name="createdBy"
                                >
                                  <option>Filter by Initiator</option>
                                  {this.state.usersList.map(
                                    (user, index) => (
                                      <option key={index} value={user.id}>
                                        {user.fullname}
                                      </option>
                                    )
                                  )}
                                </select>
                                  {/* <div>
                                    <Select
                                    placeholder="Select initiator"
                                    options={this.state.usersList}
                                    onChange={(option)=>this.handleChange(option)}>

                                    </Select>
                                  </div> */}
                                  
                                  {/* <input
                                    onChange={this.handleChange}
                                    onKeyUp={async (e) =>
                                      await this.searchAdmin(e.target.value)
                                    }
                                    value={this.state.filterData.createdName}
                                    type="text"
                                    className="form-control form-control-md "
                                    placeholder="Search Initiator"
                                    id="createdName"
                                    name="createdName"
                                  />
                                  <div className="customer-drop-down">
                                    <ul>
                                      {this.state.admins.map((admin, index) => (
                                        <li
                                          key={index}
                                          onClick={async (e) =>
                                            await this.assignAdmin(admin)
                                          }
                                        >
                                          <span className="customer-name">
                                            {admin.fullname}
                                          </span>
                                          <span className="customer-email">
                                            {admin.email}
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  </div> */}
                                </div>
                              </div>
                              <div className="card-tools col-lg-4">
                                <label
                                  className="form-label trans"
                                  htmlFor="approvedBy"
                                >
                                  top
                                </label>
                                <select
                                  onChange={this.handleChange}
                                  className="form-control form-control-md"
                                  id="approvedBy"
                                  name="approvedBy"
                                >
                                  <option>Filter by Approvals</option>
                                  {this.state.approvals.map(
                                    (approval, index) => (
                                      <option key={index} value={approval.id}>
                                        {approval.fullname}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              <div className="col-4 ">
                                <div className="row mt-4 ">
                                  <div className="col-6 ">
                                    <div className="form-group">
                                      {this.state.loading ? (
                                        <Spinner />
                                      ) : (
                                        <button
                                          type="button"
                                          onClick={async (e) =>
                                            await this.filterData(e)
                                          }
                                          className="btn btn-block btn-secondary btn-md"
                                        >
                                          Filter
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="form-group">
                                      <button
                                        onClick={async (e) =>
                                          await this.resetData()
                                        }
                                        type="button"
                                        className="btn btn-block btn-warning btn-md"
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* .btn-toolbar */}
                          </div>
                          {/* .card-title-group */}
                        </div>
                        {/* .card-inner */}
                        <div className="card-inner p-0">
                          <table className="table table-tranx">
                            <thead>
                              <tr className="tb-tnx-head">
                                <th className="tb-tnx-id">
                                  <span className="fixed-weight">Invoice Number</span>
                                </th>

                                <th className="tb-tnx-id">
                                  <span className="fixed-weight">Raised by</span>
                                </th>

                                <th className="tb-tnx-info">
                                  <span className="tb-tnx-desc d-none d-sm-inline-block fixed-if">
                                    <span>Invoice For</span>
                                  </span>
                                  <span className="tb-tnx-date d-md-inline-block d-none">
                                    <span className="d-md-none">Date</span>
                                    <span className="d-none d-md-block">
                                      <span>Date Initiated</span>
                                      <span>Status</span>
                                    </span>
                                  </span>
                                </th>
                                <th className="tb-tnx-amount is-alt">
                                  <span className="tb-tnx-total">
                                    Amount (Payable)
                                  </span>
                                  <span className="tb-tnx-status d-none d-md-inline-block">
                                    Payment Status
                                  </span>
                                </th>
                                <th className="tb-tnx-action">
                                  <span>&nbsp;</span>
                                </th>
                              </tr>
                              {/* tb-tnx-item */}
                            </thead>
                            <tbody>
                              {this.state.tableLoading && (
                                <tr>
                                  <td>
                                    <Spinner />
                                  </td>
                                </tr>
                              )}
                              {this.state.invoices.sort((a, b) => { return (new Date(b.dateModified) - new Date(a.dateModified)) })
                                .map((invoice, index) => (
                                  <tr key={index} className="tb-tnx-item">
                                    <td className="tb-tnx-id">
                                      <span>{invoice.invoiceNumber}</span>
                                    </td>
                                    <td className="tb-tnx-id">
                                      <span>{invoice.raisedBy}</span>
                                    </td>
                                    <td className="tb-tnx-info">
                                      <div className="tb-tnx-desc">
                                        <span className="title">
                                          {invoice.customer.firstName}{" "}
                                          {invoice.customer.lastName}
                                          {/* {invoice.customer.emailAddress}{" "} */}
                                        </span>
                                      </div>
                                      <div className="tb-tnx-date">
                                        <span className="date">
                                          {this.convertDate(invoice.dateCreated)}
                                        </span>
                                        {invoice.status ===
                                          "awaiting_approval" && invoice.voided === false && (
                                            <span className="badge badge-dot badge-warning">
                                              Awaiting Approval
                                            </span>
                                          )}
                                        {invoice.status === "returned" && invoice.voided === false && (
                                          <span className="badge badge-dot badge-danger">
                                            Returned
                                          </span>
                                        )}
                                        {invoice.status === "cancelled" && invoice.voided === false && (
                                          <span className="badge badge-dot badge-danger">
                                            Cancelled
                                          </span>
                                        )}
                                        {invoice.status === "approved" && invoice.voided === false && (
                                          <span className="badge badge-dot badge-success">
                                            Approved
                                          </span>
                                        )}
                                        {invoice.status && invoice.voided === true && (
                                          <span className="badge badge-dot badge-danger">
                                            Voided
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                    <td className="tb-tnx-amount is-alt">
                                      <div className="tb-tnx-total">
                                        <span className="amount">
                                          {invoice.currency}
                                          {invoice.amount.toLocaleString()}{" "}
                                          <span className="text-muted">
                                            (
                                            {Functions.currencyFormat(
                                              invoice.amountPayable
                                            )}
                                            )
                                          </span>
                                        </span>
                                      </div>
                                      <div className="tb-tnx-status">
                                        {invoice.isPaid && (
                                          <span className="badge badge-dot badge-success">
                                            Paid
                                          </span>
                                        )}
                                        {!invoice.isPaid && (
                                          <span className="badge badge-dot badge-danger">
                                            Not Paid
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                    <td className="tb-tnx-action">
                                      <div className="link-list-plain">
                                        <Link className="view-but" to={"/invoice?ref=" + invoice.invoiceNumber}>
                                          View <img type="button" className="img-arrow" src="/images/arroww.png" />
                                        </Link>
                                      </div>
                                      {/* <div className="dropdown">
                                      <a
                                        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                        data-toggle="dropdown"
                                      >
                                        <em className="icon ni ni-more-h" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                        <ul className="link-list-plain">
                                          <li>
                                            <Link to={"/invoice?ref=" + invoice.invoiceNumber}>
                                              {" "}
                                              View
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </div> */}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {/* .card-inner */}
                        <div className="card-inner">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            <li className="page-item">
                              <a
                                className="page-link"
                                href="#"
                                // onClick={async () =>
                                //   await this.listInvoices(
                                //     null,
                                //     this.state.page - 1
                                //   )
                                // }
                                onClick={this.previous}
                              >
                                Prev
                              </a>
                            </li>
                            <li className="page-item">
                              <a
                                className="page-link"
                                href="#"
                                // onClick={async () =>
                                //   await this.listInvoices(
                                //     null,
                                //     this.state.page + 1
                                //   )
                                // }
                                onClick={this.next}
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                          {/* .pagination */}
                        </div>
                        {/* .card-inner */}
                      </div>
                      {/* .card-inner-group */}
                    </div>
                    {/* .card */}
                  </div>
                  {/* .nk-block */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
