import React, { Component } from "react";
import "../../sass/others.css";
import Toastr from "../../Utils/Toastr";
import { DataContext } from "../../Contexts/DataContexts";
import UsersService from "./../../services/UsersService";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";
import Logo from "../../Logo";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        email: "",
        password: "",
      },
    };
  }

  static contextType = DataContext;

  componentDidMount() {}

  logUserIn = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var Data = await UsersService.Login(this.state.data);
    //console.log(Data);

    if (Data) {
      if (Data.message.toLowerCase() === "success") {
        localStorage.setItem("token", Data.token);
        localStorage.setItem("user", JSON.stringify(Data.user));
        this.context.addItem("user", Data.user);
        window.location.href = "/home";
        //this.props.history.push("/home");
      } else {
        Toastr("error", Data.message);
      }
    }
    this.setState({ loading: false });
  };
  handleLogin = async ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  render() {
    return (
      <div className="nk-content row align-self-center h-100">
        <div className="nk-block nk-block-middle nk-auth-body  wide-xs col" style={{marginLeft:"7vw"}}>
          <div className="card card-bordered" style={{backgroundColor:"#fff0", border:"none", maxWidth:"420px"}}>
            <div className="card-inner card-inner-lg login-card" style={{backgroundColor:"#fff0"}}>
              <div className="nk-block-head">
                <div className="nk-block-head-content text-Center" style={{width:"fit-content", margin:"auto"}}>
                  {/* <Logo style={{ height: "45px" }} /> */}
                  {/* <img className="mb-4" src="https://i.ibb.co/TbkVR3h/maple-removebg-preview.png" alt="maple-removebg-preview" border={0} /> */}
                  <h5 className="nk-block-title my-5" style={{fontWeight:"200", color:"#f1f1f1"}}>ERP Login</h5>
                </div>
              </div>
              <form action="" onSubmit={async (e) => await this.logUserIn(e)}>
                <div className="form-group">
                  <div className="form-label-group">
                    {/* <label className="form-label" htmlFor="default-01">
                      Email Address
                    </label> */}
                  </div>
                  <div className="form-control-wrap">
                    <input
                      style={{backgroundColor:"#fff0", border:"0px", borderBottom:"2px solid white", borderRadius:"0px", color:"white", outline:"none"}}
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Email address"
                      name="email"
                      value={this.state.data.email}
                      onChange={this.handleLogin}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    {/* <label className="form-label" htmlFor="password">
                      Password
                    </label> */}
                  </div>
                  <div className="form-control-wrap">
                    {/* <Link to="#" className="form-icon form-icon-right passcode-switch lg" data-target="password">
                      <em className="passcode-icon icon-show icon ni ni-eye" />
                      <em className="passcode-icon icon-hide icon ni ni-eye-off" />
                    </Link> */}
                    <input
                      style={{backgroundColor:"#fff0", border:"0px", borderBottom:"2px solid white", borderRadius:"0px", color:"white", outline:"none"}}
                      type="password"
                      className="form-control form-control-lg"
                      name="password"
                      value={this.state.data.password}
                      onChange={this.handleLogin}
                      id="password"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div className="form-group" style={{marginTop:"50px"}}>
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <button className="btn btn-lg btn-primary btn-block">
                      Log in
                    </button>
                  )}
                </div>
                <div style={{width:"fit-content", margin:"auto"}}>
                <Link
                  style={{color:"#dbdfea"}}
                  className="link link-muted link-sm"
                  to="/password-reset">
                  Forgot Password?
                </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
