import React, { Component } from "react";
import Logo from "../Logo";
import Functions from "../Utils/Functions";
import { Link } from "react-router-dom";
import Constants from "../Utils/Constants";
import { DataContext } from "../Contexts/DataContexts";

export default class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileDropdownVisible: false,
      displayExtraTopLinks: false,
      user: {},
    };
  }

  static contextType = DataContext;

  componentDidMount() {
    this.setState({ user: this.context.globalState.user });
  }

  toggleProfileDropdown() {
    let { profileDropdownVisible } = this.state;
    this.setState({ profileDropdownVisible: !profileDropdownVisible }, () => {
      console.log("profile dropdown: ", profileDropdownVisible);
    });
  }

  render() {
    return (
      <div className="nk-header nk-header-fixed is-light">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ml-n1">
              <a
                href="#"
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="sidebarMenu"
              >
                <em className="icon ni ni-menu" />
              </a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a href="#" className="logo-link">
                <img
                  className="logo-light logo-img"
                  src="./logo.png"
                  alt="logo"
                />
              </a>
            </div>
            {/* .nk-header-brand */}
            <div className="nk-header-news d-none d-xl-block">
              <div className="nk-news-list"></div>
            </div>
            {/* .nk-header-news */}
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li className="dropdown user-dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt" />
                      </div>
                      <div className="user-info d-none d-md-block">
                        <div className="user-status">
                          {this.state.user?.role || "Staff"}
                        </div>
                        <div className="user-name dropdown-indicator">
                          {this.state.user?.fullname || "User"}
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li>
                          <Link to={"/password-reset"}>
                            <em className="icon ni ni-setting-alt" />
                            <span>Change Password</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li>
                          <Link to="/logout">
                            <em className="icon ni ni-signout" />
                            <span>Sign out</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
              {/* .nk-quick-nav */}
            </div>
            {/* .nk-header-tools */}
          </div>
          {/* .nk-header-wrap */}
        </div>
        {/* .container-fliud */}
      </div>
    );
  }
}
