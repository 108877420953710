import APICall from "../Utils/APICall";

export default class ArchiveService {

  static fetchArchive = async (
    page = 20,
    pageSize = 1,
    month,
    year,
    accountId = 0
  ) => {
    let url = `/api/Archive/fetch/${pageSize}/${page}?accountId=${accountId}`;
    if (month) url = `${url}&month=${month}`;
    if (year) url = `${url}&year=${year}`;
    return await APICall(url, "GET");
  };
}
