import React from 'react';


function VoucherStatus(voucher) {
    const statusThemes = {
        awaiting_approval: "warning",
        returned: "danger",
        approved: "success",
        cancelled: "secondary"
    }

    return (
        <span className={`badge badge-${statusThemes[voucher.status]}`}>{voucher.status?.toUpperCase()}</span>
    );
}

export default VoucherStatus;