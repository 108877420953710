import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import BanksService from "./../../services/BanksService";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";
import Select from "react-select";

export default class BankList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      banks: [],
      bankId: "",
      allAccounts: [],
    };
  }

  accountTypes = [
    { label: "Bank Accounts", value: "BANK" },
    { label: "Cash Accounts", value: "CASH" },
    { label: "Card Accounts", value: "CARD" },
    { label: "All Accounts", value: "ALL" },
  ];

  handleAccountType = (option) => {
    let accountsToDisplay = this.state.allAccounts;
    if (option.value != "ALL") {
      accountsToDisplay = this.state.allAccounts.filter(
        (a) => a.accountType?.toLowerCase() == option.value.toLowerCase()
      );
    }
    this.setState({ banks: accountsToDisplay });
  };

  componentDidMount = async () => {
    this.setState({ loading: true });

    const banks = await BanksService.listBankAccount();
    console.log({ banks: banks });
    if (banks) {
      this.setState({ banks: banks, allAccounts: banks });
    }
    this.setState({ loading: false });
  };

  tableRow = ({
    bankName,
    accountNumber,
    bankAddress,
    zipCode,
    sortCode,
    accountType,
    accountingCode,
    currency,
    id,
  }) => {
    return (
      <div className="nk-tb-item">
        <div className="nk-tb-col">
          <div className="nk-tnx-type">
            <div className="nk-tnx-type-text">
              <span className="tb-lead">{bankName}</span>
              {accountNumber ? (
                <span className="tb-lead-sub">{accountNumber}</span>
              ) : (
                <span className="tb-lead-sub">{"Not Available"}</span>
              )}
            </div>
          </div>
        </div>

        <div className="nk-tb-col text-left">
          {bankAddress ? (
            <span className="tb-lead">{bankAddress}</span>
          ) : (
            <span className="tb-lead">{"Not applicable"}</span>
          )}
        </div>
        <div className=" nk-tb-col">
          <div className="nk-tnx-type-text text-left">
            <span className="tb-lead">{zipCode}</span>
            {sortCode ? (
              <span className="tb-lead-sub">{sortCode}</span>
            ) : (
              <span className="tb-lead-sub">{"Not Available"}</span>
            )}
          </div>
        </div>
        <div className="nk-tb-col text-left">
          <span className="tb-lead">{accountType}</span>
        </div>
        <div className="nk-tb-col text-left">
          {accountingCode ? (
            <span className="tb-lead">{accountingCode}</span>
          ) : (
            <span className="tb-lead">{"Not Available"}</span>
          )}
        </div>
        <div className="nk-tb-col text-left">
          {currency ? (
            <span className="tb-lead">{currency}</span>
          ) : (
            <span className="tb-lead">{"N/A"}</span>
          )}
        </div>
        {/* <div className="nk-tb-col nk-tb-col-status list text-right">
          <Link
            to={"/account/" + id}
            className="btn btn-primary btn-md px-3 btn-block"
          >
            <em class="icon ni ni-eye mr-1"></em>
            View
          </Link>
        </div> */}
      </div>
    );
  };
  render() {
    return (
      <MainLayout title="Accounts List">
        <div className="col">
          <div className="card card-preview col-lg-12">
            <div className="card-inner">
              <div
                id="_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="row justify-between g-2">
                  <div className="col-7 col-sm-4 text-left">
                    <div id="_filter" className="">
                      <Select
                        onChange={(option) => this.handleAccountType(option)}
                        options={this.accountTypes}
                        placeholder="Filter by account type"
                      />
                    </div>
                  </div>
                  <div
                    className="col-5 col-sm-8 text-right"
                    style={{ marginTop: "5px" }}
                  >
                    {this.state.loading ? (
                      <Spinner />
                    ) : (
                      <Link
                        className="btn btn-primary btn-md"
                        to="/add-account"
                      >
                        <em class="icon ni ni-plus-fill-c mr-2"></em>
                        <span className="d-none d-sm-inline-block">
                          Add New
                        </span>
                      </Link>
                    )}
                  </div>
                </div>

                <div className="card-inner p-0" style={{ marginTop: "35px" }}>
                  <div className="nk-tb-list nk-tb-tnx">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col tb-col">
                        <b>
                          <span>Account Details</span>
                        </b>
                      </div>

                      <div className="nk-tb-col text-left">
                        <b>
                          {" "}
                          <span> Bank Address</span>
                        </b>
                      </div>
                      <div className="nk-tb-col tb-col ">
                        <b>
                          {" "}
                          <span> Account Codes</span>
                        </b>
                      </div>
                      <div className="nk-tb-col tb-col">
                        <b>
                          <span>Account Type</span>
                        </b>
                      </div>
                      <div className="nk-tb-col tb-col">
                        <b>
                          <span>Accounting Code</span>
                        </b>
                      </div>
                      <div className="nk-tb-col tb-col">
                        <b>
                          <span>Currency</span>
                        </b>
                      </div>
                    </div>
                    {this.state.loading ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <div className="nk-tnx-type ">
                            <div className="nk-tnx-type-text">
                              <span className="tb-lead">
                                <Spinner />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      this.state.banks.map((account, index) => (
                        <this.tableRow key={index} {...account} />
                      ))
                    )}
                  </div>
                </div>
                {/* <div className="datatable-wrap my-3">
                                <table className=" nowrap table  no-footer dtr-inline" id="" >
                                    <thead>
                                        <tr role="row">
                                            <th className="sorting"  >Bank Name</th>
                                            <th className="sorting"  >Account Number</th>
                                            <th className="sorting"  >Bank Address</th>
                                            <th className="sorting"  >Zip Code</th>
                                            <th className="sorting"  >Sort Code</th>
                                            <th className="sorting"  > </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.banks.map((account, index) => (
                                            <this.tableRow key={index} {...account} />
                                        ))}


                                    </tbody>
                                </table>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
