import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import "../invoice/invoice.css";
import InvoiceService from "../../services/InvoiceService";
import { DataContext } from "../../Contexts/DataContexts";
import Toastr from "../../Utils/Toastr";
import Spinner from "../../Utils/Spinner";
import InvoiceStatus from "../../Utils/InvoiceStatus";
import Modal from "react-modal";
import Constants from "../../Utils/Constants";
import BanksService from "../../services/BanksService";
import ServicesService from "../../services/ServicesService";
import Select from "react-select";
import Logo from "../../Logo";
import { FormatMoney } from "../../Utils/format-currency";
import UploadedFiles from "../../Components/UploadedFiles";

export default class InvoiceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportingDocuments: [],
      pdfUrl: "",
      invoiceId: "",
      loading: false,
      approvalLoading: false,
      selfCreated: false,
      rejectLoading: false,
      voidLoading: false,
      settlementBanks: [],
      banks: [],
      serviceUsed: {},
      services: [],
      invoiceItems: [],
      invoice: {
        amount: 0,
        vat: 0,
        customer: {},

        // worflowTrail:[]
      },
      paymentChannels: [
        { name: "Online", value: "online" },
        { name: "Offline", value: "offline" },
      ],
      modeOfPayment: "",
      vatPercentage: 0,
      vat: 0,
      total: 0,
      role: "",
      data: {
        invoiceNumber: "",
        rejectionMessage: "",
      },
      approvalData: {
        invoiceNumber: "",
        approvalMessage: "",
        paymentChannel: "",
        settlementBanks: [],
        vatPercentage: 0,
      },
      modalOpen: false,
      confirmModalOpen: false,
      voidOpen: false,
      paymentInfo: "",
    };
  }
  static contextType = DataContext;

  downloadInvoice = async (invoiceNumber) => {
    this.setState({ loading: true });
    let download = await InvoiceService.download(invoiceNumber);
    if (download) this.setState({ download: download });
    this.setState({ loading: false });
  };

  getInvoice = async (invoiceNumber) => {
    this.setState({ loading: true });
    var invoice = await InvoiceService.get(invoiceNumber);
    if (invoice) {
      this.setState({ supportingDocuments: invoice.documents })
      console.log(this.state.supportingDocuments);
      const globalState = this.context.globalState;
      let userId = globalState.user?.id;
      let vatPercentage = invoice.vat;
      console.log(vatPercentage);
      let vatAmount =
        (parseFloat(vatPercentage) / 100) * (invoice.amount - invoice.discount);
      let total = invoice.amount - invoice.discount + vatAmount;
      this.setState({
        invoice: invoice,
        vatPercentage: vatPercentage,
        total: total,
        vat: vatAmount,
      });

      console.log({invoice:invoice});
      this.setState({pdfUrl:invoice.pdfUrl})
      if (invoice) {
        this.setState({ modeOfPayment: invoice.modeOfPayment });
      }
      console.log(invoice.modeOfPayment);
      if (invoice.lineItems) {
        this.setState({ invoiceItems: invoice.lineItems });
      }
      this.setState({ selfCreated: userId === invoice.createdBy });
    }
    this.setState({ loading: false });

    let addInfo = invoice.worflowTrail;
    addInfo.forEach(info =>{
      if(info.actionType === "approval"){
        this.setState({paymentInfo: info.comments});
      }
    })
  };

  componentDidMount = async () => {
    const globalState = this.context.globalState;
    let role = globalState.user?.role?.toLowerCase();
    this.setState({ role: role });
    let invoiceId = new URLSearchParams(window.location.search).get("ref");
    this.setState({ invoiceId: invoiceId });
    await this.getInvoice(invoiceId);
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data: data });
  };
  handleApprovalChange = ({ currentTarget: input }) => {
    const approvalData = { ...this.state.approvalData };
    approvalData[input.name] = input.value;
    console.log(approvalData);
    this.setState({ approvalData: approvalData });
  };

  RejectInvoice = async (e) => {
    e.preventDefault();
    this.setState({ rejectLoading: true });
    const data = { ...this.state.data };
    data.invoiceNumber = this.state.invoiceId;
    let response = await InvoiceService.reject(data);
    if (response) {
      Toastr("success", "Request processed successfully");
      setTimeout(async () => {
        await this.getInvoice(data.invoiceNumber);
      }, 2000);
    }
    this.setState({ rejectLoading: false });
  };
  GetFirstLetter(stringValue = "") {
    return stringValue.charAt(0).toUpperCase();
  }
  listServices = async () => {
    this.setState({ loading: true });
    var services = [];
    services = await ServicesService.listServices();
    if (services) {
      let serviceUsed = services.find(
        (obj) => obj.id == this.state.invoice.serviceId
      );
      this.setState({ serviceUsed: serviceUsed });
      this.setState({ services: services });
    }
    this.setState({ loading: false });
  };
  approveInvoice = async (e) => {
    e.preventDefault();
    // debugger;
    this.setState({ approvalLoading: true });
    const data = { ...this.state.approvalData };
    data.invoiceNumber = this.state.invoiceId;
    data.vatPercentage = parseFloat(data.vatPercentage);
    let response = await InvoiceService.approve(data);
    if (response) {
      Toastr("success", "Invoice approved");
      this.setState({ modalOpen: false });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    this.setState({ approvalLoading: false });
  };

  voidInvoice = async (e) => {
    e.preventDefault();
    // debugger;
    this.setState({ voidLoading: true });
    const data = { ...this.state.approvalData };
    data.invoiceNumber = this.state.invoiceId;
    data.vatPercentage = parseFloat(data.vatPercentage);
    let response = await InvoiceService.voidInvoice(data.invoiceNumber);
    if (response) {
      Toastr("success", "Invoice voided");
      this.setState({ voidOpen: false });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    this.setState({ voidLoading: false });
  };

  viewVoidModal = async () => {
    this.setState({ voidOpen: true });
  }
  viewApprovalModal = async () => {
    this.setState({ approvalLoading: true });
    let invoice = { ...this.state.invoice };
    let bankList = await BanksService.listBankAccount();
    let data = { ...this.state.approvalData };
    var settlementBank = [];
    var banks = [];
    if (invoice.lineItems) {
      invoice.lineItems.forEach((element) => {
        let bankNeeded = bankList.find(
          (obj) =>
            obj.accountNumber === element.settlementBankAccount &&
            obj.bankCode === element.settlementBankCode
        );
        settlementBank.push({
          bankId: bankNeeded.id,
          lineItemId: element.id,
          accountNumber: bankNeeded.accountNumber,
          bankName: bankNeeded.bankName,
          isChanged: false,
          serviceDesc: element.description,
        });
      });
      data.settlementBanks = [...settlementBank];
      data.vatPercentage = this.state.vatPercentage;
      this.setState({ approvalData: data });
      bankList.forEach((element) => {
        banks.push({
          label: `${element.accountNumber} - ${element.bankName}`,
          value: element.id,
        });
      });
      this.setState({ settlementBanks: banks, banks: bankList });
    }
    this.setState({ approvalLoading: false });
    window.scrollTo(0, 0);
    this.setState({ modalOpen: true });
  };
  cancelOverride = (e, index) => {
    e.preventDefault();
    let data = { ...this.state.approvalData };
    let itemNeeded = this.state.invoice.lineItems.find(
      (obj) => obj.id === parseInt(data.settlementBanks[index].lineItemId)
    );
    let bankNeeded = this.state.banks.find(
      (obj) =>
        obj.accountNumber === itemNeeded.settlementBankAccount &&
        obj.bankCode === itemNeeded.settlementBankCode
    );
    const resetData = {
      bankId: bankNeeded.id,
      lineItemId: itemNeeded.id,
      accountNumber: bankNeeded.accountNumber,
      bankName: bankNeeded.bankName,
      isChanged: false,
      serviceDesc: itemNeeded.description,
    };
    data.settlementBanks[index] = { ...resetData };

    this.setState({ approvalData: data });
  };
  overrideBank = (e, index) => {
    e.preventDefault();
    let data = { ...this.state.approvalData };
    data.settlementBanks[index].isChanged = true;
    this.setState({ approvalData: data });
  };
  confirmInvoiceModal = async () => {
    this.setState({ confirmModalOpen: true });
  };
  confirmInvoice = async (e) => {
    e.preventDefault();
    this.setState({ approvalLoading: true });
    let invoiceNumber = new URLSearchParams(window.location.search).get("ref");
    // console.log(invoiceNumber);
    let confirmInvoice = await InvoiceService.confirm(invoiceNumber);
    console.log(confirmInvoice);
    if (confirmInvoice) {
      Toastr("success", "Payment confirmed");
    }
    window.location.reload();
    this.setState({ approvalLoading: false });
  };
  handleBankChange = (option, index) => {
    if (option) {
      let data = { ...this.state.approvalData };
      // let bankNeeded = this.state.banks.find(obj => obj.id === parseInt(option.value) );
      data.settlementBanks[index].bankId = parseInt(option.value);
      this.setState({ approvalData: data });
    }
  };
  handleVatChange = async ({ currentTarget: input }) => {
    const data = { ...this.state.approvalData };
    data[input.name] = input.value;
    this.setState({ approvalData: data });
  };

  render() {
    return (
      <MainLayout title="">
        <Modal
          isOpen={this.state.confirmModalOpen}
          style={Constants.defaultModalStyle}
          // style={Constants.transparentModalStyle}
          ariaHideApp={false}
          onRequestClose={() => this.setstate({ confirmModalOpen: false })}
        >
          <div>
            <p>Are you sure you want to confirm this payment?</p>
            <div className="">
              <div className="row">
                <div className="col-md-6">
                  {this.state.approvalLoading ? (
                    <Spinner />
                  ) : (
                    <button
                      type="button"
                      className="btn btn btn-primary btn-block"
                      onClick={async (e) => await this.confirmInvoice(e)}
                    // onClick={() => this.setState({ confirmModalOpen: true })}
                    >
                      Confirm
                    </button>
                  )}
                </div>
                <div className="col-md-6">
                  <button
                    onClick={() => this.setState({ confirmModalOpen: false })}
                    type="button"
                    className="btn btn-dark btn-block"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.modalOpen}
          style={Constants.invoiceModalStyle}
          onRequestClose={() => this.setState({ modalOpen: false })}
        >
          <div style={{ width: "650px" }}>
            {
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="approvalMessage">
                      Comment
                    </label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control form-select-lg"
                        id="approvalMessage"
                        name="approvalMessage"
                        onChange={this.handleApprovalChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="paymentChannel">
                      Payment Channel
                    </label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <select
                          className="form-control form-select-lg"
                          onChange={this.handleApprovalChange}
                          id="paymentChannel"
                          name="paymentChannel"
                        >
                          <option value="">Choose Channel</option>
                          {this.state.paymentChannels.map((channel, index) => (
                            <option key={index} value={channel.value}>
                              {channel.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="vatPercentage">
                      Vat Percentage
                    </label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control form-select-lg"
                        value={this.state.approvalData.vatPercentage}
                        id="vatPercentage"
                        name="vatPercentage"
                        onChange={this.handleApprovalChange}
                      />
                    </div>
                  </div>
                </div>
              </div>}

            <div>
              <hr className="preview-hr" />
              <span className="preview-title-lg overline-title">
                Invoice Item{" "}
              </span>
              {this.state.approvalData.settlementBanks.map((item, index) => (
                <>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="">
                          Description
                        </label>
                        <div className="form-control-wrap">
                          <span className="tb-lead">{item.serviceDesc}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="">
                          Settlement Bank
                        </label>
                        <div className="form-control-wrap">
                          {item.isChanged ? (
                            <Select
                              onChange={(option) =>
                                this.handleBankChange(option, index)
                              }
                              options={this.state.settlementBanks}
                            />
                          ) : (
                            <span className="tb-lead">
                              {item.accountNumber} - {item.bankName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group mt-2">
                        <div className="form-control-wrap">
                          {item.isChanged ? (
                            <button
                              type="button"
                              className="btn btn-dark btn-block mt-3"
                              onClick={(e) => this.cancelOverride(e, index)}
                            >
                              Cancel
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary btn-block mt-3"
                              onClick={(e) => this.overrideBank(e, index)}
                            >
                              Override
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="preview-hr" />
                </>
              ))}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    {this.state.approvalLoading ? (
                      <Spinner />
                    ) : (
                      <button
                        type="button"
                        className="btn btn btn-primary btn-block"
                        onClick={async (e) => await this.approveInvoice(e)}
                      >
                        Approve
                      </button>
                    )}
                  </div>
                  <div className="col-md-6">
                    <button
                      onClick={() => this.setState({ modalOpen: false })}
                      type="button"
                      className="btn btn-dark btn-block"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.voidOpen}
          style={Constants.invoiceModalStyle}
          onRequestClose={() => this.setState({ voidOpen: false })}
        >
          <div style={{ width: "650px" }}></div>
          <div style={{ overflow: "hidden" }}>
            {" "}
            <div className="col-md-12 mb-0 row p-3 pb-1">
              <h4>
                Void invoice
              </h4>
            </div>
            <p className="text-danger">
              You are about to void this invoice.
            </p>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    {this.state.approvalLoading ? (
                      <Spinner />
                    ) : (
                      <button
                        type="button"
                        className="btn btn btn-primary btn-block"
                        onClick={async (e) => await this.voidInvoice(e)}
                      >
                        Void invoice
                      </button>
                    )}
                  </div>
                  <div className="col-md-6">
                    <button
                      onClick={() => this.setState({ voidOpen: false })}
                      type="button"
                      className="btn btn-dark btn-block"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="col">
            <div className="nk-content ">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-block-head">
                      <div className="nk-block-between g-3">
                        <div className="nk-block-head-content">
                          {this.state.invoice.voided === false && (
                            <h3 className="nk-block-title page-title">
                              Invoice{" "}
                              <strong className="text-primary small">
                                #{this.state.invoice.invoiceNumber}
                              </strong>{" "}
                              {InvoiceStatus(this.state.invoice)}{" "}
                            </h3>
                          )}
                          {this.state.invoice.voided === true && (
                          <h3 className="nk-block-title page-title">
                            Invoice{" "}
                            <strong className="text-primary small">
                              #{this.state.invoice.invoiceNumber}
                            </strong>{" "}
                            <span className={`badge badge-danger`}>voided</span>
                          </h3>
                          )}
                          <div className="nk-block-des text-soft">
                            <ul className="list-inline">
                              <li>
                                Date generated:{" "}
                                <span className="text-base">
                                  {new Date(
                                    this.state.invoice.dateCreated
                                  ).toDateString()}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="nk-block-head-content">
                          <Link
                            to="/invoices"
                            className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                          >
                            <em className="icon ni ni-arrow-left" />
                            <span>Back</span>
                          </Link>
                          <Link
                            to="/invoices"
                            className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                          >
                            <em className="icon ni ni-arrow-left" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* .nk-block-head */}
                    <div className="nk-block">
                      <div className="invoice">
                        <div className="invoice-action">
                          {/* <Link className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary" to="#" target="_blank"><em className="icon ni ni-printer-fill" /></Link> */}
                        </div>
                        {/* .invoice-actions */}
                        <div className="invoice-wrap">
                          <div style={{display:"flex", justifyContent:"space-between"}}>
                          <div className="invoice-brand text-left">
                            <Logo />
                          </div>
                          <div>
                            <a  
                              href={this.state.pdfUrl} target="_blank" rel="noopener noreferrer"
                              hidden={this.state.invoice.status === "approved"? false : true}>
                              <img className="invdownloadimg" src="/images/download.jpg"/>
                            </a>
                          </div>
                          </div>
                          <div className="invoice-head mt-5">
                            <div className="invoice-contact">
                              <span className="overline-title">Invoice To</span>
                              <div className="invoice-contact-info">
                                <h4 className="title">
                                  {this.state.invoice.customer.firstName}{" "}
                                  {this.state.invoice.customer.lastName}
                                </h4>
                                <ul className="list-plain">
                                  <li>
                                    <em className="icon ni ni-map-pin-fill" />
                                    {this.state.invoice.customer
                                      .contactAddress && (
                                        <span>
                                          {
                                            this.state.invoice.customer
                                              .contactAddress
                                          }
                                        </span>
                                      )}
                                    {!this.state.invoice.customer
                                      .contactAddress && <span></span>}
                                  </li>
                                  <li>
                                    <em className="icon ni ni-call-fill" />
                                    <span>
                                      {this.state.invoice.customer.phoneNumber}
                                    </span>
                                  </li>
                                  <li>
                                    <em className="icon ni ni-emails-fill" />
                                    <span>
                                      {this.state.invoice.customer.emailAddress}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="invoice-desc">
                              <ul className="list-plain">
                                <li className="invoice-id">
                                  <span>Invoice ID</span>
                                  <br />
                                  <span className="pl-0">
                                    {this.state.invoice.invoiceNumber}
                                  </span>
                                </li>
                                <li className="invoice-date">
                                  <span>Due Date</span>
                                  <br />
                                  <span className="pl-0">
                                    {new Date(
                                      this.state.invoice.dueDate
                                    ).toDateString()}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* .invoice-head */}
                          <div className="invoice-bills">
                            <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th className="w-250px">Invoice Number</th>
                                    <th className="w-60">Description</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.invoiceItems.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td>{item.invoiceNumber}</td>
                                        <td>
                                          Payment for - {item.description}
                                          <br />
                                          <br />
                                          <span className="font-weight-bold">
                                            {this.state.invoice.modeOfPayment ==
                                              "offline" &&
                                              item.settlementBankName +
                                              "-" +
                                              item.settlementBankAccount}
                                          </span>
                                        </td>
                                        <td>
                                          {this.state.invoice.currency}
                                          {item.amount.toLocaleString()}
                                        </td>
                                        <td>1</td>
                                        <td>
                                          {this.state.invoice.currency}
                                          {item.amount.toLocaleString()}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan={2}>
                                      {this.state.paymentInfo?<p>Payment Info: {" "}{this.state.paymentInfo === "Approved" || this.state.paymentInfo === "ok" || this.state.paymentInfo === "REVIEWED" ? "": this.state.paymentInfo}</p>: <p></p>}
                                    </td>
                                    <td colSpan={2}>Subtotal</td>
                                    <td>
                                      {FormatMoney(this.state.invoice.amount,this.state.invoice.currency)}
                                      {/* {this.state.invoice.currency}
                                      {this.state.invoice.amount.toLocaleString()} */}
                                    </td>
                                  </tr>

                                  {this.state.invoice.discount? this.state.invoice.discount && (
                                    <tr>
                                      <td colSpan={2} />
                                      <td colSpan={2}>Discount</td>
                                      <td>
                                        {FormatMoney(this.state.invoice.discount,this.state.invoice.currency)}
                                        {/* {this.state.invoice.currency}
                                        {this.state.invoice.discount.toLocaleString()} */}
                                      </td>
                                    </tr>
                                  ): <tr hidden={true}></tr>}
                                  {/* {
                                this.state.invoice.map((item, index) => (
                                  <tr key={index} > */}
                                  <tr>
                                    <td colSpan={2} />
                                    <td colSpan={2}>VAT</td>
                                    <td>
                                      {FormatMoney(this.state.vat,this.state.invoice.currency)}
                                      {/* {this.state.invoice.currency}
                                      {this.state.vat.toLocaleString()} */}
                                    </td>
                                  </tr>
                                  {/* ))
                                  } */}

                                  <tr>
                                    <td colSpan={2} />
                                    <td colSpan={2}>Grand Total</td>
                                    <td>
                                      {FormatMoney(this.state.total,this.state.invoice.currency)}
                                      {/* {this.state.invoice.currency}
                                      {this.state.total.toLocaleString()} */}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              {this.state.role === "accountant" &&
                                this.state.invoice.status !== "approved" &&
                                this.state.invoice.voided !== true &&
                                this.state.invoice.status !== "returned" && (
                                  <div className="text-right">
                                    {this.state.approvalLoading ? (
                                      <></>
                                    ) : (
                                      <button
                                        onClick={async () =>
                                          await this.viewApprovalModal()
                                        }
                                        type="submit"
                                        className="btn btn-primary my-3 btn-lg"
                                      >
                                        Approve Invoice
                                      </button>
                                    )}
                                  </div>
                                )}

                              {this.state.role === "accountant" &&
                                this.state.invoice.status !== "approved" &&
                                this.state.invoice.voided !== true &&
                                this.state.invoice.status !== "returned" && (
                                  <div className="text-right">
                                    {this.state.approvalLoading ? (
                                      <Spinner />
                                    ) : (
                                      <button
                                        onClick={async () =>
                                          await this.viewVoidModal()
                                        }
                                        type="submit"
                                        className="btn btn-light my-3 btn-lg ml-3"
                                      >
                                        Void Invoice
                                      </button>
                                    )}
                                  </div>
                                )}


                              {this.state.role === "accountant" &&
                                this.state.invoice.status === "approved" &&
                                this.state.invoice.paymentStatus !==
                                "successful" &&
                                this.state.invoice.isPaid === false && (
                                  <div className="text-right">
                                    {this.state.approvalLoading ? (
                                      <Spinner />
                                    ) : (
                                      <button
                                        onClick={async (e) =>
                                          await this.confirmInvoiceModal(e)
                                        }
                                        type="submit"
                                        className="btn btn-primary my-3 btn-lg"
                                      >
                                        Confirm Invoice
                                      </button>
                                    )}
                                  </div>
                                )}

                              <div className="nk-notes ff-italic fs-12px text-soft">
                                {" "}
                                Invoice was created on a computer and is valid
                                without the signature and seal.{" "}
                              </div>
                              
                            </div>

                            <div className="row mt-3">
                              <div className="col col-lg-8">
                                <span className="overline-title">
                                  Attached document(s)
                                </span>

                                <div>
                                  {this.state.supportingDocuments && (
                                    <UploadedFiles
                                      files={this.state.supportingDocuments}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* .invoice-bills */}
                        </div>
                        {/* .invoice */}
                        <div
                          className="nk-msg d-block"
                          style={{ display: "block !important", overflow: "auto" }}
                        >
                          <div className="nk-msg-body bg-white profile-shown" style={{paddingRight: "0px"}}>
                            <div className="nk-msg-head" style={{paddingRight: "45px", paddingBottom: "10px", paddingTop:"10px"}}>
                              <div className="nk-msg-head-meta" style={{justifyContent: "right"}}>
                                <div className="d-none d-lg-block">
                                  {this.state.invoice.status === "approved" && (
                                    <div className="download">
                                      <a
                                        target="_blank"
                                        className="btn btn-primary my-3 btn-md"
                                        href={`${Constants.apiBaseUrl}Download/receipt?invoiceNumber=${this.state.invoice.invoiceNumber}&authorization=${localStorage.getItem("token")}`}
                                      >
                                        {" "}
                                        View Receipt
                                      </a>
                                    </div>
                                  )}
                                  {this.state.invoice.status === "returned" &&
                                    this.state.selfCreated && (
                                      <ul className="nk-msg-tags">
                                        <Link style={{color: "white", backgroundColor: "grey", padding: "10px", marginRight: "20px", width: "100px"}}
                                          to={
                                            "/update-invoice?ref=" +
                                            this.state.invoiceId
                                          }
                                          className="btn btn-dim btn-sm btn-outline-light fix-edt"
                                        >
                                          <em className="icon ni ni-check"></em>
                                          <span>EDIT</span>
                                        </Link>
                                      </ul>
                                    )}
                                </div>
                                <div className="d-lg-none">
                                  <Link
                                    to="/update-invoice"
                                    className="btn btn-icon btn-trigger nk-msg-hide ml-n1"
                                  >
                                    <em className="icon ni ni-arrow-left" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            {/* .nk-msg-head */}
                            <div
                              className="nk-msg-reply nk-reply"
                              data-simplebar
                            >
                              <div className="nk-msg-head py-4 d-lg-none">
                                <h4 className="title">
                                  Unable to select currency when order
                                </h4>
                                <ul className="nk-msg-tags">
                                  <li>
                                    <span className="label-tag">
                                      <em className="icon ni ni-flag-fill" />{" "}
                                      <span>Technical Problem</span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                {this.state.invoice.worflowTrail &&
                                  this.state.invoice.worflowTrail.map(
                                    (workflow, index) =>
                                      workflow.comments && (
                                        <div
                                          key={index}
                                          className="nk-reply-item"
                                        >
                                          <div className="nk-reply-header">
                                            <div className="user-card">
                                              <div className="user-avatar sm bg-pink">
                                                <span>
                                                  {this.GetFirstLetter(
                                                    workflow.personName
                                                  )}
                                                </span>
                                              </div>
                                              <div className="user-name">
                                                {workflow.personName}
                                              </div>
                                              <div className="date-time ml-3">
                                                {new Date(
                                                  workflow.dateCreated
                                                ).toDateString()}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="nk-reply-body">
                                            <div className="nk-reply-entry entry">
                                              <p>
                                                <span className="badge badge-secondary mr-2">
                                                  {workflow.actionType}
                                                </span>
                                                {workflow.comments}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                  )}
                              </div>
                              {this.state.role === "accountant" &&
                                this.state.invoice.status !== "approved" &&
                                this.state.invoice.status !== "returned" &&
                                this.state.voided !== false && 
                                (
                                  <div className="nk-reply-form">
                                    <div className="nk-reply-form-header">
                                      <ul className="nav nav-tabs-s2 nav-tabs nav-tabs-sm">
                                        <li className="nav-item">
                                          <a
                                            className="nav-link active"
                                            data-toggle="tab"
                                            href="#reply-form"
                                          >
                                            Reply
                                          </a>
                                        </li>
                                      </ul>
                                      <div className="nk-reply-form-title">
                                        {/* <div className="title">Reply as:</div>
                               <div className="user-avatar xs bg-purple">
                                 <span>IH</span>
                               </div> */}
                                      </div>
                                    </div>
                                    <div className="tab-content">
                                      <div
                                        className="tab-pane active"
                                        id="reply-form"
                                      >
                                        <form
                                          onSubmit={async (e) =>
                                            await this.RejectInvoice(e)
                                          }
                                        >
                                          <div className="nk-reply-form-editor">
                                            <div className="nk-reply-form-field">
                                              <textarea
                                                className="form-control form-control-simple no-resize"
                                                id="rejectionMessage"
                                                name="rejectionMessage"
                                                placeholder=""
                                                onChange={this.handleChange}
                                                defaultValue={""}
                                              />
                                            </div>
                                            <div className="nk-reply-form-tools">
                                              <ul className="nk-reply-form-actions g-1">
                                                <li className="mr-2">
                                                  {this.state.rejectLoading ? (
                                                    <Spinner />
                                                  ) : (
                                                    <button
                                                      className="btn btn-danger"
                                                      type="submit"
                                                    >
                                                      Return
                                                    </button>
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                            {/* .nk-reply-form-tools */}
                                          </div>
                                          {/* .nk-reply-form-editor */}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                            {/* .nk-reply */}
                          </div>
                          {/* .nk-msg-body */}
                        </div>
                      </div>
                      {/* .nk-block */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    );
  }
}
