import React, { Component } from 'react';
import Constants from '../Utils/Constants';
import Functions from '../Utils/Functions';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from "react-modal";
import "../sass/file-preview.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

export default class UploadedFiles extends Component{
    constructor(props){
        super(props);
        this.state={
            pdfDocument: null
        }
    }


   componentDidMount(){
       Modal.setAppElement("body");
   }

   showDocument=(fileReference)=> {
       console.log("step 1");
       const certificateUrl = `${Constants.apiBaseUrl}api/voucher/viewfile/${fileReference}?authorization=${localStorage.getItem("token")}`;
       console.log("step 2"); 
       const pdfRenderer = (
            <div
                className="row"
                style={{ marginLeft: "-50px", marginRight: "-50px" }}
            >
                <div className="col">
                    <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        documents={[{ uri: certificateUrl }]}
                    />
                </div>
            </div>
        );
        console.log("step 3");
        this.setState({pdfDocument: pdfRenderer},()=>{
            console.log("step 4");
            document.body.style.overflow = 'hidden';
            console.log("step 5");
        });
        console.log("step 6");
    }
    render(){
        return (
            <>
            <Modal
                isOpen={this.state.pdfDocument ? true : false}
                contentLabel=""
                style={Constants.defaultModalStyle}
                onRequestClose={() => {
                    this.setState({pdfDocument:null},()=>{
                        document.body.style.overflow = 'unset';
                    });
                }}
            >
                <PerfectScrollbar>
                    <div
                        className="mymodal-wrapper file-preview-container lightbox-style"
                        style={{
                            width: "100vw",
                            maxWidth: "700px",
                            maxHeight: "90vh",
                        }}
                    >
                        {this.state.pdfDocument}
                    </div>
                </PerfectScrollbar>
            </Modal>
                <div className="nk-files nk-files-view-grid">
                    <div className="nk-files-list">
                        {this.props.files.map((file, index) => <div key={index} className="nk-file-item nk-file border-0" onClick={() => this.showDocument(file.fileReference)} style={{cursor: "pointer"}}>
                            <div className="nk-file-info">
                                <div className="nk-file-title">
                                    <div className="nk-file-icon">
                                        <a className="nk-file-icon-link" onClick={() => this.showDocument(file.fileReference)} style={{cursor: "pointer"}}>
                                            <span className="nk-file-icon-type">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                                                    <path d="M49,61H23a5.0147,5.0147,0,0,1-5-5V16a5.0147,5.0147,0,0,1,5-5H40.9091L54,22.1111V56A5.0147,5.0147,0,0,1,49,61Z" style={{ fill: '#e3edfc' }} />
                                                    <path d="M54,22.1111H44.1818a3.3034,3.3034,0,0,1-3.2727-3.3333V11s1.8409.2083,6.9545,4.5833C52.8409,20.0972,54,22.1111,54,22.1111Z" style={{ fill: '#b7d0ea' }} />
                                                    <path d="M19.03,59A4.9835,4.9835,0,0,0,23,61H49a4.9835,4.9835,0,0,0,3.97-2Z" style={{ fill: '#c4dbf2' }} />
                                                    <rect x={27} y={31} width={18} height={2} rx={1} ry={1} style={{ fill: '#7e95c4' }} />
                                                    <rect x={27} y={35} width={18} height={2} rx={1} ry={1} style={{ fill: '#7e95c4' }} />
                                                    <rect x={27} y={39} width={18} height={2} rx={1} ry={1} style={{ fill: '#7e95c4' }} />
                                                    <rect x={27} y={43} width={14} height={2} rx={1} ry={1} style={{ fill: '#7e95c4' }} />
                                                    <rect x={27} y={47} width={8} height={2} rx={1} ry={1} style={{ fill: '#7e95c4' }} />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="nk-file-name">
                                        <div className="nk-file-name-text">
                                            <a onClick={() => this.showDocument(file.fileReference)} style={{cursor: "pointer"}} className="title">{file.description}</a>

                                        </div>
                                    </div>
                                </div>
                                <ul className="nk-file-desc">
                                    {null && <li className="size">{Functions.currencyFormat(file.size / 1000)} kb</li>}
                                </ul>
                            </div>

                        </div>
                        )}
                    </div>
                </div></>
        );
    }
    
}