import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import { DataContext } from "../../Contexts/DataContexts";
import Toastr from "../../Utils/Toastr";
import Spinner from "../../Utils/Spinner";
import UploadedFiles from "../../Components/UploadedFiles";
import Modal from "react-modal";
import Constants from "../../Utils/Constants";
import BanksService from "../../services/BanksService";
import ServicesService from "../../services/ServicesService";
import Select from "react-select";
import Logo from "../../Logo";
import VoucherService from "../../services/VoucherService";
import VoucherStatus from "../../Utils/VoucherStatus";
import Functions from "../../Utils/Functions";
import { FormatMoney } from "../../Utils/format-currency";
import UsersService from "../../services/UsersService";

export default class VoucherView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      approvalLoading: false,
      rejectLoading: false,
      voidLoading: false,
      settlementBanks: [],
      banks: [],
      total: 0,
      role: "",
      comments: "",
      payload: {},
      modalOpen: false,
      realBanks: [],
      isAccountant: false,
      bypass1stApprover: false,
      supportingDocuments: [],
      
    };
  }

  static contextType = DataContext;

  roleActions = {
    accountant: ["accountant_review", "final_processing"],
    owner: ["owner_review"],
    firstlevelauthorizer: ["first_approval"],
    secondlevelauthorizer: ["second_approval", "first_approval"],
  };

  actionAPI = {
    accountant_review: {
      approve: VoucherService.approveVoucherAccountant,
      reject: VoucherService.AccountantRejectVoucher,
    },
    final_processing: {
      approve: VoucherService.finalProcessing,
    },
    first_approval: {
      approve: VoucherService.firstLevelApproval,
      reject: VoucherService.AppprovalLevelReject,
    },
    second_approval: {
      approve: VoucherService.secondLevelApproval,
      reject: VoucherService.AppprovalLevelReject,
    },
  };

  updatePayload = (key, value) => {
    let disburseAccount = this.state.disburseAccount;

    let payload = this.state.payload || {};

    payload[key] = value;

    if (payload.bankAccountId && payload.bankAccountId > 0) {
      disburseAccount = this.state.banks.find(
        (x) => x.id === payload.bankAccountId
      );
    }
    this.setState({ payload: payload, disburseAccount: disburseAccount });
  };

  checkBox = () => {
    var checker = document.getElementById("checker");
    if (checker.checked == true) {
      this.setState({ bypass1stApprover: true });
      this.updatePayload("bypassFirstLevelApproval", true);
    } else {
      this.setState({ bypass1stApprover: false });
      this.updatePayload("bypassFirstLevelApproval", false);
    }
  };

  convertDatetoReadable(date) {
    if (date) {
      return new Date(date).toISOString().split("T")[0];
    }
    return "";
  }

  getVoucher = async (voucherCode) => {
    this.setState({ loading: true });
    var voucher = await VoucherService.getVoucher(voucherCode);
    // console.log(voucher);
    console.log({ voucher: voucher });
    if (voucher) {
      if (voucher.disburseFromAccountId) {
        const disburseAccount = this.state.banks.find(
          (a) => a.id == voucher.disburseFromAccountId
        );
        this.setState({ disburseAccount });
      }
      this.setState({ voucher: voucher });

      this.setState({ voucher: voucher, comments: voucher.comments });

      if (voucher.lineItems) {
        let total = 0;
        voucher.lineItems.forEach((element) => {
          total += element.amount;
        });
        this.setState({ voucherItems: voucher.lineItems, total });
      }
      const globalState = this.context.globalState;
      let role = globalState.user?.role?.toLowerCase();
      if (globalState.user?.id == voucher.createdBy) {
        if (
          !(this.state.isAccountant && voucher.nextAction == "final_processing")
        ) {
          role = "owner";
        }
      }

      this.setState({ role: role });
    }

    this.setState({ loading: false });
  };

  componentDidMount = async () => {
    const globalState = this.context.globalState;
    // console.log({ globalState: globalState });
    if (globalState.user?.role?.toLowerCase() === "accountant") {
      this.setState({ isAccountant: true });
    }
    const realBanks = await BanksService.listBank();
    this.setState({ realBanks: realBanks });

    const banks = await BanksService.listBankAccount();
    if (banks) {
      banks.forEach((element) => {
        element.label = `${element.accountType} - ${element.accountNumber} - ${element.bankName}`;
        element.value = element.id;
      });
    }

    this.setState({ banks });
    let voucherId = Functions.GetPathUrl(window.location.pathname);
    this.setState({ voucherId: voucherId });

    await this.getVoucher(voucherId);
  };

  GetFirstLetter(stringValue = "") {
    return stringValue.charAt(0).toUpperCase();
  }

  listServices = async () => {
    this.setState({ loading: true });
    var services = [];
    services = await ServicesService.listServices();
    if (services) {
      let serviceUsed = services.find(
        (obj) => obj.id == this.state.invoice.serviceId
      );
      this.setState({ serviceUsed: serviceUsed });
      this.setState({ services: services });
    }
    this.setState({ loading: false });
  };

  voidVoucher = async () => {
    this.setState({ approvalLoading: true });
    const data = { ...this.state.payload };

    data.voucherCode = this.state.voucherId;
    if (this.state.voucher.nextAction == "final_processing") {
      data.comments = data.comments || "Funds disbursed";
    }

    const voidPath = !this.state.isApproval && this.state.isVoid ? "void" : "";
    let res = await VoucherService.voidVoucher(data.voucherCode);
    if (res) {
      Toastr("success", "Voucher workflow updated");
      this.setState({ modalOpen: false });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    this.setState({ approvalLoading: false });
  };

  submitAction = async () => {
    this.setState({ approvalLoading: true });

    const data = { ...this.state.payload };
    
    data.documents = this.state.supportingDocuments;

    data.voucherCode = this.state.voucherId;

    //data.comments = this.state.comments;

    if (this.state.voucher.nextAction == "final_processing") {
      data.comments = data.comments || "Funds disbursed";
    }

    const actionPath = this.state.isApproval && !this.state.isVoid ? "approve" : "" || !this.state.isApproval && !this.state.isVoid ? "reject" : "";
    console.log({data:data});

    let response = await this.actionAPI[this.state.voucher.nextAction][
      actionPath
    ](data);

    if (response) {
      Toastr("success", "Voucher workflow updated");
      this.setState({ modalOpen: false });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    this.setState({ approvalLoading: false });
  };

  startApproval() {
    this.setState({ modalOpen: true, isApproval: true, isVoid: false });
  }

  startVoid() {
    this.setState({ modalOpen: true, isVoid: true, isApproval: false });
  }

  startRejection() {
    this.setState({ modalOpen: true, isApproval: false, isVoid: false });
  }
  modalStyle = Constants.defaultModalStyle;


  filesList() {
    return (
      <div className="nk-files nk-files-view-grid">
        <div className="nk-files-list" style={{display:"block"}}>
          {this.state.supportingDocuments.map((file, index) => (
            <div key={index} className="nk-file-item nk-file">
              <div className="nk-file-info">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <a className="nk-file-icon-link" href="#">
                      <span className="nk-file-icon-type">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 72 72"
                        >
                          <path
                            d="M49,61H23a5.0147,5.0147,0,0,1-5-5V16a5.0147,5.0147,0,0,1,5-5H40.9091L54,22.1111V56A5.0147,5.0147,0,0,1,49,61Z"
                            style={{ fill: "#e3edfc" }}
                          />
                          <path
                            d="M54,22.1111H44.1818a3.3034,3.3034,0,0,1-3.2727-3.3333V11s1.8409.2083,6.9545,4.5833C52.8409,20.0972,54,22.1111,54,22.1111Z"
                            style={{ fill: "#b7d0ea" }}
                          />
                          <path
                            d="M19.03,59A4.9835,4.9835,0,0,0,23,61H49a4.9835,4.9835,0,0,0,3.97-2Z"
                            style={{ fill: "#c4dbf2" }}
                          />
                          <rect
                            x={27}
                            y={31}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={35}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={39}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={43}
                            width={14}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                          <rect
                            x={27}
                            y={47}
                            width={8}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: "#7e95c4" }}
                          />
                        </svg> */}
                      </span>
                    </a>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <a href="#" className="title">
                        {file.fileName}
                      </a>
                    </div>
                  </div>
                </div>
                <ul className="nk-file-desc">
                  <li className="size">
                    {Functions.currencyFormat(file.size / 1000)} kb
                  </li>
                </ul>
              </div>
              <div className="nk-file-actions">
                <div className="dropdown">
                  <a
                    href
                    className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-plain no-bdr">
                      <li>
                        <a onClick={() => this.removeSupportingDocument(index)}>
                          <em className="icon ni ni-trash" />
                          <span>Remove file</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  hiddenFileInput = {
    height: "45px",
    width: "130px",
    cursor: "pointer",
    position: "relative",
    opacity: "0",
    left: "-130px",
    top: "-25px",
    fontSize: 0,
  };

  removeSupportingDocument = (index) => {
    const docs = [...this.state.supportingDocuments];
    docs.splice(index, 1);
    this.setState({ supportingDocuments: docs });
  };

  grabUploadedFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    console.log(reader);
    reader.onloadend = () => {
      const supportingDocuments = this.state.supportingDocuments || [];
      const newFile = {
        description: file.name,
        base64String: reader.result.split(",")[1],
        fileName: file.name,
        size: file.size,
      };

      console.log("uploaded file:", newFile);
      if (newFile.size / 1000000 > 1.0) {
        Toastr("error", "File cannot be larger than 1MB");
      } else {
        supportingDocuments.push(newFile);
      }
      this.setState({ supportingDocuments: supportingDocuments });
    };
    reader.readAsDataURL(file);
    console.log({uploadedfiles:this.state.supportingDocuments});
  };



  render() {
    this.modalStyle.content.padding = "0";

    return (
      <MainLayout title="">
        {this.state.modalOpen && (
          <Modal
            isOpen={this.state.modalOpen}
            style={this.modalStyle}
            onRequestClose={() => this.setState({ modalOpen: false })}
          >
            <div style={{ width: "650px" }}>
              <div style={{ overflow: "hidden" }}>
                {" "}
                <div
                  className="row p-3 pb-1"
                  style={{
                    background:
                      this.state.voucher.nextAction != "final_processing"
                        ? "#eee"
                        : "#364a63",
                  }}
                >
                  {this.state.voucher.nextAction != "final_processing" ? (
                    <div className="col-md-12 mb-0">
                      <h4 className="mt-2">
                        {this.state.isApproval && !this.state.isVoid
                          ? "Approve voucher"
                          : ""}
                      </h4>
                      <h4>
                        {!this.state.isApproval && !this.state.isVoid
                          ? "Reject/return voucher"
                          : ""}
                      </h4>
                      <h4>
                        {!this.state.isApproval && this.state.isVoid
                          ? "Void voucher"
                          : ""}
                      </h4>
                      <div>
                        {this.state.isVoid && (
                          <p className="text-danger">
                            You are about to void this voucher.
                          </p>
                        )}
                      </div>
                      {this.state.isApproval && (
                        <p className="text-muted">
                          You are about to approve this voucher. Enter your
                          comments below to proceed
                        </p>
                      )}

                      {!this.state.isApproval && !this.state.isVoid && (
                        <p className="text-danger">
                          You are about to reject this voucher. Enter your
                          comments below
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="col-md-12 mb-0">
                      <h4 className="mt-2 text-white">
                        {this.state.isApproval
                          ? `Process Disbursement for ${this.state.voucher.currency} ${Functions.currencyFormat(this.state.total)}`
                          : "Void voucher"}
                      </h4>
                      {this.state.isApproval && (
                        <p className="text-white">
                          You are about to disburse the funds. Ensure that all
                          post-approval requirements have been met
                        </p>
                      )}
                      {this.state.isVoid && (
                        <p className="text-danger">
                          You are about to void this voucher.
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-3 pt-1 pl-4 pb-4 pr-4">
                {this.state.voucher.nextAction == "final_processing" && !this.state.isVoid && (
                  <div className="row">
                    <div className="col-12">
                      {/* <h3> {this.state.voucher.currency} {Functions.currencyFormat(this.state.total)}</h3> */}
                    </div>
                    <div className="col-12 mb-2">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="form-group">
                              <label className="form-label" htmlFor="approvalMessage"> Confirm paying account? </label>
                              <div className="form-control-wrap">
                                <Select
                                  options={this.state.banks}
                                  onChange={(option) => this.updatePayload("bankAccountId", option.id)}
                                  value={this.state.banks.find((a) => a.id === this.state.payload.disburseFromAccountId)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-label" htmlFor="dateDisbursed"> Disbursement Date: </label>
                              <div className="form-control-wrap">
                                <input style={{ height: "45px" }} onChange={(e) => this.updatePayload('dateDisbursed', e.target.value)} type="date" className="form-control  form-control-md" id="dateDisbursed" name="dateDisbursed" value={this.convertDatetoReadable(this.state.payload.dateDisbursed)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          {/* <label className="form-label" hidden={true}> Disburse funds from this account: </label> */}
                          <h7 className="title mt-1" style={{marginLeft:"10px"}}>
                            <span className="text-danger"> {this.state.disburseAccount?this.state.disburseAccount.accountType : "" } </span> {" "} - {" "}
                            <span className="font-weight-normal"> {this.state.disburseAccount? this.state.disburseAccount.accountNumber : ""}{" "}{this.state.disburseAccount? this.state.disburseAccount.bankName: ""}</span>
                          </h7>
                        </div>
                        {/* <hr /> */}
                      </div>
                    </div>
                  </div>
                )}
                {!this.state.isVoid && (<div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label hidden={this.state.isAccountant? true : false} className="form-label" htmlFor="approvalMessage">
                        Your comments
                      </label>
                      <div className="form-control-wrap mb-3 row">
                        <div className={this.state.isAccountant? "col" : "col"}>
                          <textarea
                          placeholder={this.state.isAccountant? "Your Comments" : ""}
                          style={{ height: "50px", minHeight: "50px"}}
                          className="form-control form-select-lg"
                          onChange={(e) =>
                            this.updatePayload("comments", e.target.value)
                          }
                          />
                        </div>
                        {this.state.voucher.nextAction === "final_processing"?
                        <div className="col-md-4" hidden={this.state.isAccountant? false : true}>
                          <input
                          hidden={this.state.voucher.ledgerCode? true : false}
                          style={{height:"50px"}}
                          placeholder="Ledger Code"
                          className="form-control"
                          onChange={(e) => this.updatePayload("ledgerCode", e.target.value)}
                          ></input>
                          <label hidden={this.state.voucher.ledgerCode? false : true} className="form-label"> Ledger Code </label>
                          <p style={{color:"tomato", marginTop:"-15px", fontSize:"20px", fontWeight:"600"}}>{this.state.voucher.ledgerCode? this.state.voucher.ledgerCode : ""}</p>
                        </div>: <></>}
                        
                      </div>
                    <div hidden={this.state.voucher.nextAction === "final_processing"? false : true}>
                      <hr className="preview-hr mt-2 mb-2" />
                      <span className="preview-title-lg overline-title">
                        Upload proof of payment{" "}
                      </span>{" "}
                      <p>Maximum file size is 1MB</p>
                      <button className="btn btn-dim btn-outline-danger">
                        {this.state.supportingDocuments &&
                        this.state.supportingDocuments.length > 0
                          ? "+ Add another document"
                          : "Add document"}
                      </button>
                      <input
                        accept=".png, .jpg, .jpeg, .docx, .pdf"
                        type="file"
                        id="documentUploader"
                        style={this.hiddenFileInput}
                        title="+ Add supporting document"
                        onChange={(e) => this.grabUploadedFile(e)}
                      />
                      <div className="row gy-4 align-center mb-2" style={{width:"800px", marginBottom:"20px", display:"block"}}>
                        {this.state.supportingDocuments &&
                          this.state.supportingDocuments.length > 0 && (
                            <div className="col-12">{this.filesList()}</div>
                          )}
                      </div>
                    </div>
                    </div>
                  </div>
                </div>

                )}

                {this.state.voucher.nextAction == "accountant_review" &&
                  this.state.isApproval &&
                  !this.state.isVoid && (
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="approvalMessage"
                          >
                            Which account to disburse from?
                          </label>
                          <div className="form-control-wrap">
                            <Select
                              options={this.state.banks}
                              onChange={(option) =>
                                this.updatePayload(
                                  "disburseFromAccountId",
                                  option.id
                                )
                              }
                              value={this.state.banks.find(
                                (a) =>
                                  a.id ==
                                  this.state.payload.disburseFromAccountId
                              )}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label className="form-label">Ledger Code</label>
                            <div className="form-control-wrap">
                              <input
                                style={{ height: "45px" }}
                                type="text"
                                className="form-control "
                                onChange={(e) =>
                                  this.updatePayload(
                                    "ledgerCode",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="col-md-6"
                            style={{
                              alignSelf: "baseline",
                              height: "fit-content",
                              marginTop: "auto",
                              marginBottom: "4px",
                            }}
                          >
                            <div style={{ display: "inline-flex" }}>
                              <input
                                onChange={()=>this.checkBox()}
                                id="checker" 
                                className="inputCheck" 
                                type="checkbox" 
                                style={{marginRight:"5px", width:"20px", height:"20px", border:"2px solid red", accentColor:"#9ED029", cursor:"pointer"}}></input>
                              <label style={{marginBottom:"0px"}} className="form-label">Select for 2nd approver level only</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {!this.state.isVoid ? 
                  <div className="row">
                    <div className="col-md-12">
                      <div className="" style={{float:"right"}}>
                        <div className="text-left ">
                          {this.state.approvalLoading ? (
                            <Spinner />
                          ) : (
                            <button
                              onClick={() => this.submitAction()}
                              type="button"
                              className={`btn btn-lg btn-block btn-${
                                this.state.isApproval ? "primary" : "dark"
                              } px-5`}
                            >
                              {this.state.voucher.nextAction ==
                                "final_processing" && this.state.isApproval
                                ? "Disburse funds"
                                : ""}
                              {!this.state.isApproval ? "Reject Voucher" : ""}
                              {this.state.voucher.nextAction ==
                                "accountant_review" && this.state.isApproval
                                ? "Approve Voucher"
                                : ""}{" "}
                              {this.state.voucher.nextAction ==
                                "first_approval" && this.state.isApproval
                                ? "Approve Voucher"
                                : ""}{" "}
                              {this.state.voucher.nextAction ==
                                "second_approval" && this.state.isApproval
                                ? "Approve Voucher"
                                : ""}{" "}
                            </button>
                          )}
                        </div>
                        {/* <div className="col-md-6 text-right">
                          <button
                            onClick={() => this.setState({ modalOpen: false })}
                            type="button"
                            className="btn btn-link text-dark"
                          >
                            <strong className="text-danger mr-3">x</strong>{" "}
                            Cancel
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div> :
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 text-left ">
                          {this.state.approvalLoading ? (
                            <Spinner />
                          ) : (
                            <button
                              onClick={() => this.voidVoucher()}
                              type="button"
                              className={`btn btn-lg btn-block btn-${
                                this.state.isApproval ? "primary" : "dark"
                              } px-5`}
                            >
                              {!this.state.isApproval ? "Void Voucher" : ""}
                            </button>
                          )}
                        </div>
                        <div className="col-md-6 text-right">
                          <button
                            onClick={() => this.setState({ modalOpen: false })}
                            type="button"
                            className="btn btn-link text-dark"
                          >
                            <strong className="text-danger mr-3">x</strong>{" "}
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </Modal>
        )}
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="col">
            <div className="nk-content ">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-block-head">
                      <div className="nk-block-between g-3">
                        <div className="nk-block-head-content">
                          <h3 className="nk-block-title page-title">
                            Voucher{" "}
                            <strong className="text-primary small">
                              {/* #{this.state.voucher.voucherCode} */}#
                              {this.state.voucher.trackingCode}
                            </strong>{" "}
                            {/* {this.state.voucher.worflowTrail[1].personName} */}
                            {/* {VoucherStatus(this.state.voucher)}{" "} */}
                          </h3>
                          <div className="nk-block-des text-soft">
                            <ul className="list-inline">
                              <li>
                                Date raised:{" "}
                                <span className="text-base">
                                  {new Date(
                                    this.state.voucher.dateCreated
                                  ).toDateString()}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="nk-block-head-content">
                          <button
                            onClick={() => window.print()}
                            className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                          >
                            <em className="icon ni ni-printer" />
                            <span>Print</span>
                          </button>
                          <Link
                            to="/vouchers"
                            className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                          >
                            <em className="icon ni ni-arrow-left" />
                            <span>Back</span>
                          </Link>
                          <Link
                            to="/vouchers"
                            className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                          >
                            <em className="icon ni ni-arrow-left" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* .nk-block-head */}
                    <div className="nk-block">
                      {/* {this.state.voucher.nextAction && (
                        <div
                          className={`alert alert-${
                            Functions.voucherStatusThemes[
                              this.state.voucher.status
                            ]
                          } alert-icon mb-0`}
                        >
                          <em class="icon ni ni-alert-circle"></em> The next
                          activity required for this voucher is{" "}
                          <strong>
                            {this.state.voucher.nextAction
                              .toUpperCase()
                              .replace("_", " ")}
                          </strong>
                          .
                        </div>
                      )} */}
                      <div className="invoice">
                        <div className="invoice-action">
                          {/* <Link className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary" to="#" target="_blank"><em className="icon ni ni-printer-fill" /></Link> */}
                        </div>
                        {/* .invoice-actions */}
                        <div className="invoice-wrap printme">
                          <div className="row" style={{display:"flex", justifyContent:"space-between"}}>
                            <div className="invoice-brand text-left">
                              <Logo />
                            </div>
                            <div style={{position:"relative", textAlign:"center", color:"white"}}>
                              <img width="125px" src={this.state.voucher.voucherType === "requisition"? "/images/banner-blue.jpg" : "/images/banner-green.jpg" }/>
                              <div style={{
                                            position:"absolute", 
                                            top:"40%", 
                                            left:this.state.voucher.voucherType === "requisition"? "26%" : "32%", 
                                            fontSize:"10px", 
                                            fontWeight:"600"}}>
                                {this.state.voucher.voucherType.toUpperCase()}
                              </div>
                            </div>
                          </div>
                          {this.state.voucher.nextAction &&
                            this.state.voucher.voided == false && (
                              <div
                                className={`alert alert-${
                                  Functions.voucherStatusThemes[
                                    this.state.voucher.status
                                  ]
                                } alert-icon mb-0`}
                              >
                                <em className="icon ni ni-alert-circle"></em>{" "}
                                The next activity required for this voucher is{" "}
                                <strong>
                                  {this.state.voucher.nextAction
                                    .toUpperCase()
                                    .replace("_", " ")}
                                </strong>
                                .
                              </div>
                            )}
                          {this.state.voucher.nextAction &&
                            this.state.voucher.voided == true && (
                              <div
                                className={`alert alert-${
                                  Functions.voucherStatusThemes[
                                    this.state.voucher.voided
                                  ]
                                } alert-icon mb-0`}
                              >
                                <em className="icon ni ni-alert-circle"></em>{" "}
                                The voucher has been voided.
                              </div>
                            )}
                          <div className="invoice-head mt-5">
                            <div className="invoice-contact">
                              <span className="overline-title">Raised by</span>
                              <div className="invoice-contact-info">
                                <h4 className="title">
                                  {this.state.voucher.raisedBy}
                                </h4>
                              </div>
                              {this.state.disburseAccount && (
                                <>
                                  <span className="overline-title">
                                    Funds{" "}
                                    {this.state.voucher.nextAction &&
                                      "will be "}{" "}
                                    disbursed from
                                  </span>
                                  <div className="invoice-contact-info">
                                    <h5 className="title">
                                      <span className="text-danger">
                                        {this.state.disburseAccount.accountType}
                                      </span>{" "}
                                      -{" "}
                                      <span className="font-weight-normal">
                                        {
                                          this.state.disburseAccount
                                            .accountNumber
                                        }{" "}
                                        {this.state.disburseAccount.bankName}
                                      </span>
                                    </h5>
                                  </div>
                                  <h6>
                                    <span className="text-bold mr-2">
                                      Approved by :
                                    </span>
                                    <span className="mr-2">
                                      {(this.state.voucher.nextAction == "" &&
                                        this.state.voucher.worflowTrail[
                                          this.state.voucher.worflowTrail
                                            .length - 2
                                        ].personName) ||
                                        (this.state.voucher.worflowTrail
                                          .length &&
                                          this.state.voucher.worflowTrail[
                                            this.state.voucher.worflowTrail
                                              .length - 1
                                          ].personName)}
                                    </span>
                                    {VoucherStatus(this.state.voucher)}{" "}
                                  </h6>
                                </>
                              )}
                            </div>
                            <div className="invoice-desc">
                              <ul className="list-plain">
                                <li className="invoice-id">
                                  <span>Ledger Code</span>:
                                  <span>{this.state.voucher.ledgerCode}</span>
                                </li>
                                <li className="invoice-id">
                                  <span>Beneficiary Name :</span>
                                  <br />
                                  <span className="padding">
                                    {this.state.voucher.beneficiaryName}
                                  </span>
                                </li>
                                <li className="invoice-date">
                                  <span>Date raised</span>:
                                  <span>
                                    {Functions.dateForm(
                                      this.state.voucher.dateCreated
                                    )}
                                  </span>
                                </li>
                                {this.state.voucher.dateDisbursed && (
                                  <li className="invoice-date">
                                    <span>Date disbursed</span>:
                                    <span>
                                      {Functions.dateForm(
                                        this.state.voucher.dateDisbursed
                                      )}
                                    </span>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                          {/* .invoice-head */}
                          <div className="invoice-bills mt-3">
                            <div
                              className="table-responsive"
                              style={{ overflow: "unset" }}
                            >
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th className="w-150px">
                                      {this.state?.voucher?.voucherType ===
                                      "voucher"
                                        ? "Client Name"
                                        : "Voucher Code"}
                                    </th>
                                    <th className="">Description</th>
                                    <th>Bank Account</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.voucherItems.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td>
                                          {this.state?.voucher?.voucherType ===
                                          "voucher" ? (
                                            <Link
                                              to={`/invoice?ref=${item?.invoice?.invoiceNumber}`}
                                              target="_blank"
                                              className="voucher-link"
                                            >
                                              {item?.customer?.firstName}{" "}
                                              {item?.customer?.lastName}
                                            </Link>
                                          ) : (
                                            this.state.voucher.trackingCode
                                          )}
                                        </td>
                                        <td>{item.description}</td>
                                        <td>
                                          {}
                                        {this.state.voucher.voucherType === "voucher" ? "CARD" :
                                        (item.settlementBankCode &&
                                            this.state.realBanks?.find(
                                              (a) =>
                                                a.bankCode ==
                                                item.settlementBankCode
                                            ).bankName) ||
                                            "CASH"}{" "}
                                          {item.settlementBankCode && "-"}{" "}
                                          {item.settlementBankAccount}
                                        </td>
                                        <td>
                                          {this.state.voucher.currency}
                                          {Functions.currencyFormat(
                                            item.amount
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                                <tfoot>
                                  {null && (
                                    <tr>
                                      <td colSpan={2} />
                                      <td colSpan={2}>Subtotal</td>
                                      <td>
                                        {this.state.invoice.currency}
                                        {this.state.invoice.amount.toLocaleString()}
                                      </td>
                                    </tr>
                                  )}

                                  {null && this.state.invoice.discount && (
                                    <tr>
                                      <td colSpan={2} />
                                      <td colSpan={2}>Discount</td>
                                      <td>
                                        {this.state.invoice.currency}
                                        {this.state.invoice.discount.toLocaleString()}
                                      </td>
                                    </tr>
                                  )}
                                  {null && (
                                    <tr>
                                      <td colSpan={2} />
                                      <td colSpan={2}>VAT</td>
                                      <td>
                                        {this.state.invoice.currency}
                                        {this.state.vat.toLocaleString()}
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td colSpan={1} />
                                    <td colSpan={2} className="text-right">
                                      Grand Total
                                    </td>
                                    <td>
                                      {FormatMoney(
                                        this.state.total,
                                        this.state.voucher.currency
                                      )}
                                      {/* {this.state.voucher.currency}
                                      {this.state.total.toLocaleString()} */}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <div>
                                <div className="mb-3">
                                  <h6>List of Approval(s)</h6>
                                  {this.state.voucher.worflowTrail.map(
                                    (item, index) => (
                                      <>
                                        {item.actionDescription !== "" ? (
                                          <>
                                            <p
                                              key={index}
                                              className="text-bold mr-2"
                                            >
                                              <span
                                                className="pr-2"
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {item.personName}:
                                              </span>

                                              <span className="mr-2">
                                                {item.actionDescription}
                                              </span>
                                            </p>
                                            <p>
                                              {VoucherStatus(
                                                item.actionDescription
                                              )}
                                            </p>
                                          </>
                                        ) : null}
                                      </>
                                    )
                                  )}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col col-lg-8">
                                  <span className="overline-title">
                                    Attached document(s)
                                  </span>
                                  {this.state.voucher.documents && (
                                    <UploadedFiles
                                      files={this.state.voucher.documents}
                                    />
                                  )}
                                </div>
                                {this.state.voucher.nextAction &&
                                  this.roleActions[this.state.role] &&
                                  this.roleActions[this.state.role].includes(
                                    this.state.voucher.nextAction
                                  ) && (
                                    <div className="col col-lg-4">
                                      <div className="card ">
                                        <div className="card-inner text-right">
                                          <h6 className="card-subtitle mb-2 ff-base small">
                                            {this.state.voucher.nextAction
                                              .toUpperCase()
                                              .replace("_", " ")}
                                          </h6>
                                          {this.state.role == "owner" ? (
                                            <Link
                                              className="btn btn-outline-primary my-3 btn-lg"
                                              to={
                                                "/voucher-review/" +
                                                this.state.voucher.voucherCode
                                              }
                                            >
                                              Respond to query
                                            </Link>
                                          ) : (
                                            <>
                                              <button
                                                onClick={() =>
                                                  this.startApproval()
                                                }
                                                type="button"
                                                className="btn btn-outline-primary my-3 btn-lg"
                                              >
                                                {this.state.voucher
                                                  .nextAction !=
                                                "final_processing"
                                                  ? "Approve Voucher"
                                                  : "Disburse funds"}
                                              </button>
                                              {this.state.voucher.nextAction !=
                                                "final_processing" && (
                                                <button
                                                  onClick={() =>
                                                    this.startRejection()
                                                  }
                                                  type="button"
                                                  className="btn btn-danger my-3 btn-lg ml-3"
                                                >
                                                  Reject Voucher
                                                </button>
                                              )}
                                              {this.state.voucher.voided ===
                                                false &&
                                                this.state.role ==
                                                  "accountant" && (
                                                  <button
                                                    onClick={() =>
                                                      this.startVoid()
                                                    }
                                                    type="button"
                                                    className="btn btn-light my-3 btn-lg ml-3"
                                                  >
                                                    Void Voucher
                                                  </button>
                                                )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>

                              <div>
                                <div
                                  className="card-inner text-right"
                                  style={{
                                    marginTop: "-46px",
                                    paddingTop: "-20px",
                                  }}
                                >
                                  {this.state.voucher.nextAction ==
                                    "final_processing" &&
                                    this.state.voucher.voided === false &&
                                    this.state.role == "accountant" &&
                                    this.state.role == "owner" && (
                                      <button
                                        onClick={() => this.startApproval()}
                                        type="button"
                                        className="btn btn-outline-primary my-3 btn-lg"
                                      >
                                        Disburse funds
                                      </button>
                                    )}
                                </div>
                              </div>

                              <div>
                                <div
                                  className="card-inner text-right"
                                  style={{ marginTop: "-46px" }}
                                >
                                  {this.state.role == "owner" &&
                                    this.state.role == "accountant" &&
                                    this.state.voucher.voided === false && (
                                      <button
                                        onClick={() => this.startVoid()}
                                        type="button"
                                        className="btn btn-light my-3 btn-lg ml-3"
                                      >
                                        Void Voucher
                                      </button>
                                    )}
                                </div>
                              </div>

                              <div className="nk-notes ff-italic fs-12px text-soft">
                                {" "}
                                {/* //{" "} */}
                              </div>
                            </div>
                          </div>
                          {/* .invoice-bills */}
                        </div>
                        {/* .invoice */}
                        <div className="nk-msg no-printme">
                          <div
                            className="nk-msg-body bg-white profile-shown"
                            style={{ zIndex: "unset" }}
                          >
                            <div className="nk-msg-head"></div>
                            {/* .nk-msg-head */}
                            <div
                              className="nk-msg-reply nk-reply"
                              data-simplebar
                            >
                              <div className="nk-msg-head py-4 d-lg-none">
                                <h4 className="title">
                                  Unable to select currency when order
                                </h4>
                                <ul className="nk-msg-tags">
                                  <li>
                                    <span className="label-tag">
                                      <em className="icon ni ni-flag-fill" />{" "}
                                      <span>Technical Problem</span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                {this.state.voucher.worflowTrail &&
                                  this.state.voucher.worflowTrail.map(
                                    (workflow, index) => 
                                       (
                                      <div
                                        key={index}
                                        className="nk-reply-item"
                                      >
                                        <div className="nk-reply-header">
                                          <div className="user-card">
                                            <div className="user-avatar sm bg-pink">
                                              <span>
                                                {this.GetFirstLetter(
                                                  workflow.personName
                                                )}
                                              </span>
                                            </div>
                                            <div className="user-name">
                                              {workflow.personName}
                                            </div>
                                            <div className="date-time ml-3">
                                              {new Date(
                                                workflow.dateCreated
                                              ).toDateString()}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-reply-body">
                                          <div className="nk-reply-entry entry">
                                            <p>
                                              <span className="badge badge-secondary mr-2">
                                                {workflow.actionType}
                                              </span>
                                              {workflow.comments}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                            {/* .nk-reply */}
                          </div>
                          {/* .nk-msg-body */}
                        </div>
                      </div>
                      {/* .nk-block */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    );
  }
}
