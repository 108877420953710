import React, { useState } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import "../../sass/invoice.css";
import Spinner from "../../Utils/Spinner";
import Toastr from "../../Utils/Toastr";
import VoucherService from "../../services/VoucherService";
import SingleCardVoucher from "../../Components/SingleCardVoucher";
import FileList from "../../Components/FileList";
import Select from "react-select";

const newVoucher = {
  amount: "",
  description: "",
  invoiceId: "",
};

const hiddenFileInput = {
  height: "45px",
  width: "130px",
  cursor: "pointer",
  position: "relative",
  opacity: "0",
  left: "-130px",
  top: "-25px",
  fontSize: 0,
};

const currencies = [
  { label: "NGN", value: "NGN" },
  { label: "USD", value: "USD" },
];

const CardPayment = () => {
  const [voucherItems, setvoucherItems] = useState([newVoucher]);
  const [loading, setLoading] = useState(false);
  const [supportingDocuments, setSupportingDocuments] = useState([]);
  const [data, setData] = useState({ beneficiaryName: "", currency: "" });

  const AddVoucherItem = () => {
    const newVoucherItem = [...voucherItems, newVoucher];
    setvoucherItems(newVoucherItem);
  };

  const RemoveVoucherItem = (index) => {
    let data = voucherItems.filter((item, idx) => idx !== index);
    setvoucherItems(data);
  };

  const handleItemChange = (field, value, index) => {
    let items = voucherItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, [field]: value };
      } else return item;
    });
    setvoucherItems(items);
  };

  const grabUploadedFile = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onloadend = () => {
      const newFile = {
        description: file.name,
        base64String: reader.result.split(",")[1],
        fileName: file.name,
        size: file.size,
      };

      if (newFile.size / 1000000 > 1.0) {
        Toastr("error", "File cannot be larger than 1MB");
      } else {
        setSupportingDocuments((prevDocs) => {
          return [newFile, ...prevDocs];
        });
      }
    };
    reader.readAsDataURL(file);
  };

  const raiseVoucher = async (e) => {
    e.preventDefault();

    let payload = data;
    if (!payload.beneficiaryName) {
      Toastr("error", "Beneficiary name is required");
      setLoading(false);
      return;
    }

    if (!payload.currency) {
      Toastr("error", "Currency is required");
      setLoading(false);
      return;
    }

    let validItems =
      voucherItems.filter(
        (obj) => obj.amount && parseFloat(obj.amount.toString()) > 0
      ) || [];

    if (validItems.length === 0) {
      Toastr("error", "You must add at least one item");
      this.setState({ loading: false });
      return;
    }

    let isValid = validItems.every((a) => a.description.trim() !== "");

    if (!isValid) {
      Toastr(
        "error",
        "There were errors with your requisition. description is required"
      );
      return;
    }

    payload.lineItems = validItems;
    payload.documents = supportingDocuments || [];
    payload.voucherType = "voucher";

    setLoading(true);
    let response = await VoucherService.raiseCardVoucher(payload);
    if (response) {
      Toastr("success", "Your voucher is successfully raised.");
      console.log(response);
      setTimeout(() => {
        window.location.href = `/voucher/${response.voucherCode}`;
      }, 2000);
    }
    setLoading(false);
  };

  const removeDocument = (index) => {
    const newDocuments = supportingDocuments.filter((_, idx) => idx !== index);
    setSupportingDocuments(newDocuments);
  };

  return (
    <MainLayout title={"Card Payment"}>
      <div className="col">
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered">
            <div className="card-inner">
              <form onSubmit={async (e) => await raiseVoucher(e)}>
                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="currency">
                        Currency
                      </label>
                      <div className="form-control-wrap ">
                        <Select
                          onChange={(option) => {
                            console.log(option);
                            setData({
                              ...data,
                              currency: option.value,
                            });
                          }}
                          options={currencies}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="currency">
                        Beneficiary Name
                        <small className="text-danger">*</small>
                      </label>
                      <div className="form-control-wrap ">
                        <input
                          minLength={10}
                          // value={beneficiaryName}
                          type="text"
                          onChange={(e) =>
                            setData({
                              ...data,
                              beneficiaryName: e.target.value,
                            })
                          }
                          className="form-control form-control-lg"
                          placeholder="Type Beneficiary Name"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <span
                      className="preview-title-lg overline-title"
                      style={{ marginTop: "50px" }}
                    >
                      Voucher Items{" "}
                      <hr
                        className="preview-hr"
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      />
                    </span>

                    <div className="row gy-4 align-center">
                      {voucherItems.map((item, index) => (
                        <SingleCardVoucher
                          item={item}
                          index={index}
                          voucherItems={voucherItems}
                          RemoveVoucherItem={RemoveVoucherItem}
                          handleItemChange={handleItemChange}
                          key={index}
                        />
                      ))}

                      {
                        <div className="col-md-2 pr-0">
                          <div className="form-group">
                            <div className="form-control-wrap mt-0">
                              <button
                                onClick={() => AddVoucherItem()}
                                type="button"
                                className="mt-2 btn btn-link text-dark fix-add-vouch"
                              >
                                <em className="icon ni mr-3 ni-plus-c"></em> Add
                                voucher item
                              </button>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <div>
                    <hr className="preview-hr" />
                    <span className="preview-title-lg overline-title">
                      Upload Supporting Documents{" "}
                    </span>{" "}
                    <p>Maximum file size is 1MB</p>
                    <button className="btn btn-dim btn-outline-danger">
                      {supportingDocuments && supportingDocuments.length > 0
                        ? "+ Add another document"
                        : "Add document"}
                    </button>
                    <input
                      accept=".png, .jpg, .jpeg, .docx, .pdf"
                      type="file"
                      id="documentUploader"
                      style={hiddenFileInput}
                      title="+ Add supporting document"
                      onChange={(e) => grabUploadedFile(e)}
                    />
                    <div className="row gy-4 align-center">
                      <div className="col-12">
                        <FileList
                          documents={supportingDocuments}
                          removeDoc={removeDocument}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gy-4 align-center">
                    <div className="col-lg-6 mt-5">
                      <div className="form-group">
                        <label
                          className="form-label buttonLabel"
                          htmlFor="Button"
                        ></label>
                        <div className="form-control-wrap">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              {loading ? (
                                <Spinner />
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-block  btn-primary fix-inv-btn "
                                >
                                  Submit Voucher
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CardPayment;
