import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/mystyling.css";
import CategoriesService from "../../services/CategoriesService";
import Toastr from "../../Utils/Toastr";
import ServicesService from './../../services/ServicesService';
import BanksService from "../../services/BanksService";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";
import Select from "react-select";
import "./service.scss"

export default class NewService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        categoryId: 0,
        bankAccountId: 0,
        defaultAmount: 0,
        isActivestatus: true
      },
      payload:{},
      categories: [],
      bankAccount: [],
      currencies: [
        { value: "Naira", label: "Naira" },
        { value: "USD", label: "USD" },
        ]
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    let categoryOptions = [];
    const categories = await CategoriesService.listCategories();
    if (categories) {
      categories.forEach(element => {
        categoryOptions.push({ value: element.id, label: element.categoryname })
      });
      this.setState({ categories: categoryOptions });
    }
    let accountOptions = [];
    const bankAccount = await BanksService.listBankAccount();
    console.log({bank:bankAccount});
    if (bankAccount) {
      bankAccount.forEach(element => {
        accountOptions.push({ value: element.id, label: `${element.accountNumber} - ${element.bankName}`, curr: element.currency });
      });
      this.setState({ bankAccount: accountOptions });
    }
    this.setState({ loading: false });

  }

  updatePayload=(payloadArray)=>{
    const tempPayload = {...this.state.payload};
    payloadArray.forEach((el)=>{
        tempPayload[el.key] = el.data;
    });
    console.log({tempPayload:tempPayload});
    this.setState({payload: tempPayload});
    console.log(this.state.payload)
   
 }

  // getCurrency=async()=>{
  //   const bankAccount = await BanksService.listBankAccount();
  //   if(bankAccount.length > 0){
  //     bankAccount.forEach(bank=>{
  //       if(bank.id)
  //     })
  //   }
    

  // }

  submit = async () => {
    this.setState({ loading: true });

    const payloadWithStatus = { ...this.state.payload, isActivestatus: true };

    const response = await ServicesService.add(this.state.payload);
    if (response) {
      Toastr("success", "Service added successful");
    }
    setTimeout(function(){
      window.location.href = "/services";
   }, 2000);
    this.setState({ loading: false });
  }

  // handleServiceChange = ({ currentTarget: input }) => {
  //   if (input) {
  //     const data = { ...this.state.data };
  //     data[input.name] = input.value;

  //     this.setState({ data: data });
  //   }
  
  // handleCategoryChange = (category) => {
  //   if (category) {
  //     const data = { ...this.state.data };
  //     data.categoryId = category.value;
  //     this.setState({ data: data });
  //   }
  // }

  // handleBankChange = (bank) => {
  //   if (bank) {
  //     const data = { ...this.state.data };
  //     data.bankAccountId = bank.value;
  //     this.setState({ data: data });
  //   }
  // }



  render() {
    return (
      <MainLayout title="Add New Service">
        <div className="services col">
          <div className="card card-bordered col-md-8">
            <div className="card-inner">
              <form action="#">
                <div className="row g-4">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="name">
                        Service Name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="name" name="name"
                          onChange={(e)=>this.updatePayload([{data: e.target.value, key: 'name'}])}
                          //onChange={this.handleServiceChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="categoryId">
                        Category
                      </label>
                      <div className="form-control-wrap">
                        <Select onChange={(option)=>this.updatePayload([{data: option.value, key: 'categoryId'}])} name="categoryId"
                          id="categoryId"
                          options={this.state.categories} />

                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="bankAccountId">
                        Settlement Bank
                      </label>
                      <div className="form-control-wrap">
                        <Select onChange={(option)=>this.updatePayload([{data: option.value, key: 'bankAccountId'}, {data: option.curr, key: 'currency'} ])} name="bankAccountId"
                          id="bankAccountId"
                          options={this.state.bankAccount} />


                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="name">
                        Default price - {this.state.payload.currency? this.state.payload.currency: "Select bank for currency"}
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="number"
                          className="form-control form-control-lg"
                          id="price" name="price"
                          onChange={(e)=>this.updatePayload([{data: parseFloat(e.target.value), key: 'defaultAmount'}])}/>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-6">
                          {this.state.loading ? <Spinner /> : <button
                            onClick={() => this.submit()}
                            type="button"
                            className="btn btn-lg btn-block btn-primary mr-3"
                          >
                            Add Service
                          </button>}
                        </div>
                        <div className="col-md-6">
                          <Link
                            to="/services"
                            className="btn btn-lg btn-dark btn-block"
                          >
                            <em className="icon ni ni-chevrons-left"></em> Back to list
                          </Link>
                        </div>
                      </div>


                    </div>
                  </div>




                </div>
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
