export const FormatMoney = (number, symbol = null) => {
    number = !number ? 0.00 : number;
    if (symbol === "NGN") {
        return '₦' + (parseFloat(number)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
    }if (symbol ===  "USD"){
        return '$' + (parseFloat(number)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
    }


    
    
    
}