import APICall from "../Utils/APICall";

export default class UsersService {
  static listUsers = async (role, page = 1, pageSize = 100) => {
    return await APICall(`/api/Users/fetch/${pageSize}/${page}/${role}`, "GET");
  };

  static listVoucherByUser = async (
    page = 1,
    pageSize = 100,
    status,
    id,
    name
  ) => {
    return await APICall(
      `/api/Invoice/List-bycustomer?page=${page}&pageSize=${pageSize}&invoiceStatus=${status}&customerId=${id}&paidOnly=true`,
      "GET"
    );
  };

  static create = async (payload) => {
    return await APICall("/api/Users/create", "POST", payload);
  };
  static search = async (search, role, page = 1, pageSize = 20) => {
    return await APICall(
      `/api/Users/fetch/${pageSize}/${page}/${role}?query=${search}`,
      "GET"
    );
  };
  static Login = async (payload) => {
    return await APICall("/api/Users/Login", "POST", payload);
  };
  static Logout = async () => {
    return await APICall("/api/Users/Logout", "POST", {});
  };
  static ResetPassword = async (payload) => {
    return await APICall("/api/Users/ResetPassword", "POST", payload);
  };
  static changepassword = async (payload) => {
    return await APICall("/api/Users/changepassword", "POST", payload);
  };
  static setnewpassword = async (payload) => {
    return await APICall("/api/Users/setnewpassword", "POST", payload);
  };
  static Get = async (userId) => {
    return await APICall(`/api/Users/Get/${userId}`, "GET");
  };

  static ToggleActive = async (userId) => {
    return await APICall(`/api/Users/ToggleActive/${userId}`, "POST", {});
  };
  static update = async (payload) => {
    return await APICall("/api/Users/update", "POST", payload);
  };

  static getSettingsItems = async () => {
    return await APICall("/api/AppSettings/list", "GET");
  };

  static postSettings = async (payload) => {
    return await APICall("/api/AppSettings/update", "POST", payload);
  };

  static getNotiications = async () => {
    return await APICall("/api/Notiications/get-latest", "GET");
  };

  static getNotiiList = async (page = 1, pageSize = 5) => {
    return await APICall(`/api/Notiications/list/${page}/${pageSize}?`, "GET");
  };
}
