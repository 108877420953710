import React from "react";
import accountant from "./accountant";
import staff from "./staff";
import admin from "./admin";
import externalauditor from "./auditor";

const Menus = {
  accountant,
  staff,
  admin,
  externalauditor,
  firstlevelauthorizer: [
    ...staff,
    { label: "Requisition Approvals", icon: "fa fa-check", url: "/approvals" },
  ],
  secondlevelauthorizer: [
    ...staff,
    { label: "Requisition Approvals", icon: "fa fa-check", url: "/approvals" },
  ],
};
export default Menus;
