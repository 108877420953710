import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/mystyling.css";
import Spinner from "../../Utils/Spinner";
import { Link } from "react-router-dom";
import CategoriesService from "../../services/CategoriesService";
import BanksService from "../../services/BanksService";
import ServicesService from './../../services/ServicesService';
import Toastr from "../../Utils/Toastr";
import Select from "react-select";
import Functions from "../../Utils/Functions";
import { parse } from "date-fns";

export default class ServiceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                name: "",
                categoryId: 0,
                bankAccountId: 0,
            },
            id: "",
            categories: [],
            bankAccount: [],
            serviceDetail: [],
            serviceId: 0,
            serviceAmount: '',
            currentBank: '',
            payload: {},
            currency: "",
            isActive: false,
        };
    }

    componentDidMount = async () => {
        this.setState({ loading: true });
        let categoryOptions = [];
        const categories = await CategoriesService.listCategories();
        if (categories) {
            categories.forEach(element => {
                categoryOptions.push({ value: element.id, label: element.categoryname })
            });
            this.setState({ categories: categoryOptions });
        }

        const bankAccount = await BanksService.listBankAccount();
        let accountOptions = [];
        if (bankAccount) {
            bankAccount.forEach(element => {
                accountOptions.push({ value: element.id, label: `${element.accountNumber} - ${element.bankName}`, curr: element.currency });
            });
            this.setState({ bankAccount: accountOptions });
        }

        let serviceId = Functions.GetPathUrl(window.location.pathname);
        this.setState({ serviceId: serviceId });
        await this.getService(serviceId);

        const services = await ServicesService.listServices();
        this.setState({ loading: false });
        console.log({services:services});
        if (services?.length > 0){
            services.forEach(servi => {
                if(JSON.stringify(servi.id) === serviceId){
                    this.setState({serviceAmount: JSON.stringify(servi.amount)});
                    this.updatePayload([{data:parseFloat(servi.amount), key:"defaultAmount"}]);
                    this.setState({currentBank:JSON.stringify(servi.settlementBank.id)});
                    this.setState({currency:servi.currency});
                    this.setState({isActive: servi.isActive}); // Set the initial active status
                }
            })
        }
        console.log({amount:this.state.serviceAmount});
    }

    getService = async (id) => {
        console.log({ID:id});

        let serviceList = [];
        serviceList = await ServicesService.listServices();

        if (serviceList && serviceList.length > 0) {
            let neededService = serviceList.find(obj => obj.id === parseInt(id));
            let data = { ...this.state.data };
            data.name = neededService.servicename;
            data.categoryId = neededService.categoryId;
            data.bankAccountId = neededService.settlementBank?.id;
            this.setState({ data: data });
            this.updatePayload([{data:neededService.settlementBank?.id, key:"bankAccountId"},{data:neededService.servicename, key:"name"}]);
        }
    }

    serviceUpdate = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const service = await ServicesService.update(this.state.serviceId, this.state.payload);
        if (service) {
            if (this.state.isActive) {
                await this.toggleStatus();
            }
            Toastr("success", "Service updated successfully");
            setTimeout(() => {
                window.location.href = "/services";
            }, 2000);
        } else {
            Toastr("error", "Update failed");
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }

    updatePayload = (payloadArray) => {
        const tempPayload = {...this.state.payload};
        payloadArray.forEach((el) => {
            tempPayload[el.key] = el.data;
        });
        console.log({tempPayload:tempPayload});
        this.setState({payload: tempPayload});
        console.log(this.state.payload);
    }

    handleBankChange = (bank) => {
        console.log(bank);
        if (bank) {
            const data = { ...this.state.data };
            data.bankAccountId = bank.value;
            this.setState({ data: data });
            this.updatePayload([{data:bank.value, key: "bankAccountId"}]);
        }
    }
toggleStatus = async () => {
    this.setState({ loading: true });
    const updatedService = await ServicesService.updateStatus(this.state.serviceId, !this.state.isActive);
    if (updatedService) {
        this.setState({ isActive: updatedService.isActive });
        Toastr("success", `Service ${updatedService.isActive ? 'activated' : 'deactivated'} successfully`);
    } else {
        Toastr("error", "Status toggle failed");
    }
    this.setState({ loading: false });
}

    render() {
        return (
            <MainLayout title="Service Details">
                <div className="col">
                    <div className="card card-bordered col-md-8">
                        <div className="card-inner">
                            <form action="" onSubmit={async (e) => await this.serviceUpdate(e)}>
                                <div className="row g-4">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="name">Service Name</label>
                                            <div className="form-control-wrap" style={{display:"flex"}}>
                                                {this.state.loading? <input  className="form-control form-select-lg"/>:
                                                <input
                                                    className="form-control form-select-lg"
                                                    name="name" id="name"
                                                    defaultValue={this.state.data.name}
                                                    onChange={(e) => this.updatePayload([{data: e.target.value, key: "name"}])}
                                                    style={{borderTopRightRadius:"0px", borderBottomRightRadius:"0px", borderRight:"0px"}}
                                                />}
                                                {this.state.loading? <div />:
                                                <div className=""
                                                    style={{
                                                        color: "white",
                                                        backgroundColor: this.state.isActive? "#1ee0ac" : "tomato",
                                                        borderTop: this.state.isActive? "1px solid #1ee0ac" : "1px solid tomato",
                                                        borderRight: this.state.isActive? "1px solid #1ee0ac" : "1px solid tomato",
                                                        borderBottom: this.state.isActive? "1px solid #1ee0ac" : "1px solid tomato",
                                                        alignSelf:"center",
                                                        textAlign:"center",
                                                        fontSize:"15px",
                                                        fontWeight:"600",
                                                        padding:"10px",
                                                        height:"44px",
                                                        borderTopRightRadius:"3px",
                                                        borderBottomRightRadius:"3px"
                                                    }}>{this.state.isActive? "Active" : "Inactive"}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="category">Category</label>
                                            <div className="form-control-wrap">
                                                <Select
                                                    options={this.state.categories}
                                                    onChange={(selectedOption) => this.updatePayload([{data: selectedOption.value, key: "categoryId"}])}
                                                    value={this.state.categories.find(option => option.value === this.state.data.categoryId)}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="bankAccount">Bank Account</label>
                                            <div className="form-control-wrap">
                                                <Select
                                                    options={this.state.bankAccount}
                                                    onChange={(options)=>{this.handleBankChange(options);this.setState({currency:options.curr});}}
                                                    value={this.state.bankAccount.find(option => option.value === this.state.data.bankAccountId)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="amount">Default Amount - {this.state.currency}</label>
                                            <div className="form-control-wrap">
                                                <input
                                                    className="form-control form-select-lg"
                                                    name="amount" id="amount"
                                                    defaultValue={this.state.serviceAmount}
                                                    onChange={(e) => this.updatePayload([{data: parseFloat(e.target.value), key: "defaultAmount"}])}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="currency">Currency</label>
                                            <div className="form-control-wrap">
                                                <input
                                                    className="form-control form-select-lg"
                                                    name="currency" id="currency"
                                                    defaultValue={this.state.currency}
                                                    onChange={(e) => this.updatePayload([{data: e.target.value, key: "currency"}])}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-12">
                                        <div className="form-group" style={{display:"flex"}}>
                                            <button type="button" className="btn btn-lg btn-secondary" onClick={this.toggleStatus} disabled={this.state.loading} style={{padding:"15px", width:"200px", marginRight:"10px"}}>
                                                {this.state.loading ? "Updating..." : (this.state.isActive ? "Deactivate" : "Activate")}
                                            </button>
                                            <button type="submit" className="btn btn-lg btn-primary" disabled={this.state.loading} style={{padding:"15px", width:"200px"}}>
                                                {this.state.loading ? "Updating..." : "Save"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }
}
