import React, { Component } from "react";
import { Link } from "react-router-dom";
import Constants from "./Utils/Constants";

export default function Logo({ icon, style, className, reverse, linkUrl }) {
  const logoImage = reverse ? (
    <img
      src={Constants.subfolder + "/logo-reverse.png"}
      style={style}
      className={className}
    />
  ) : icon ? (
    <img
      src={Constants.subfolder + "/icon.png"}
      style={style}
      className={className}
    />
  ) : (
    <img
      src={Constants.subfolder + "/logo.png"}
      style={style}
      className={className}
    />
  );

  return linkUrl ? <Link to="/">{logoImage}</Link> : logoImage;
}
