import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../sass/others.css";
import UsersService from "../../services/UsersService";
import Toastr from "../../Utils/Toastr";
import Spinner from "../../Utils/Spinner";

export default class PasswordChange extends Component {
  constructor(props) {
    super(props);
    const link = window.location.href;
    this.state = {
      loading: false,
      data: {
        email: link?.split("?")[1]?.split("=")[1] ?? "",
      },
    };
  }

  resetPassword = async (e) => {
    e.preventDefault();

    var Data = await UsersService.ResetPassword(this.state.data);
    if (Data) {
      Toastr("success", "Mail successfully sent for password reset");
      const link = window.location.href;
      setTimeout(() => {
        if (link?.split("?")[1]?.split("=")[1]) window.location.href = "/users";
        else window.location.href = "/login";
      }, 2000);
    }
    this.setState({ loading: false });
  };

  handlePasswordChange = async ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    console.log(input);
    data[input.name] = input.value;
    this.setState({ data });
  };

  render() {
    return (
      <div className="nk-content row align-self-center h-100">
        <div
          className="nk-block nk-block-middle nk-auth-body  wide-xs col "
          style={{ marginLeft: "7vw" }}
        >
          <div
            className="card card-bordered"
            style={{
              backgroundColor: "#fff0",
              border: "none",
              maxWidth: "420px",
            }}
          >
            <div
              className="card-inner card-inner-lg"
              style={{ backgroundColor: "#fff0" }}
            >
              <div className="nk-block-head">
                <div className="nk-block-head-content text-center">
                  {/* <Link to=""><img className="mb-4" src="https://i.ibb.co/TbkVR3h/maple-removebg-preview.png" alt="maple-removebg-preview" border={0} /></Link> */}
                  <h4
                    className="nk-block-title text-center"
                    style={{ fontWeight: "200", color: "#f1f1f1" }}
                  >
                    Password reset
                  </h4>
                </div>
              </div>
              <form action="" onSubmit={async (e) => this.resetPassword(e)}>
                <div className="form-group">
                  <div className="form-label-group">
                    {/* <label className="form-label" htmlFor="default-01">Email Address</label> */}
                  </div>
                  <div className="form-control-wrap">
                    <input
                      style={{
                        backgroundColor: "#fff0",
                        border: "0px",
                        borderBottom: "2px solid white",
                        borderRadius: "0px",
                        color: "white",
                        outline: "none",
                      }}
                      type="text"
                      name="email"
                      value={this.state.data.email}
                      onChange={this.handlePasswordChange}
                      className="form-control form-control-lg"
                      id="default-01"
                      placeholder="Email address"
                    />
                  </div>
                </div>
                <div className="form-group">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <button
                      style={{ color: "white" }}
                      type="submit"
                      className="btn btn-lg btn-primary btn-block"
                      onClick={async (e) => await this.resetPassword(e)}
                    >
                      Proceed
                    </button>
                  )}
                </div>
              </form>
              <div
                className="form-note-s2"
                style={{ marginTop: "20px", textAlign: "center" }}
              >
                <Link style={{ color: "#dbdfea" }} to="/login">
                  <strong>Return to login</strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
