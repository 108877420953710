import { useState } from "react";
import { Link } from "react-router-dom";
import CustomerService from "../services/CustomerService";
import UsersService from "../services/UsersService";

const SingleCardVoucher = ({
  item,
  handleItemChange,
  index,
  voucherItems,
  RemoveVoucherItem,
}) => {
  const [searchString, setSearchString] = useState("");
  const [showCustomer, setShowCustomer] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const [showVouchers, setShowVouchers] = useState(false);
  const [loadingVouchers, setLoadingVouchers] = useState(false);

  const searchCustomers = async (search) => {
    setLoading(true);
    if (search.length >= 3) {
      setShowCustomer(true);
      var customers = await CustomerService.search(search);
      if (customers) {
        setCustomers(customers);
      }
    } else {
      setCustomers([]);
      setShowCustomer(false);
    }
    setLoading(false);
  };

  const searchVoucher = async (id) => {
    setLoadingVouchers(true);

    var { data } = await UsersService.listVoucherByUser(1, 100, "approved", id);

    if (data) {
      setLoadingVouchers(false);
      setVouchers(data);
    }
    setLoadingVouchers(false);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat("en-Us").format(num);
  };

  const formatDate = (date) => {
    const formattedDate = new Date(date);

    return `${formattedDate.getDate()}/${
      formattedDate.getMonth() + 1
    }/${formattedDate.getFullYear()}`;
  };

  return (
    <div className="col-12">
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">
              Item description <small className="text-danger">*</small>
            </label>
            <div className="form-control-wrap">
              <input
                value={item.description}
                type="text"
                onChange={(e) =>
                  handleItemChange("description", e.target.value, index)
                }
                className="form-control form-control-lg"
                placeholder="Item description"
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="amount">
              Amount <small className="text-danger">*</small>
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                onChange={(e) =>
                  handleItemChange("amount", Number(e.target.value), index)
                }
                className="form-control form-control-lg"
                value={item.amount}
                placeholder="Enter amount"
                step="any"
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label" htmlFor="customer">
              {!selectedVoucher ? "Client Name" : "Invoice Details"}
            </label>
            <div className="form-control-wrap">
              {!selectedVoucher && (
                <input
                  type="text"
                  onChange={(e) => setSearchString(e.target.value)}
                  onKeyUp={async (e) => {
                    setSelectedVoucher("");
                    setShowVouchers(false);
                    setVouchers([]);
                    await searchCustomers(e.target.value);
                  }}
                  value={searchString}
                  className="form-control form-control-lg"
                  id="customer"
                  placeholder="search"
                  name="customer"
                />
              )}

              <div className="customer-drop-down invoice-pad sm">
                <ul style={{ height: "100%", overflow: "auto" }}>
                  {!selectedVoucher && (
                    <>
                      {customers?.map((customer, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            searchVoucher(customer.id);
                            setShowVouchers(true);
                            setCustomers([]);
                            setShowCustomer(false);
                          }}
                        >
                          <span className="customer-name">
                            {customer.firstName} {customer.lastName}
                          </span>
                          <span className="customer-email">
                            {customer.emailAddress}
                          </span>
                        </li>
                      ))}

                      {showCustomer && (
                        <li className="customer-name">
                          Not Found?{" "}
                          <Link to="/add-customer" className="new">
                            Add New
                          </Link>
                        </li>
                      )}

                      {vouchers?.map((voucher, idx) => (
                        <li
                          key={idx}
                          onClick={() => {
                            setSelectedVoucher(voucher);
                            console.log(voucher.id);
                            handleItemChange("invoiceId", voucher.id, index);
                            // voucher.invoiceNumber
                          }}
                        >
                          <span className="customer-name">
                            {voucher.customer.firstName}{" "}
                            {voucher.customer.lastName}
                          </span>
                          {voucher.currency}
                          <span className="customer-name">
                            {formatNumber(voucher.amount)}
                          </span>
                          <span className="customer-name">
                            {formatDate(voucher.dateCreated)}
                          </span>
                        </li>
                      ))}

                      {loadingVouchers && (
                        <li className="customer-name">Loading Invoices</li>
                      )}

                      {!loadingVouchers &&
                        vouchers.length < 1 &&
                        showVouchers && (
                          <li className="customer-name">
                            Not Invoice(s) Found{" "}
                          </li>
                        )}
                    </>
                  )}

                  {selectedVoucher && (
                    <>
                      <li>
                        <div className="cancel-cont">
                          <button
                            className="cancel-btn"
                            onClick={() => setSelectedVoucher(null)}
                          >
                            <em className="fa fa-times"></em>
                          </button>
                        </div>
                      </li>
                      <li className="customer-name">
                        <span>Invoice Number:{"     "}</span>
                        <Link
                          to={`/invoice?ref=${selectedVoucher.invoiceNumber}`}
                          target="_blank"
                          style={{ color: "blue" }}
                        >
                          {selectedVoucher.invoiceNumber}
                        </Link>
                      </li>
                      <li className="customer-name">
                        <span>Customer:{"     "}</span>
                        <span>{selectedVoucher.customer.firstName} </span>
                        <span>{selectedVoucher.customer.lastName}</span>
                      </li>
                      <li className="customer-name">
                        <span>Customer:{"     "}</span>
                        <span>{selectedVoucher.currency}</span>
                        <span>{selectedVoucher.amount}</span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {voucherItems.length > 1 && (
        <div className="col-md-1">
          <div className="form-group">
            <div className="form-control-wrap mt-4">
              <a
                className="mt-2"
                style={{ cursor: "pointer" }}
                onClick={() => RemoveVoucherItem(index)}
              >
                <img src="/images/delete2.png" style={{ width: "20px" }}></img>
              </a>
            </div>
          </div>
        </div>
      )}
      <hr className="preview-hr" style={{ marginBottom: "0px" }} />
    </div>
  );
};

export default SingleCardVoucher;
