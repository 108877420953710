import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import Spinner from "../../Utils/Spinner";
import Toastr from "../../Utils/Toastr";
import UsersService from "./../../services/UsersService";
import Select from "react-select";
import { Link } from "react-router-dom";
export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        name: "",
        email: "",
        role: "",
      },

      roles: [
        { value: "staff", label: "Staff" },
        { value: "admin", label: "Admin" },
        { value: "accountant", label: "Accountant" },
        { value: "firstLevelAuthorizer", label: "First Level Authorizer" },
        { value: "secondLevelAuthorizer", label: "Second Level Authorizer" },
        { value: "externalAuditor", label: "External Auditor" },
      ],
    };
  }

  addUser = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var Data = await UsersService.create(this.state.data);
    if (Data) {
      Toastr("success", "User added successfully");
      setTimeout(() => {
        this.props.history.push("/users");
      }, 2000);
    }
    this.setState({ loading: false });
  };

  handleUserChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleRoleChange = (role) => {
    if (role) {
      let data = { ...this.state.data };
      data.role = role.value;
      this.setState({ data: data });
    }
  };

  render() {
    return (
      <MainLayout title="User">
        <div className="col">
          <div className="card card-bordered col-md-6">
            <div className="card-inner">
              <form action="" onSubmit={async (e) => await this.addUser(e)}>
                <div className="row g-4">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="name">
                        Full name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={this.state.data.name}
                          onChange={this.handleUserChange}
                          className="form-control form-select-lg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={this.state.data.email}
                          onChange={this.handleUserChange}
                          className="form-control form-select-lg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="role">
                        Role
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          onChange={(option) => this.handleRoleChange(option)}
                          id="role"
                          options={this.state.roles}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          {this.state.loading ? (
                            <Spinner />
                          ) : (
                            <button
                              type="button"
                              className="btn  btn-primary btn-block"
                              onClick={async (e) => await this.addUser(e)}
                            >
                              Add User
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Link to="/users" className="btn  btn-dark btn-block">
                            {" "}
                            <em className="icon ni ni-chevrons-left"></em> Back
                            to Users
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
