import React, { Component } from "react";
import PaymentService from "../../services/PaymentService";
import Spinner from "../../Utils/Spinner";
import FailedPayment from "./failed-payment";
import SuccessPayment from "./success-payment";
export default class PaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      paymentData: {
        invoice: {},
        payment: {},
      },
      paymentSuccessful: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    let reference = this.props.match.params.paymentReference;
    console.log(reference);
    let paymentData = await PaymentService.status(reference);
    if (paymentData) {
      if (paymentData.invoice?.paymentStatus === "successful") {
        this.setState({ paymentSuccessful: true });
      }
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="nk-app-root">
        {/* main @s */}
        <div className="nk-main ">
          {/* wrap @s */}
          <div className="nk-wrap nk-wrap-nosidebar">
            {/* content @s */}
            <div className="nk-content ">
              {this.state.loading ? (
                <div className="nk-block-middle nk-auth-body">
                  {" "}
                  <Spinner />{" "}
                </div>
              ) : this.state.paymentSuccessful ? (
                <SuccessPayment />
              ) : (
                <FailedPayment />
              )}
            </div>
            {/* wrap @e */}
          </div>
          {/* content @e */}
        </div>
        {/* main @e */}
      </div>
    );
  }
}
