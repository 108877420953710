import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import CustomerService from "../../services/CustomerService";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";

export default class CustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      customers: [],
      count: 0,
      page: 1,
      search: "",
      company: "",
      contactAddress: "",
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    const customers = (await CustomerService.listCustomers()) || [];
    console.log(customers);
    if (customers) {
      this.setState({ customers: customers });
    }
    const customersList = await CustomerService.count();
      this.setState({ count: customersList });

    this.setState({ loading: false });
  };

  tableRow = ({
    company,
    emailAddress,
    firstName,
    lastName,
    phoneNumber,
    contactAddress,
    id,
  }) => {
    return (
      <div className="nk-tb-item">
        <div className="nk-tb-col">
          <div className="nk-tnx-type">
            <div className="nk-tnx-type-text">
              <span className="tb-lead">
                {firstName} {lastName}
              </span>
            </div>
          </div>
        </div>
        <div className="nk-tb-col tb-col-lg">
          {company ? (
            <span className="tb-lead-sub">{company}</span>
          ) : (
            <span className="tb-lead-sub">{"Not Available"}</span>
          )}
        </div>
        <div className="nk-tb-col text-left">
          <span className="tb-amount font-weight-normal">{emailAddress}</span>
        </div>
        <div className="nk-tb-col text-left tb-col-sm">
          <span className="tb-amount font-weight-normal">{phoneNumber}</span>
        </div>
        <div className="nk-tb-col text-left">
          {contactAddress ? (
            <span className="tb-amount font-weight-normal">
              {contactAddress}
            </span>
          ) : (
            <span className="tb-amount font-weight-normal">
              {"Not Available"}
            </span>
          )}
        </div>
        <div className="nk-tb-col nk-tb-col-status list">
          <Link
            to={"/customer/" + id}
            className="btn btn-primary"
            // className="badge badge-sm text-white badge-dim badge-outline-secondary d-none d-md-inline-flex"
          >
            <em class="icon ni ni-eye mr-2"></em>
            View
          </Link>
        </div>
      </div>
    );
  };

  previous = async () => {
    if (this.state.page == 1) return;
    let previousPage = this.state.page - 1;
    const customers =
      (await CustomerService.listCustomers(
        previousPage,
        20,
        this.state.search
      )) || [];
    this.setState({ customers: customers });
    this.setState({ page: previousPage });
  };

  next = async () => {
    let nextPage = this.state.page + 1;
    const customers =
      (await CustomerService.listCustomers(nextPage, 20, this.state.search)) ||
      [];
    if (!customers || customers.length == 0) return;
    this.setState({ customers: customers });
    this.setState({ page: nextPage });
  };

  search = async (text) => {
    const customers = (await CustomerService.listCustomers(1, 20, text)) || [];
    this.setState({ customers: customers });
    this.setState({ page: 1, search: text });
  };

  render() {
    return (
      <MainLayout title="Customer list">
        <div className="col">
          <div className="nk-content ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between g-3">
                      <div className="nk-block-head-content">
                        <div className="nk-block-des text-soft">
                          <p>You have total {this.state.count} customers.</p>
                        </div>
                      </div>
                      <div className="nk-block-head-content">
                        <ul className="nk-block-tools g-3">
                          <li>
                            <Link
                              className="btn btn-primary btn-md"
                              to="/add-customer"
                            >
                              New Customer
                            </Link>
                          </li>
                          {/* <li>
                            <a
                              href="#"
                              className="btn btn-white btn-dim btn-outline-light"
                            >
                              <em className="icon ni ni-download-cloud" />
                              <span>Export</span>
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title">All customers</h5>
                            </div>
                            <div className="card-tools mr-n1">
                              <ul className="btn-toolbar gx-1">
                                <li>
                                  <input
                                    onChange={(e) =>
                                      this.search(e.target.value)
                                    }
                                    type="search"
                                    class="form-control form-control-md"
                                    placeholder="Type in to search"
                                    id="search"
                                    aria-controls="customer_list_Table"
                                  />
                                </li>
                              </ul>
                            </div>
                            <div
                              className="card-search search-wrap"
                              data-search="search"
                            >
                              <div className="search-content">
                                <a
                                  href="#"
                                  className="search-back btn btn-icon toggle-search"
                                  data-target="search"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </a>
                                <button className="search-submit btn btn-icon">
                                  <em className="icon ni ni-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner p-0">
                          <div className="nk-tb-list nk-tb-tnx">
                            <div className="nk-tb-item nk-tb-head col-md-12">
                              <div className="nk-tb-col tb-col">
                                <span>Name</span>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span>company</span>
                              </div>
                              <div className="nk-tb-col text-left">
                                <span>Email Address</span>
                              </div>
                              <div className="nk-tb-col text-left tb-col-sm">
                                <span>Phone Number</span>
                              </div>
                              <div className="nk-tb-col">
                                <span className="sub-text d-none d-md-block">
                                  Contact Address
                                </span>
                              </div>
                              <div className="nk-tb-col nk-tb-col-tools" />
                            </div>
                            {this.state.loading ? (
                              <div className="nk-tb-item col-md-12">
                                <div className="nk-tb-col">
                                  <div className="nk-tnx-type">
                                    <div className="nk-tnx-type-text">
                                      <span className="tb-lead">
                                        <Spinner />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : !this.state.customers ? null : (
                              this.state.customers.map((customer, index) => (
                                <this.tableRow key={index} {...customer} />
                              ))
                            )}
                          </div>
                        </div>
                        <div className="card-inner">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            <li className="page-item">
                              <a
                                onClick={() => this.previous()}
                                className="page-link"
                                href="#"
                              >
                                Prev
                              </a>
                            </li>
                            <li className="page-item">
                              <a
                                onClick={() => this.next()}
                                className="page-link"
                                href="#"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
