import React, { Component } from "react";
import MainLayout from "../Components/Layouts/MainLayout";
import UsersService from "../services/UsersService";
import "./samplePage.scss";
import Spinner from "../Utils/Spinner";
import InvoiceService from "../services/InvoiceService";
import CustomerService from "../services/CustomerService";
import Functions from "../Utils/Functions";
import VoucherService from "../services/VoucherService";
import { FormatMoney } from "../Utils/format-currency";
// import Vouchers from "./voucher/vouchers";
// import { async } from "q";
//import { Navigate } from "react-router-dom";
// import { DataContext } from "../Contexts/DataContexts";
// import TransactionService from "../services/TransactionService";
// import InvoiceService from "../services/InvoiceService";
// import CustomerService from "../services/CustomerService";
// import Spinner from "../Utils/Spinner";
// import { Link } from "react-router-dom";
// import InvoiceStatus from "../Utils/InvoiceStatus";

export default class SamplePage extends Component {
  constructor(props) {
    super(props);
    //this.showNotification = this.showNotification.bind(this);
    this.state = {
      transactionLoading: false,
      invoiceLoading: false,
      customerLoading: false,
      transactions: [],
      invoices: [],
      role: "",
      customers: [],
      welcomeName: "",
      redirectUrl: null,
      exchangeRate: "",
      isLoading: false,
      filterData: {
        dateFrom: "",
        dateTo: "",
        invoiceStatus: "all",
        createdBy: 0,
        approvedBy: 0,
        customerId: 0,
        customerName: "",
        serviceId: 0,
        invoiceNumber: "",
        forCurrentUser: true,
        createdName: "",
      },
      invData: [],
      customerCount: "",
      invCount: "",
      voucherData: [],
      loading: false,
      vdata: "",
      awaitApproval: "",
    };
  }
  statusThemes = {
    raised: "primary",
    responded: "warning",
    returned_by_accountant: "danger",
    approved_by_accountant: "info",
    returned_at_approval: "danger",
    first_approval: "info",
    first_and_final_approval: "success",
    second_approval: "success",
    paid: "success",
  };

  componentDidMount = async (filterData = null, page) => {
    this.setState({ loading: true });
    const userName = JSON.parse(localStorage.getItem("user"));
    // console.log({userData:userName});
    this.setState({ welcomeName: userName.fullname });
    this.setState({ isLoading: true });
    const settings = await UsersService.getSettingsItems();
    this.setState({ isLoading: false });
    if (settings?.length > 0) {
      settings.forEach((sets) => {
        if (sets.settingKey === "NGN_to_USD_Exchange_Rate") {
          this.setState({ exchangeRate: sets.settingValue });
        }
      });
    }

    let today = new Date();
    let aYearAgo = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
    const data = filterData ? { ...filterData } : { ...this.state.filterData };
    if (!data.dateFrom) data.dateFrom = Functions.formatDate2(aYearAgo);
    if (!data.dateTo) data.dateTo = Functions.formatDate2(today);
    const invResponse = await InvoiceService.dashList(
      data.dateFrom,
      data.dateTo,
      1,
      5,
      true
    );
    console.log({ recentinv: invResponse });
    if (invResponse?.data.length > 0) {
      this.setState({ invData: invResponse.data });
      this.setState({ invCount: JSON.stringify(invResponse.count) });
    }

    console.log({ datalogged: this.state.invData });

    const customerNumber = await CustomerService.count();
    this.setState({ customerCount: JSON.stringify(customerNumber) });

    const vouchers = await VoucherService.dashVoucher(
      data.dateFrom,
      data.dateTo,
      1,
      5,
      true
    );
    console.log({ vouchers: vouchers });
    if (vouchers?.data.length > 0) {
      this.setState({ voucherData: vouchers.data });
      this.setState({ vdata: vouchers.count });
    }

    const pendingVouchers = await VoucherService.dashVoucher(
      data.dateFrom,
      data.dateTo,
      1,
      100,
      true,
      "raised"
    );
    this.setState({ awaitApproval: JSON.stringify(pendingVouchers.count) });

    this.setState({ loading: false });

    this.loadNotiications();
    //this.loadNotiiList();
  };

  reloadRate = async () => {
    this.setState({ isLoading: true });
    const settings = await UsersService.getSettingsItems();
    this.setState({ isLoading: false });
    if (settings?.length > 0) {
      settings.forEach((sets) => {
        if (sets.settingKey === "NGN_to_USD_Exchange_Rate") {
          this.setState({ exchangeRate: sets.settingValue });
        }
      });
    }
  };

  loadNotiications = async () => {
    const response = await UsersService.getNotiications();
    console.log({ notification: response });
  };

  loadNotiiList = async () => {
    const response = await UsersService.getNotiiList(1, 5);
    console.log({ notiilist: response });
  };

  gotoInvoices = () => {
    window.location.href = "/invoices";
  };

  gotoCustomers = () => {
    window.location.href = "/customers";
  };

  gotoVouchers = () => {
    window.location.href = "/vouchers";
  };

  addCustomer = () => {
    window.location.href = "/add-customer";
  };

  raiseRequest = () => {
    window.location.href = "/raise-voucher";
  };

  raiseInvoice = () => {
    window.location.href = "/raise-invoice";
  };

  gotoInvoice = (invoiceRef) => {
    window.location.href = "/invoice?ref=" + invoiceRef;
  };

  gotoVoucher = (voucherRef) => {
    window.location.href = "/voucher/" + voucherRef;
  };

  render() {
    return (
      <MainLayout title={"Dashboard"}>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="dash-box col-md-12">
            <div className="boxed">
              <div className="row upper-section">
                <div className="welcome col-md-5">
                  <p className="hello" style={{color:"#1c2b46"}}>{"Welcome,"}</p>
                  <p 
                      className="hello" 
                      style={{
                        backgroundColor:"#1c2b46", 
                        color:"#99FF00",
                        width:"fit-content",
                        padding:"8px",
                        borderRadius:"6px"}}>
                      {this.state.welcomeName}</p>
                </div>
                <div className="links col-md-7">
                  {/* <div className="quick-title">Quick links</div> */}
                  <div className="row quick-sect">
                    <div
                      className="col-md-4 left"
                      style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    >
                      <button
                        onClick={() => this.raiseRequest()}
                        className="btn-lg quick-link"
                      >
                        Raise requisition
                      </button>
                    </div>
                    <div
                      className="col-md-4 post"
                      style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    >
                      <button
                        onClick={() => this.raiseInvoice()}
                        className="btn-lg quick-link"
                        href="/invoices"
                      >
                        Raise Invoice
                      </button>
                    </div>
                    <div
                      className="col-md-4 right"
                      style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    >
                      <button
                        onClick={() => this.addCustomer()}
                        className="btn-lg quick-link"
                      >
                        <em class="icon ni mr-3 ni-plus-c"></em>Add customer
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="quick-title"></div>
              {/* <div>
        <button onClick={this.showNotification}>Show notification</button></div> */}
              <div className="cards-sect">
                <div className="left-card">
                  <div className="row size-set">
                    <div className="box-top-header col-md-6 one">
                      <p className="card-tit">All Customers</p>
                      {this.state.loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <p className="card-dat">
                            {this.state.customerCount
                              ? this.state.customerCount
                              : 0}
                          </p>
                          <p
                            type="button"
                            onClick={() => this.gotoCustomers()}
                            className="view-all"
                          >
                            View all
                          </p>
                        </>
                      )}
                    </div>
                    <div className="box-top-header col-md-6 one">
                      <img
                        className="item-images"
                        src="/images/customer22.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="row size-set bot-div"
                    style={{ paddingTop: "22px" }}
                  >
                    <div className="box-top-header col-md-6 one">
                      <p className="card-tit">My Invoices</p>
                      {this.state.loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <p className="card-dat">
                            {this.state.invCount ? this.state.invCount : 0}
                          </p>
                          <p
                            type="button"
                            onClick={() => this.gotoInvoices()}
                            className="view-all"
                          >
                            View all
                          </p>
                        </>
                      )}
                    </div>
                    <div className="box-top-header col-md-6 one">
                      <img
                        className="item-images"
                        src="/images/invoice22.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="left-card middle">
                  <div className="row size-set">
                    <div className="box-top-header col-md-6 one">
                      <p className="card-tit">My Payment Requests</p>
                      {this.state.loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <p className="card-dat">
                            {this.state.vdata ? this.state.vdata : 0}
                          </p>
                          <p
                            type="button"
                            onClick={() => this.gotoVouchers()}
                            className="view-all"
                          >
                            View all
                          </p>
                        </>
                      )}
                    </div>
                    <div className="box-top-header col-md-6 one">
                      <img
                        className="item-images"
                        src="/images/payment22.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="row size-set bot-div"
                    style={{ paddingTop: "22px" }}
                  >
                    <div className="box-top-header col-md-6 one">
                      <p className="card-tit">Awaiting Approval</p>
                      {this.state.loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <p className="card-dat">
                            {this.state.awaitApproval
                              ? this.state.awaitApproval
                              : 0}
                          </p>
                          <p
                            type="button"
                            onClick={() => this.gotoVouchers()}
                            className="view-all"
                          >
                            View all
                          </p>
                        </>
                      )}
                    </div>
                    <div className="box-top-header col-md-6 one">
                      <img
                        className="item-images"
                        src="/images/approval22.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="right-card">
                  <div className="box-top-header2">Today's Exchange Rate</div>
                  <div className="rate-box">
                    <div className="exchange-rate">
                      {this.state.isLoading ? (
                        <div className="SpinnerEdit">
                        <Spinner className="sppinner"/>
                        </div>
                      ) : (
                        this.state.exchangeRate
                      )}
                    </div>
                    <div style={{backgroundColor:"white", width:"fit-content", margin:"auto", borderRadius:"50%"}}>
                      <img
                        hidden={this.state.isLoading}
                        type="button"
                        src="/images/sync.png"
                        alt=""
                        className="reload"
                        onClick={() => this.reloadRate()}
                      />
                    </div>
                  </div>
                  <div className="bottom-message" hidden={false}>
                    Rate is valid until 3pm. For payments after the deadline,
                    tomorrow's rate will apply
                  </div>
                </div>
              </div>
              {/* <div className="set-sm-card top-top">
          <div className="date-card">
            <div className="sm-card-content">
                <img className="vouch-img" src="./images/voucher.png" alt="vouch" />
                <p className="sm-card-text">Pending Invoices</p>
            </div>
            <div className="sm-card-num">500</div>
          </div>
          <div className="date-card">
          <div className="sm-card-content">
                <img className="vouch-img" src="./images/voucher.png" alt="vouch" />
                <p className="sm-card-text">Pending Requisitions</p>
            </div>
            <div className="sm-card-num">500</div>
          </div>
          <div className="date-card">
          <div className="sm-card-content">
                <img className="vouch-img" src="./images/voucher.png" alt="vouch" />
                <p className="sm-card-text">Awaiting Approval</p>
            </div>
            <div className="sm-card-num">500</div>
          </div>
        </div>
        <div className="set-sm-card bott-bott">
          <div className="date-card">
            <div className="sm-card-content">
                <img className="vouch-img" src="./images/voucher.png" alt="vouch" />
                <p className="sm-card-text">Monthly invoice count</p>
            </div>
            <div className="sm-card-num">500</div>
          </div>
          <div className="date-card">
          <div className="sm-card-content">
                <img className="vouch-img" src="./images/voucher.png" alt="vouch" />
                <p className="sm-card-text">Monthly voucher count</p>
            </div>
            <div className="sm-card-num">500</div>
          </div>
          <div className="date-card">
          <div className="sm-card-content">
                <img className="vouch-img" src="./images/voucher.png" alt="vouch" />
                <p className="sm-card-text">Monthly customer count</p>
            </div>
            <div className="sm-card-num">500</div>
          </div>
        </div> */}
              <div>
                <div className="quick-title">Recent Invoices</div>
                <fieldset className="field">
                  <div className="row recent-head-set head-txt">
                    <div className="col-md-2 txt">DATE</div>
                    <div className="col-md-2 txt">CUSTOMER NAME</div>
                    <div className="col-md-3 txt">INVOICE NUMBER</div>
                    <div className="col-md-2 txt" style={{ textAlign: "left" }}>
                      STATUS
                    </div>
                    <div
                      className="col-md-2 txt"
                      style={{ textAlign: "right", paddingRight: "25px" }}
                    >
                      AMOUNT
                    </div>
                    <div className="col-md-1 txt"></div>
                  </div>
                  {this.state.invData.map((list, i) => (
                    <div key={i} className="row recent-body-set">
                      <div className="col-md-2 txt">
                        {Functions.dateForm(list.dateCreated)}
                      </div>
                      <div className="col-md-2 txt">
                        {list.customer.lastName + " " + list.customer.firstName}
                      </div>
                      <div className="col-md-3 txt">{list.invoiceNumber}</div>
                      <div
                        className="col-md-2 txt"
                        style={{ textAlign: "left" }}
                      >
                        {list.status === "awaiting_approval" && (
                          <span className="badge badge-dot badge-warning">
                            Awaiting Approval
                          </span>
                        )}
                        {list.status === "returned" && (
                          <span className="badge badge-dot badge-danger">
                            Returned
                          </span>
                        )}
                        {list.status === "cancelled" && (
                          <span className="badge badge-dot badge-danger">
                            Cancelled
                          </span>
                        )}
                        {list.status === "approved" && (
                          <span className="badge badge-dot badge-success">
                            Approved
                          </span>
                        )}
                      </div>
                      <div
                        className="col-md-2 txt"
                        style={{
                          textAlign: "right",
                          paddingRight: "25px",
                          fontWeight: "500",
                        }}
                      >
                        {FormatMoney(list.amountPayable, list.currency)}
                      </div>
                      <div className="col-md-1 txt arr-div">
                        <img
                          type="button"
                          alt=""
                          className="img-arrow"
                          src="/images/arroww.png"
                          onClick={() => this.gotoInvoice(list.invoiceNumber)}
                        />
                      </div>
                    </div>
                  ))}
                </fieldset>
              </div>
              <div>
                <div className="quick-title" style={{ marginTop: "20px" }}>
                  Recent Requisitions
                </div>
                <fieldset className="field">
                  <div className="row recent-head-set head-txt">
                    <div className="col-md-2 txt">DATE</div>
                    <div className="col-md-2 txt">VOUCHER CODE</div>
                    <div className="col-md-3 txt">BENEFICIARY NAME</div>
                    <div className="col-md-2 txt">STATUS</div>
                    <div
                      className="col-md-2 txt"
                      style={{ textAlign: "right", paddingRight: "25px" }}
                    >
                      AMOUNT
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  {this.state.voucherData.map((list, i) => (
                    <div key={i} className="row recent-body-set">
                      <div className="col-md-2 txt">
                        {Functions.dateForm(list.dateCreated)}
                      </div>
                      <div className="col-md-2 txt">{list.voucherCode}</div>
                      <div className="col-md-3 txt">{list.beneficiaryName}</div>
                      <div className="col-md-2 txt">
                        {list.status === "returned_by_accountant" && (
                          <p
                            className={`badge badge-dot badge-${
                              this.statusThemes[list.status]
                            }`}
                          >
                            RETURNED
                          </p>
                        )}
                        {list.status === "paid" && (
                          <p
                            className={`badge badge-dot badge-${
                              this.statusThemes[list.status]
                            }`}
                          >
                            {list.status?.toUpperCase()}
                          </p>
                        )}
                        {list.status === "first_and_final_approval" && (
                          <p
                            className={`badge badge-dot badge-${
                              this.statusThemes[list.status]
                            }`}
                          >
                            FINAL_APPROVAL
                          </p>
                        )}
                        {list.status === "raised" && (
                          <p
                            className={`badge badge-dot badge-${
                              this.statusThemes[list.status]
                            }`}
                          >
                            {list.status?.toUpperCase()}
                          </p>
                        )}
                        {list.status === "responded" && (
                          <p
                            className={`badge badge-dot badge-${
                              this.statusThemes[list.status]
                            }`}
                          >
                            {list.status?.toUpperCase()}
                          </p>
                        )}
                        {list.status === "approved_by_accountant" && (
                          <p
                            className={`badge badge-dot badge-${
                              this.statusThemes[list.status]
                            }`}
                          >
                            REVIEWED
                          </p>
                        )}
                        {list.status === "returned_at_approval" && (
                          <p
                            className={`badge badge-dot badge-${
                              this.statusThemes[list.status]
                            }`}
                          >
                            {list.status?.toUpperCase()}
                          </p>
                        )}
                        {list.status === "first_approval" && (
                          <p
                            className={`badge badge-dot badge-${
                              this.statusThemes[list.status]
                            }`}
                          >
                            {list.status?.toUpperCase()}
                          </p>
                        )}
                        {list.status === "second_approval" && (
                          <p
                            className={`badge badge-dot badge-${
                              this.statusThemes[list.status]
                            }`}
                          >
                            FINAL_APPROVAL
                          </p>
                        )}
                      </div>
                      <div
                        className="col-md-2 txt"
                        style={{
                          textAlign: "right",
                          paddingRight: "25px",
                          fontWeight: "500",
                        }}
                      >
                        {FormatMoney(list.amount, list.currency)}
                      </div>
                      <div className="col-md-1 arr-div">
                        <img
                          type="button"
                          alt=""
                          className="img-arrow"
                          src="/images/arroww.png"
                          onClick={() => this.gotoVoucher(list.voucherCode)}
                        />
                      </div>
                    </div>
                  ))}
                </fieldset>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    );
  }
}
