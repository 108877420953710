import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import Modal from "react-modal";
import Constants from "../../Utils/Constants";
import CategoriesService from "../../services/CategoriesService";
import Toastr from "../../Utils/Toastr";
import Spinner from "../../Utils/Spinner";
import "../../sass/categories.scss";
// import zIndex from "@material-ui/core/styles/zIndex";
export default class ServiceCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                name: "",
                categoryCode: "",
                id: 0
            },

            categories: [],
            modalOpen: false,
            categoryId: 0,
            modal: false,
            filtered: null

        };
    }


    componentDidMount = async () => {
        this.setState({ loading: true });
        const categories = await CategoriesService.listCategories();
        if (categories) {
            this.setState({ categories: categories });
        }
        this.setState({ loading: false });
    };


    add = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var Data = await CategoriesService.add(this.state.data)
        if (Data) {
            Toastr("success", "Service category added");
        }
        setTimeout(() => {
            window.location.reload(false);
            this.props.history.push("/service-categories");
            this.setState({ modalOpen: false });
        }, 3000);
        this.setState({ loading: false });
    };

    getCategory = async (id) => {
        this.setState({ loading: true });
        let categoryList = [];
        categoryList = await CategoriesService.listCategories();
        if (categoryList && categoryList.length > 0) {
            let neededCategory = categoryList.find(obj => obj.id === id);
            let data = { ...this.state.updateData };
            data.name = neededCategory?.categoryname;

            this.setState({ updateData: data });

        }
        this.setState({ loading: false });
    }
    updateCategory = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var categories = await CategoriesService.update(this.state.data)
        if (categories) {
            Toastr("success", "Service updated category ");
        }
        setTimeout(() => {
            window.location.reload(false);
        }, 3000);
        this.setState({ loading: false });
    };

    handleCategoryChange = async ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data: data });
    }
    GetCategoryToUpdate = (category) => {
        let data = { ...this.state.data };
        data.id = category.id;
        data.name = category.categoryname;
        this.setState({ data: data });
        this.setState({ modal: true });
    }

    searchCategory = (search) => {
        if (!search) {
            this.setState({ filtered: null });
            return;
        }
        let filtered = this.state.categories || [];
        filtered = this.state.categories.filter(a => a.categoryname.toLowerCase().includes(search));
        this.setState({ filtered: filtered });
    }


    render() {
        return <MainLayout title="Service Categories">
            <Modal
                isOpen={this.state.modalOpen}
                style={Constants.defaultModalStyle}
                onRequestClose={() => this.setState({ modalOpen: false })}
                ariaHideApp={false}>
                <div style={{ width: "450px", }} >
                    <div className="col">
                        <div className="form-group">
                            <label className="form-label" htmlFor="full-name-1">Enter new Service category</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control form-select-lg"
                                    name="name"
                                    value={this.state.data.name}
                                    onChange={this.handleCategoryChange} />
                            </div>
                            <label className="form-label" htmlFor="full-name-1">Enter Service category Code</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control form-select-lg"
                                    name="categoryCode"
                                    value={this.state.data.categoryCode}
                                    onChange={this.handleCategoryChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div>
                                <label className="form-label" htmlFor="full-name-1" style={{ visibility: 'hidden' }}>Settlement Bank</label>
                            </div>
                            {
                                this.state.loading ? <Spinner /> :
                                    <button type="button" className="btn btn-lg btn-primary mx-3" onClick={(e) => { this.add(e) }}>Save</button>
                            }
                            <button onClick={() => this.setState({ modalOpen: false })} type="button" className="btn btn-lg btn-secondary">Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="col service-categories-container">
                <div className="card card-preview">
                    <div className="card-inner">
                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row justify-between g-2">
                                <div className="col-7 col-sm-4 text-left">
                                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="form-control form-control-sm" placeholder="Type in to Search" onChange={(e) => this.searchCategory(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-5 col-sm-8 text-right">
                                    {this.state.loading ? <Spinner /> :
                                        <button onClick={() => this.setState({ modalOpen: true })} type="button" className="btn btn-primary">
                                            <em className="icon ni ni-plus-fill-c mr-2"></em>
                                            Add New Category
                                        </button>
                                    }
                                </div>
                            </div>
                            <Modal
                                isOpen={this.state.modal}
                                style={Constants.defaultModalStyle}
                                onRequestClose={() => this.setState({ modal: false })}
                                ariaHideApp={false}>
                                <div style={{ width: "450px", }} className="p-3">
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="name">Update Service category</label>
                                            <div className="form-control-wrap">
                                                <input
                                                    type="text"
                                                    className="form-control form-select-lg"
                                                    name="name"
                                                    value={this.state.data.name}
                                                    onChange={this.handleCategoryChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div>
                                                <label className="form-label" htmlFor="name" style={{ visibility: 'hidden' }}>Settlement Bank</label>
                                            </div>
                                            {
                                                this.state.loading ? <Spinner /> :
                                                    <button type="button" className="btn btn-lg btn-primary mx-3" onClick={(e) => { this.updateCategory(e) }}>Update</button>
                                            }
                                            <button onClick={() => this.setState({ modal: false })} type="button" className="btn btn-lg btn-secondary">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <div className="row">

                                {(this.state.filtered || this.state.categories).map((category, index) => (
                                    <div className="service_cat_item odd col-lg-6 mt-3 pl-4 pr-4 mb-3" key={index}>
                                        <button onClick={() => this.GetCategoryToUpdate(category)} type="button" className="card border card-bordered block text-soft">
                                            <div className="card-inner">
                                                <div className="row equal-witdth">
                                                    <div className="col-3 pr-0 align-self-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="svg_icon">
                                                            <rect x="14.5" y="5" width="56" height="70" rx="6" ry="6" fill="#fe2502" stroke="#fe2502" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></rect>
                                                            <path d="M69.38,85H29.62a6.06,6.06,0,0,1-6.12-6V21a6.06,6.06,0,0,1,6.12-6H59.16L75.5,30.47V79A6.06,6.06,0,0,1,69.38,85Z" fill="#fff" stroke="#fe2502" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                                                            <polyline points="59.5 16 59.5 31 74.5 31.07" fill="none" stroke="#fe2502" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polyline>
                                                            <rect x="34.5" y="40" width="30" height="30" fill="#fe2502" stroke="#fe2502" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></rect>
                                                            <line x1="34.5" y1="48" x2="63.5" y2="48" fill="none" stroke="#fe2502" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></line>
                                                            <line x1="45.5" y1="70" x2="45.5" y2="40" fill="none" stroke="#fe2502" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></line>
                                                            <line x1="34.5" y1="56" x2="63.5" y2="56" fill="none" stroke="#fe2502" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></line>
                                                            <line x1="34.5" y1="64" x2="63.5" y2="64" fill="none" stroke="#fe2502" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></line>
                                                        </svg>

                                                    </div>

                                                    <div className="col-6 pl-0 text-left align-self-center">
                                                        <h6>{category.categoryname}</h6>
                                                        <span>{category.categoryCode}</span>
                                                    </div>

                                                    {/* <div className="col-2 text-right align-self-center" style={{ position: "relative", zIndex: 0 }}>
                                                        <span className="btn btn-icon btn-trigger mr-n1"><em className="icon ni ni-chevron-right"></em></span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                ))}

                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </MainLayout >;
    }
}
