import React, { Component, useContext } from "react";
import TopNav from "../TopNav";
import Sidebar from "../Sidebar";
import Constants from "../../Utils/Constants";
import Functions from "../../Utils/Functions";
import { Link, Redirect } from "react-router-dom";
import { DataContext } from "../../Contexts/DataContexts";

export default class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      hideFloatingButton: false,
      hideActivationMessage: false,
      mobileMenuIsOpen: false,
    };

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  static contextType = DataContext;

  componentDidMount() {}

  toggleMobileMenu() {
    this.setState({ mobileMenuIsOpen: !this.state.mobileMenuIsOpen });
  }

  render() {
    return (
      <div className="nk-main ">
        <Sidebar />

        <div className="nk-wrap ">
          <TopNav />
          {/* content @s */}
          <div className="nk-content ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">
                          {this.props.title}
                        </h3>
                        <div className="nk-block-des text-soft">
                          {this.props.subTitle && <p>{this.props.subTitle}</p>}
                        </div>
                      </div>
                      {/* .nk-block-head-content */}

                      {/* .nk-block-head-content */}
                    </div>
                    {/* .nk-block-between */}
                  </div>
                  {/* .nk-block-head */}
                  <div className="nk-block">
                    <div className="row g-gs">{this.props.children}</div>
                  </div>
                  {/* .nk-block */}
                </div>
              </div>
            </div>
          </div>
          {/* content @e */}
        </div>
        {/* wrap @e */}
      </div>
    );
  }
}
