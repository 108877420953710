import APICall from "../Utils/APICall";

export default class BanksService {
    static listBankAccount = async () => {
        const data = await APICall("/api/Banks/list", "GET");
        return data && data.filter(a=> a.accountType);
    };

    static listBank = async () => {
        let cached = sessionStorage.getItem('banks-list');
        if(cached){
            return JSON.parse(cached);
        }
        const banks_from_api = await APICall("/api/Banks/bankslist", "GET");
        if(banks_from_api){
            sessionStorage.setItem('banks-list', JSON.stringify(banks_from_api));
        }
        return banks_from_api;
    };
    static addBank = async (payload) => {
        return await APICall("/api/Banks/add", "POST", payload);
    };
    static update = async (bankId, payload) => {
        return await APICall(`/api/Banks/update/${bankId}`, "POST", payload);
    };

}
