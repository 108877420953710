import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import Toastr from "../../Utils/Toastr";
import BanksService from "./../../services/BanksService";
import Select from "react-select";
import Spinner from "../../Utils/Spinner";
import { Link } from "react-router-dom";

export default class UpdateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        accountNumber: "",
        bankAddress: "",
        bankCode: "",
        sortCode: "",
        zipCode: "",
        accountType: "",
        id: "",
      },
      bankId: "",
      banks: [],
      bankCode: [],
      accountId: "",

      paymentType: [
        { label: "CASH", value: "CASH" },
        { label: "BANK", value: "BANK" },
      ],
    };
  }
  componentDidMount = async () => {
    this.setState({ loading: true });

    const banks = await BanksService.listBank();
    let accountOptions = [];
    if (banks) {
      banks.forEach((element) => {
        accountOptions.push({
          value: element.bankCode,
          label: element.bankName,
        });
      });
      this.setState({ banks: accountOptions });
    }

    let id = this.props.match.params.accountId;
    this.setState({ accountId: id });
    await this.getAccountDetails(id);

    this.setState({ loading: false });
  };

  getAccountDetails = async (id) => {
    this.setState({ loading: true });
    let accountList = [];
    accountList = await BanksService.listBankAccount();
    if (accountList && accountList.length > 0) {
      let neededAccount = accountList.find((obj) => obj.id === parseInt(id));
      let data = { ...this.state.data };
      data.accountNumber = neededAccount?.accountNumber;
      data.bankAddress = neededAccount?.bankAddress;
      data.sortCode = neededAccount?.sortCode;
      data.bankCode = neededAccount?.bankCode;
      data.zipCode = neededAccount?.zipCode;
      data.accountType = neededAccount?.accountType;
      this.setState({ data: data });
    }
    this.setState({ loading: false });
  };
  updateAccount = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var Data = await BanksService.update(this.state.accountId, this.state.data);
    if (Data) {
      Toastr("success", "Account updated successfully");
      setTimeout(() => {
        this.props.history.push("/accounts");
      }, 3000);
    }

    this.setState({ loading: false });
  };

  handleBankChange = (bank) => {
    console.log(bank);
    if (bank) {
      const data = { ...this.state.data };
      data.bankCode = bank.value;
      this.setState({ data: data });
      console.log(data);
    }
  };

  handleAccountChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data: data });
  };

  handlePaymentType = (option) => {
    if (option) {
      let data = { ...this.state.data };
      data.accountType = option.value;
      this.setState({ data: data });
    }
  };

  render() {
    return (
      <MainLayout title="View Account Information">
        <div className="col">
          <div className="card card-bordered col-lg-8">
            <div className="card-inner">
              <form
                action=""
                onSubmit={async (e) => await this.updateAccount(e)}
              >
                <div className="row g-4">
                  <div className="col-lg-6">
                    <div>
                      <label
                        className="form-label"
                        htmlFor="full-name-1"
                        style={{ visibility: "" }}
                      >
                        Account Type
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <Select
                        value={this.state.paymentType.find(
                          (obj) => obj.value === this.state.data.accountType
                        )}
                        onChange={(option) => this.handlePaymentType(option)}
                        options={this.state.paymentType}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="accountNumber">
                        Account Number
                      </label>
                      <div className="form-control-wrap">
                        <input
                          // type="number"
                          className="form-control form-select-lg"
                          name="accountNumber"
                          id="accountNumber"
                          value={this.state.data.accountNumber}
                          onChange={this.handleAccountChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="bankAddress">
                        Bank Address
                      </label>
                      <div className="form-control-wrap">
                        <input
                          // type="text"
                          className="form-control form-select-lg"
                          name="bankAddress"
                          id="bankAddress"
                          value={this.state.data.bankAddress}
                          onChange={this.handleAccountChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="bankCode">
                        Bank Name
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          className=""
                          name="bankCode"
                          id="bankCode"
                          onChange={(option) => this.handleBankChange(option)}
                          value={this.state.banks.find(
                            (obj) => obj.value === this.state.data.bankCode
                          )}
                          options={this.state.banks}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="sortCode">
                        Sort Code
                      </label>
                      <div className="form-control-wrap">
                        <input
                          // type="text"
                          className="form-control form-select-lg"
                          name="sortCode"
                          id="sortCode"
                          value={this.state.data.sortCode}
                          onChange={this.handleAccountChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="zipCode">
                        Swift Code
                      </label>
                      <div className="form-control-wrap">
                        <input
                          // type="text"
                          className="form-control form-select-lg"
                          name="zipCode"
                          id="zipCode"
                          value={this.state.data.zipCode}
                          onChange={this.handleAccountChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div>
                      <label
                        className="form-label"
                        htmlFor="full-name-1"
                        style={{ visibility: "hidden" }}
                      >
                        ***
                      </label>
                    </div>
                    <div className="row" style={{ visibility: "hidden" }}>
                      <div className="col-lg-6">
                        <div className="form-group">
                          {this.state.loading ? (
                            <Spinner />
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary btn-block"
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Link
                            to="/accounts"
                            className="btn btn-dark btn-lg btn-block text-nowrap"
                          >
                            <em className="icon ni ni-chevrons-left"></em> Back
                            to List
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
