import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/others.css";
import TransactionService from "../../services/TransactionService";
import Functions from "../../Utils/Functions";
import Spinner from "../../Utils/Spinner";

export default class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      transactions: [],
      reportData: {
        page: 1,
        pageLength: 50,
        from: "",
        to: "",
        invoiceNumberSearch: "",
      },
    };
  }
  tableRow = ({ transactionCode, invoice }) => {
    return (
      <div className="nk-tb-item">
        <div className="nk-tb-col">
          <div className="nk-tnx-type">
            <div className="nk-tnx-type-text">
              <span className="tb-lead">{transactionCode}</span>
            </div>
          </div>
        </div>
        <div className="nk-tb-col tb-col-lg">
          <span className="tb-amount">
            {invoice?.currency}
            {(invoice?.amount ?? "").toLocaleString()}
          </span>
        </div>
        <div className="nk-tb-col text-right">
          <span className="tb-amount">{invoice?.invoiceNumber}</span>
        </div>
        <div className="nk-tb-col text-right tb-col-sm">
          <span className="tb-lead">{invoice?.customer?.company}</span>
          <span className="tb-sub-lead">
            {invoice?.customer?.firstName} {invoice?.customer?.lastName} -{" "}
            {invoice?.customer?.phoneNumber}
          </span>
        </div>
        <div className="nk-tb-col text-center">
          <span className="tb-amount">{invoice?.paymentStatus}</span>
        </div>
      </div>
    );
  };

  componentDidMount = async () => {
    let today = new Date();
    let aYearAgo = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
    let data = { ...this.state.reportData };
    data.from = Functions.formatDate2(aYearAgo);
    data.to = Functions.formatDate2(today);
    console.log({ date: data.to });
    this.setState({ reportData: data });
    await this.ListTransactions(data);
  };
  ListTransactions = async (reportData = null) => {
    if (!reportData) reportData = { ...this.state.reportData };
    this.setState({ loading: true });
    let transactions = await TransactionService.listTransaction(
      reportData.from,
      reportData.to,
      reportData.page,
      reportData.pageLength,
      reportData.invoiceNumberSearch
    );
    if (transactions) {
      this.setState({
        transactions: transactions.data,
        count: transactions.count,
      });
    }
    this.setState({ loading: false });
  };
  convertDatetoReadable(date) {
    if (date) {
      console.log(date);
      return new Date(date).toISOString().split("T")[0];
    }
    return "";
  }
  handleChange = ({ currentTarget: input }) => {
    if (input) {
      const data = { ...this.state.reportData };
      data[input.name] = input.value;

      this.setState({ reportData: data });
    }
  };
  previous = async () => {
    let reportData = { ...this.state.reportData };
    reportData.page = reportData.page + 1;
    this.setState({ reportData: reportData });
    await this.ListTransactions(reportData);
  };
  next = async () => {
    let reportData = { ...this.state.reportData };
    reportData.page = reportData.page - 1;
    this.setState({ reportData: reportData });
    await this.ListTransactions(reportData);
  };
  render() {
    return (
      <MainLayout title="Transactions">
        <div className="col">
          <div className="nk-content ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between g-3">
                      <div className="nk-block-head-content">
                        <div className="nk-block-des text-soft">
                          <p>You have total {this.state.count} transactions.</p>
                        </div>
                      </div>
                      <div className="nk-block-head-content">
                        <ul className="nk-block-tools g-3">
                          {/* <li>
                        <a
                          href="#"
                          className="btn btn-white btn-dim btn-outline-light"
                        >
                          <em className="icon ni ni-download-cloud" />
                          <span>Export</span>
                        </a>
                      </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title">All transactions</h5>
                            </div>
                            <div className="card-tools mr-n1">
                              <ul className="btn-toolbar gx-1">
                                <li>
                                  <label
                                    className="form-label"
                                    htmlFor="invoiceNumberSearch"
                                  >
                                    Search Invoice number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-md"
                                    placeholder="invoice number"
                                    id="invoiceNumberSearch"
                                    onChange={this.handleChange}
                                    name="invoiceNumberSearch"
                                    value={
                                      this.state.reportData.invoiceNumberSearch
                                    }
                                    aria-controls="customer_list_Table"
                                  />
                                </li>
                                <li>
                                  <label className="form-label" htmlFor="from">
                                    from
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control form-control-md"
                                    placeholder="from"
                                    onChange={this.handleChange}
                                    id="from"
                                    name="from"
                                    value={this.convertDatetoReadable(
                                      this.state.reportData.from
                                    )}
                                    aria-controls="customer_list_Table"
                                  />
                                </li>
                                <li>
                                  <label className="form-label" htmlFor="to">
                                    to
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control form-control-md"
                                    placeholder="Type in to search"
                                    id="to"
                                    name="to"
                                    value={this.convertDatetoReadable(
                                      this.state.reportData.to
                                    )}
                                    aria-controls="customer_list_Table"
                                    onChange={this.handleChange}
                                  />
                                </li>
                                <li>
                                  <label
                                    style={{
                                      visibility: "hidden",
                                      display: "block",
                                    }}
                                    className="form-label"
                                    htmlFor="discountAmount"
                                  >
                                    to
                                  </label>
                                  <button
                                    type="button"
                                    onClick={async (e) =>
                                      await this.ListTransactions()
                                    }
                                    className="btn btn-primary btn-md"
                                  >
                                    {" "}
                                    <em className="icon ni ni-search"></em>{" "}
                                    Search
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div
                              className="card-search search-wrap"
                              data-search="search"
                            >
                              <div className="search-content">
                                <a
                                  href="#"
                                  className="search-back btn btn-icon toggle-search"
                                  data-target="search"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </a>
                                <button className="search-submit btn btn-icon">
                                  <em className="icon ni ni-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner p-0">
                          <div className="nk-tb-list nk-tb-tnx">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col tb-col">
                                <span>Transaction Reference</span>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span>Amount</span>
                              </div>
                              <div className="nk-tb-col text-right">
                                <span>Invoice Number</span>
                              </div>
                              <div className="nk-tb-col text-right tb-col-sm">
                                <span>Customer Details</span>
                              </div>
                              <div className="nk-tb-col nk-tb-col-status">
                                <span className="sub-text d-none d-md-block">
                                  Payment Status
                                </span>
                              </div>
                              <div className="nk-tb-col nk-tb-col-tools" />
                            </div>
                            {this.state.loading ? (
                              <div className="nk-tb-item">
                                <div className="nk-tb-col">
                                  <div className="nk-tnx-type">
                                    <div className="nk-tnx-type-text">
                                      <span className="tb-lead">
                                        <Spinner />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              this.state.transactions.map(
                                (transaction, index) => (
                                  <this.tableRow key={index} {...transaction} />
                                )
                              )
                            )}
                          </div>
                        </div>
                        <div className="card-inner">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            {this.state.reportData.page > 1 && (
                              <li className="page-item">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={async () => await this.previous()}
                                  className="page-link"
                                >
                                  Prev
                                </a>
                              </li>
                            )}
                            {this.state.transactions.length ===
                              this.state.reportData.pageLength && (
                              <li className="page-item">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={async () => await this.next()}
                                  className="page-link"
                                >
                                  Next
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
