import React, { Component } from "react";
import UsersService from "../../services/UsersService";
export default class Logout extends Component {
    constructor(props) {
        super(props);
    
      }
      logout = async () => {
          await UsersService.Logout();
          localStorage.clear();
          window.location.href = "/login";
          //this.props.history.push('/login');
      }

      async componentDidMount(){
       await this.logout();
      }
      render() {
        return (
            <div></div>
        )
      }
}