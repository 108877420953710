import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import UsersService from "./../../services/UsersService";
import Toastr from "../../Utils/Toastr";
import Spinner from "../../Utils/Spinner";

const UserDetail = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    email: "",
    name: "",
  });
  const [role, setRole] = useState([]);
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const Data = await UsersService.update({ ...data, id: Number(userId) });
    if (Data) {
      Toastr("success", "User updated sucessfully");
      setTimeout(() => {
        navigate("/users");
      }, 2000);
    }
    this.setState({ loading: false });
  };

  const getUserDetails = async () => {
    setLoading(true);
    const users = await UsersService.Get(userId);
    if (users) {
      const { fullname, email } = users;
      setData({ name: fullname, email });
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <MainLayout title="User Details">
      <div className="col">
        <div className="card card-bordered col-md-5">
          <div className="card-inner">
            <form action="#" onSubmit={async (e) => await updateUser(e)}>
              <div className="row g-4">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fullname">
                      Full name
                    </label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control form-select-lg"
                        name="name"
                        onChange={handleUserChange}
                        value={data.name}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-select-lg"
                        name="email"
                        onChange={handleUserChange}
                        value={data.email}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-6"> */}
                <div className="col-lg-6">
                  <div className="form-group">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <button
                        type="submit"
                        onSubmit={updateUser}
                        className="btn btn-primary btn-block"
                      >
                        Update User
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <Link to="/users" className="btn  btn-dark btn-block">
                      <em className="icon ni ni-chevrons-left"></em> Back to
                      Users
                    </Link>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default UserDetail;
