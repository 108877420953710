import React, { Component } from "react";
import "../../sass/others.css";
import Functions from "../../Utils/Functions";
import Toastr from "../../Utils/Toastr";
import UsersService from "./../../services/UsersService";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      data: {
        password: "",
        password1: "",
      },
    };
  }

  changePassword = async (e) => {
    e.preventDefault();

    var regData = {
      code: this.state.code,
      password: this.state.data.password,
    };
    var Data = await UsersService.changepassword(regData);
    if (Data) {
      Toastr("success", "Password changed successfully ");
      setTimeout(() => {
      window.location.href = ("/login");
    }, 2000);
    }
  };

  handlePasswordChange = async ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    console.log(input);
    data[input.name] = input.value;
    this.setState({ data: data });
  };
  componentDidMount = () => {
    let code = Functions.GetPathUrl(window.location.pathname);
    this.setState({ code: code });
  };

  render() {
    return (
      <div className="nk-content row align-self-center h-100">
        <div className="nk-block nk-block-middle nk-auth-body  wide-xs col" style={{marginLeft:"7vw"}}>
          <div className="card card-bordered" style={{backgroundColor:"#fff0", border:"none", maxWidth:"420px"}}>
            <div className="card-inner card-inner-lg" style={{backgroundColor:"#fff0"}}>
              <div className="nk-block-head">
                <div className="nk-block-head-content text-center" style={{width:"fit-content", margin:"auto"}}>
                  {/* <a href="">
                    <img
                      className="mb-4"
                      src="https://i.ibb.co/TbkVR3h/maple-removebg-preview.png"
                      alt="maple-removebg-preview"
                      border={0}
                    />
                  </a> */}
                  <h4 className="nk-block-title text-center mb-3" style={{fontWeight:"200", color:"#f1f1f1"}}>
                    Password Change
                  </h4>
                </div>
              </div>
              <form
                action=""
                onSubmit={async (e) => await this.changePassword(e)}
              >
                <div className="form-group">
                  <div className="form-label-group">
                    {/* <label className="form-label" htmlFor="password">
                      Password
                    </label> */}
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#"
                      className="form-icon form-icon-right passcode-switch lg"
                      data-target="password"
                    >
                      <em className="passcode-icon icon-show icon ni ni-eye" />
                      <em className="passcode-icon icon-hide icon ni ni-eye-off" />
                    </a>
                    <input
                      style={{backgroundColor:"#fff0", border:"0px", borderBottom:"2px solid white", borderRadius:"0px", color:"white", outline:"none"}}
                      type="password"
                      name="password"
                      value={this.state.data.password}
                      onChange={this.handlePasswordChange}
                      className="form-control form-control-lg"
                      id="password"
                      placeholder="Enter new password"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    {/* <label className="form-label" htmlFor="password">
                      Re-Type Password
                    </label> */}
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#"
                      className="form-icon form-icon-right passcode-switch lg"
                      data-target="password1"
                    >
                      <em className="passcode-icon icon-show icon ni ni-eye" />
                      <em className="passcode-icon icon-hide icon ni ni-eye-off" />
                    </a>
                    <input
                      style={{backgroundColor:"#fff0", border:"0px", borderBottom:"2px solid white", borderRadius:"0px", color:"white", outline:"none"}}
                      type="password"
                      name="password1"
                      value={this.state.data.password1}
                      onChange={this.handlePasswordChange}
                      className="form-control form-control-lg"
                      id="password1"
                      placeholder="Re-type password"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <button className="btn btn-lg btn-primary btn-block mt-4">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
