import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import "../../sass/customer.css";
import CustomerService from "../../services/CustomerService";
import Toastr from "../../Utils/Toastr";
import Spinner from "../../Utils/Spinner";
import { Link } from 'react-router-dom';

export default class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        firstname: "",
        lastname: "",
        email: "",
        companyName: "",
        phoneNumber: "",
        contactAddress: "",
      }
    };
  }

  addCustomer = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      var Data = await CustomerService.add(this.state.data);
      if (Data) {
        Toastr("success", "Customer Successfully Added");
        setTimeout(() => {
          window.location.href = ('/customers');
        }, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        Toastr("error", "A customer with this email already exists.");
      } else {
        Toastr("error", "An error occurred while adding the customer.");
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  handleCustomerChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  }

  render() {
    return (
      <MainLayout title="Add Customer">
        <div className="col">
          <div className="nk-block nk-block-lg">
            <div className="card card-bordered col-lg-7">
              <div className="card-inner">
                <form onSubmit={this.addCustomer}>
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="full-name-1">Customer First Name</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            name="firstname"
                            value={this.state.data.firstname}
                            onChange={this.handleCustomerChange}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="full-name-1">Customer Last Name</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            name="lastname"
                            value={this.state.data.lastname}
                            onChange={this.handleCustomerChange}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="company-name">Company Name</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            name="companyName"
                            value={this.state.data.companyName}
                            onChange={this.handleCustomerChange}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="email-address-1">Email address</label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            name="email"
                            value={this.state.data.email}
                            onChange={this.handleCustomerChange}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="phone-no-1">Phone Number</label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            name="phoneNumber"
                            value={this.state.data.phoneNumber}
                            onChange={this.handleCustomerChange}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="company-name">Contact Address</label>
                        <div className="form-control-wrap">
                          <input
                            type="textarea"
                            name="contactAddress"
                            value={this.state.data.contactAddress}
                            onChange={this.handleCustomerChange}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          {this.state.loading ? <Spinner /> : 
                          <button type="submit" className="btn btn-lg btn-primary btn-block">
                            Add Customer
                          </button>}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <Link to="/customers" className="btn btn-lg btn-outline btn-block btn-dark">
                            <em className="icon ni ni-chevrons-left"></em> Back to Customers
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
