import APICall from "../Utils/APICall";

export default class CategoriesService {
  static listCategories = async () => {
    return await APICall("/api/Categories/list", "GET");
  };
  static add = async (payload) => {
    return await APICall("/api/Categories/add", "POST", payload);
  };
  static update = async (payload) => {
    return await APICall("/api/Categories/update", "POST", payload);
  };
}
